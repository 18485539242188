import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { AgroButton, InlineError } from "../../../../components";
import useGeneric from "../../../../utils/hooks/user/useGeneric";
import useMediaService from "../../../../utils/hooks/user/useMediaService";
import { message } from "antd";
import {TagsInput} from "react-tag-input-component";
import {Editor} from "react-draft-wysiwyg";
import {EditorState} from "draft-js";
import {convertToHTML} from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from 'react-select';

const AddBlog = ({ completed, disable }) => {
    const [form] = Form.useForm();
    const [buttonText, setButtonText] = useState("Create Blog");
    const [current, setCurrent] = useState(0);

    const {
        createBlogLoading,
        createBlogFailure,
        setCreateBlogFailure,
        createBlog,
    } = useMediaService();

    const { blogCategoriesData, getBlogCategories } = useGeneric();
    const success = "Successful";
    const error = "An error has occured";

    const { TextArea } = Input;

    useEffect(() => {
        if (current !== 0) {
            setButtonText("Continue");
        }
    }, [current]);

    useEffect(() => {
        getBlogCategories();
    }, []);

    const [featured_image, setFeaturedImage] = useState(null);
    const [title, setTitle] = useState("");
    const [meta, setMeta] = useState("");
    const [post_type, setPostType] = useState("");
    const [tags, setTags] = useState([]);
    const [categories, setCategories] = useState([])
    const [limit, setLimit] = useState(2)
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setOptions(blogCategoriesData?.map(category => ({
            label: category?.name,
            value: category?.id,
        })));
    }, [blogCategoriesData]);

    // handle onChange event of the dropdown
    const handleChangeCat = (e, no) => {
        setSelectedCategories(Array.isArray(e) ? e.map(x => x.value) : []);
    }

    const handleImage = (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e);
        setFeaturedImage(e); 
        reader.onload = function () {
        };
    };

    const handleAddTags = (tags) => {
        setTags(tags );
    };

    const [postBody, setpostBody] = useState("");
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [convertedContent, setConvertedContent] = useState(null);
    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    };
    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
        setpostBody(currentContentAsHTML);
    };

    const onSubmit = (values) => {
        const formData = new FormData();
        formData.append("title", title);
        // formData.append("body", values?.body);
        formData.append("body", postBody);
        selectedCategories?.forEach((cat,i, data) => {
            formData.append(`categories[${i}]`, cat)
        });
        tags?.forEach((tag,i, data) => {
            formData.append(`tags[${i}]`, tag)
        })
        formData.append("is_active", values?.is_active);
        formData.append("meta", meta);
        formData.append("post_type", "blog");
        formData.append("featured_image", featured_image);

        createBlog(formData).then((data) => {
            if (data?.success) {
                message.success(data?.message || success);
                    if (!createBlogFailure) {
                        completed();
                    }
            } else {
                message.error(data?.message || error);
            }
        });
    };


    return (
        <div>
            <InlineError
                control={createBlogFailure}
                message={createBlogFailure}
                onClose={() => setCreateBlogFailure(null)}
            />

            <Form form={form} onFinish={onSubmit} autoComplete="on" className="mt-5">
                <div className="flex items-center justify-start md:justify-between flex-wrap w-full">
                    <div className="w-full mb-2 flex justify-between items-center">
                        <p className="mb-2 text-lg lg:text-[18px] font-bold">Create Blog</p>
                    </div>

                    <div className="w-full md:w-[29%] lg:my-4 ">
                        <p className="form-label">Title</p>
                        <input
                            name="title"
                            className="form-field__input__2 mb-24"
                            type="text"
                            placeholder="Benz"
                            onChange={(e)=>setTitle(e.target.value)}
                        />
                    </div>



                    <div className="w-full md:w-[29%] lg:my-0 ">
                        <p className="form-label">Category</p>
                        <Select
                            className="dropdown mb-24"
                            placeholder="Select Option"
                            value={options.filter(obj => selectedCategories.includes(obj.value))} // set selectedCategories values
                            options={options} // set list of the data
                            onChange={(event) => handleChangeCat(event, 0)} // assign onChange function
                            isMulti
                            isClearable
                        />

                    </div>

                    <div className="w-full md:w-[29%] lg:my-0 ">
                        <p className="form-label">Picture</p>
                        <input
                            className="form-field__input__2 mb-24"
                            type="file"
                            onChange={(e) => handleImage(e.target.files[0])}
                            accept="image/png, image/gif, image/jpeg"
                        />
                    </div>
                </div>
                <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
                    <div className="w-full md:w-[100%] lg:my-0 ">
                        <p className="form-label">Body</p>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={handleEditorChange}
                                placeholder="Write your post here"
                            />
                        {/*<Form.Item*/}
                        {/*    name="body"*/}
                        {/*    rules={[*/}
                        {/*        { required: true, message: "Required" },*/}
                        {/*        { min: 2, message: "Must be at least 2 characters" },*/}
                        {/*    ]}*/}
                        {/*>*/}
                        {/*    <TextArea*/}
                        {/*        className="form-field__input__2"*/}
                        {/*        rows={8}*/}
                        {/*        // placeholder="maxLength is 6"*/}
                        {/*        disabled={disable}*/}
                        {/*    />*/}

                        {/*</Form.Item>*/}
                    </div>
                </div>
                <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
                    <div className="w-full md:w-[29%] lg:my-0" style={{marginBottom:"24px"}}>
                        <p className="form-label">Tags</p>

                        <TagsInput
                            name="tags"
                            maxLength={limit}
                            placeHolder="Enter Tags & press enter"
                            onChange={handleAddTags}
                        />
                    </div>

                    <div className="w-full md:w-[29%] lg:my-4 ">
                        <p className="form-label">Meta</p>
                        <input
                            name="meta"
                            className="form-field__input__2 mb-24"
                            type="text"
                            placeholder="Benz"
                            onChange={(e)=>setMeta(e.target.value)}
                        />
                    </div>
                    <div className="w-full md:w-[29%] lg:my-0 ">
                        <p className="form-label">Featured Active</p>
                        <Form.Item  name={"is_active"}>
                            <select
                                className="form-field__input__2 form-field__sel bg-white"
                                disabled={disable}
                            >
                                <option value={""}>Select Is Active</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                            </select>
                        </Form.Item>
                    </div>
                </div>

                <div className="flex justify-end mt-4 mb-1 gap-x-4">
                    <AgroButton
                        loading={createBlogLoading}
                        disabled={createBlogLoading}
                        primary
                        text={createBlogLoading ? "Please wait..." : buttonText}
                        type="submit"
                        className={"px-10"}
                    />
                </div>
            </Form>
        </div>
    );
};

export default AddBlog;
