import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import globalReducer from "../slices/globalSlice.js";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

const rootReducer = combineReducers({
	global: globalReducer,
});

const persistConfig = {
	key: "root",
	storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	// devTools,
	middleware: [thunk],
});

const persistor = persistStore(store);

export { store, persistor };
