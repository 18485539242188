import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AgroButton from "../../misc/AgroButton";
import ListAggregation from "./components/ListAggregation";
import { InlineError } from "../..";
import CreateAggregation from "./components/CreateAggregation";
import useFieldService from "../../../utils/hooks/user/useFieldService";
import ViewEditAggregation from "./components/ViewEditAggregation";

const Aggregation = () => {
  const [tab, setTab] = useState(0);
  const [aggregate, setAggregate] = useState(null);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    size: 10,
  });
  const {
    aggregationsLoading,
    aggregationsFailure,
    setAggregationsFailed,
    getAggregations,
  } = useFieldService();
  const { aggregations } = useSelector((state) => state.global);
  const handleClick = (index) => {
    if (tab === 1)
      setPaginationData({
        page: 1,
        size: 10,
      });
    setTab(index);
  };

  useEffect(() => {
    getAggregations(paginationData);
  }, [paginationData]);

  const handleCompleted = () => {
    setTab(0);
    setPaginationData({
      page: 1,
      size: 10,
    });
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current,
      size: pageSize,
    });
  };

  const handleView = (aggregate, tab) => {
    setAggregate(aggregate);
    setTab(tab);
  };

  return (
    <div>
      <div className={"flex justify-end gap-x-3"}>
        <AgroButton
          onClick={() => handleClick(0)}
          primary={tab !== 1}
          text="List Aggregation"
          className=""
        />
        <AgroButton
          onClick={() => handleClick(1)}
          primary={tab === 1}
          text="Add Aggregation"
          className=""
        />
      </div>
      {tab === 0 ? (
        <>
          <div className={"w-full mt-4"}>
            <InlineError
              control={aggregationsFailure}
              message={aggregationsFailure}
              onClose={() => setAggregationsFailed(null)}
            />
          </div>
          <ListAggregation
            aggregations={aggregations}
            paginationChangeHandler={paginationChangeHandler}
            loading={aggregationsLoading}
            view={(aggregate, tab) => handleView(aggregate, tab)}
          />
        </>
      ) : tab === 1 ? (
        <CreateAggregation completed={handleCompleted} />
      ) : (
        <ViewEditAggregation
          completed={handleCompleted}
          aggregate={aggregate}
        />
      )}
    </div>
  );
};

export default Aggregation;
