import React, { useEffect, useState } from "react";
import {Form, Input, message} from "antd";
import { AgroButton, InlineError } from "../../..";
import { FaEdit } from "react-icons/fa";
import { RiCloseLine } from "react-icons/ri";
import useMediaService from "../../../../utils/hooks/user/useMediaService";


const ViewEditAdvert = ({ completed, advert, disable }) => {
  const [form] = Form.useForm();
  const {updateAdvertLoading, updateAdvertFailure, setUpdateAdvertFailure, updateAdvert,} = useMediaService();
  const [state, setState] = useState(0);
  const [header, setHeader] = useState("View Advert");

  const success = "Successful";
  const error = "An error has occured";

  const edit = () => {
    setHeader(state === 0 ? "Edit Advert" : "View Advert");
    setState(state === 0 ? 1 : 0);
  };


  const { TextArea } = Input;

  const [bannerImage, setBannerImage] = useState(null);
  const [advertItem, setAdvertItem] = useState(null);
  const [advertType, setAdvertType] = useState(null);
  const [advertId, setAdvertId] = useState(null);

  const handleImage = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    setBannerImage(e);
    reader.onload = function () {
      // setPhoto(reader.result);
    };
  };

  const handleAdvert = (e) => {
    const type = e.target.value;
    setAdvertItem(type);
  };

  const handleType = (e) => {
    const type = e.target.value;
    setAdvertType(type);
  };

  useEffect(() => {
    let isCancelled = false;
    form.setFieldsValue({
      advert_plan_id: advert?.advert_plan_id,
      advert_url: advert?.advert_url,
      banner: advert?.bannerImage,
    });

    return () => {
      isCancelled = true;
    };
  }, [advert]);

  const onSubmit = (values) => {
    const data = {
      advert_plan_id: values?.advert_plan_id ?? "",
      advert_url: values?.advert_url ?? "",
      banner: values?.bannerImage ?? "",
    };
    updateAdvert({ id: advert.id, payload: data }).then((x) => {
      if (x !== false) {
        completed();
      }
    });
  };

  return (
      <div className={"mt-4"}>
        <InlineError
            control={updateAdvertFailure}
            message={updateAdvertFailure}
            onClose={() => setUpdateAdvertFailure(null)}
        />

        <Form form={form} onFinish={onSubmit} autoComplete="on" className="mt-6">
          <div className={"w-full justify-between items-center flex mb-4"}>
            <h2 className={"text-[#28812c] text-lg font-bold"}>{header}</h2>
            <h2 className={"text-lg cursor-pointer"} onClick={() => edit(state)}>
              {state === 0 ? (
                  <span className={"text-[#28812c]"}>
                <FaEdit />
              </span>
              ) : (
                  <span className={"text-[#ea580c] text-[28px]"}>
                <RiCloseLine />
              </span>
              )}
            </h2>
          </div>
          <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
            <div className="w-full md:w-[29%] lg:my-0 ">
              <p className="form-label">Advert</p>
              <Form.Item onChange={handleAdvert} name={"advert_plan_id"}>
                <select
                    className="form-field__input__2 form-field__sel bg-white"
                    disabled={state === 0}
                >
                  <option value={""}>Select Advert</option>
                  <option value={"1"}>Publishes Description</option>
                  <option value={"2"}>Banner Size</option>
                  <option value={"3"}>Pictorial Guide</option>
                </select>
              </Form.Item>
            </div>

            <div className="w-full md:w-[29%] lg:my-0 ">
              <p className="form-label">Advert Type</p>
              <Form.Item  onChange={handleType} name={"advert_type"}>
                <select
                    className="form-field__input__2 form-field__sel bg-white"
                    disabled={state === 0}
                >
                  <option value={""}>Select Advert Type</option>
                  <option value="pay_per_view">Pay Per View</option>
                  <option value="fixed_duration">Fixed Duration</option>
                </select>
              </Form.Item>
            </div>

            <>
              {
                advertType === "pay_per_view"
                    ?
                    <div className="w-full md:w-[29%] lg:my-0 ">
                      <p className="form-label">Numbers Of Views</p>
                      <Form.Item
                          name="advert_url"
                          // rules={[
                          //     { required: true, message: "Required" },
                          //     { min: 2, message: "Must be at least 2 characters" },
                          // ]}
                      >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={state === 0}
                        />
                      </Form.Item>
                    </div>
                    : advertType === "fixed_duration"
                        ?
                        <>
                          <div className="w-full md:w-[29%] lg:my-0 ">
                            <p className="form-label">Start Date</p>
                            <Form.Item
                                name="start_date"
                                // rules={[
                                //     { required: true, message: "Required" },
                                //     { min: 2, message: "Must be at least 2 characters" },
                                // ]}
                            >
                              <Input
                                  type="datetime-local"
                                  className="form-field__input__2"
                                  placeholder=" "
                                  disabled={state === 0}
                              />
                            </Form.Item>
                          </div>

                          <div className="w-full md:w-[29%] lg:my-0 ">
                            <p className="form-label">End Date</p>
                            <Form.Item
                                name="end_date"
                                // rules={[
                                //     { required: true, message: "Required" },
                                //     { min: 2, message: "Must be at least 2 characters" },
                                // ]}
                            >
                              <Input
                                  type="datetime-local"
                                  className="form-field__input__2"
                                  placeholder=" "
                                  disabled={state === 0}
                              />
                            </Form.Item>
                          </div>
                        </>
                        :
                        <></>
              }
            </>

            <div className="w-full md:w-[29%] lg:my-0 ">
              <p className="form-label">Advert Url</p>
              <Form.Item
                  name="advert_url"
                  rules={[
                    { required: true, message: "Required" },
                    { min: 2, message: "Must be at least 2 characters" },
                  ]}
              >
                <Input
                    className="form-field__input__2"
                    placeholder=" "
                    disabled={state === 0}
                />
              </Form.Item>
            </div>


            <div className="w-full md:w-[29%] lg:my-0 ">
              <p className="form-label">Upload Banner</p>
              <input
                  className="form-field__input__2 mb-24"
                  type="file"
                  onChange={(e) => handleImage(e.target.files[0])}
                  accept="image/png, image/gif, image/jpeg"
                  disabled={state === 0}
              />
            </div>
          </div>

          {state === 1 ? (
              <div className="flex justify-center my-1">
                <AgroButton
                    loading={updateAdvertLoading}
                    disabled={updateAdvertLoading}
                    primary
                    text={updateAdvertLoading ? "Updating" : "Update"}
                    type="submit"
                    className={"px-16"}
                />
              </div>
          ) : (
              <></>
          )}
        </Form>
      </div>
  );
};

export default ViewEditAdvert;
