import React from "react";
import { Table, Pagination } from "antd";
import { aggregationListColumns } from "../../../../utils/misc/fieldServices";
import { AgroButton } from "../../..";

const ListAggregation = (props) => {
  const { aggregations, paginationChangeHandler, loading, view } = props;
  const defaultCurrent = 1;
  const defaultPageSize = 10;
  const data = aggregations?.data;

  const handleView = (row) => {
    view(row, 2);
  };

  const handleBuild = () => {
    console.log("build");
  };

  const handlePickup = () => {
    console.log("pickup");
  };

  const handleList = () => {
    console.log("list");
  };

  const dataSource =
    data &&
    data.length > 0 &&
    data.map((row, index) => {
      return {
        key: row?.id,
        sn: index + 1,
        category: row?.item?.item_category?.name ?? "N/A",
        sub_category: row?.item?.item_sub_category?.name ?? "N/A",
        item: row?.item?.name ?? "N/A",
        quantity: row?.quantity ?? "N/A",
        supply_mode:
          row?.supply_modes?.map((s) => s.mode_type).join(", ") ?? "N/A",
        action: (
          <div className={"flex gap-x-2"}>
            <AgroButton
              onClick={() => handleView(row)}
              primary
              text="View"
              className={"h-fit text-[10px]"}
            />
            <AgroButton
              onClick={handleBuild}
              primary
              text="Build"
              className={"h-fit text-[10px]"}
            />
            <AgroButton
              onClick={handlePickup}
              primary
              text="Pick Up"
              className={"h-fit text-[10px] w-[4rem]"}
            />
            <AgroButton
              onClick={handleList}
              primary
              text="List"
              className={"h-fit text-[10px]"}
            />
          </div>
        ),
      };
    });

  const onShowSizeChange = (current, pageSize) => {
    return { current, pageSize };
  };

  return (
    <>
      <div className={"mt-6"}>
        <Table
          className={"aggregationList"}
          rowClassName={(_, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          bordered={true}
          dataSource={dataSource}
          columns={aggregationListColumns}
          pagination={false}
          loading={loading}
        />
      </div>
      <div className="flex justify-end py-3">
        <Pagination
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={defaultCurrent}
          total={aggregations?.total}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={defaultPageSize}
          onChange={paginationChangeHandler}
        />
      </div>
    </>
  );
};

export default ListAggregation;
