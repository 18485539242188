const cooperativesListColumns = [
  {
    title: "S/N",
    dataIndex: "sn",
    key: "sn",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",
  },
  {
    title: "Members",
    dataIndex: "members",
    key: "members",
  },
  {
    title: "Focus",
    dataIndex: "focus",
    key: "focus",
  },
  {
    title: "Manage",
    dataIndex: "manage",
    key: "manage",
  },
];

const associationsListColumns = [
  {
    title: "S/N",
    dataIndex: "sn",
    key: "sn",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
  },
  {
    title: "City",
    dataIndex: "city",
    key: "city",
  },
  {
    title: "Members",
    dataIndex: "members",
    key: "members",
  },
  {
    title: "Focus",
    dataIndex: "focus",
    key: "focus",
  },
  {
    title: "Manage",
    dataIndex: "manage",
    key: "manage",
  },
];

const cooperativesDuesListColumns = [
  {
    title: "S/N",
    dataIndex: "sn",
    key: "sn",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Manage",
    dataIndex: "manage",
    key: "manage",
  },
];

const associationsDuesListColumns = [
  {
    title: "S/N",
    dataIndex: "sn",
    key: "sn",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Manage",
    dataIndex: "manage",
    key: "manage",
  },
];

const cooperativeMembersListColumns = [
  {
    title: "S/N",
    dataIndex: "sn",
    key: "sn",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Last Due Paid",
    dataIndex: "lastDuePaid",
    key: "lastDuePaid",
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Manage",
    dataIndex: "manage",
    key: "manage",
  },
];

const associationMembersListColumns = [
  {
    title: "S/N",
    dataIndex: "sn",
    key: "sn",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Last Due Paid",
    dataIndex: "lastDuePaid",
    key: "lastDuePaid",
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Manage",
    dataIndex: "manage",
    key: "manage",
  },
];

const farmersNotInCooperativeListColumns = [
  {
    title: "S/N",
    dataIndex: "sn",
    key: "sn",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Date Captured",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
    key: "phone",
  },
];

const farmersNotInAssociationListColumns = [
  {
    title: "S/N",
    dataIndex: "sn",
    key: "sn",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Date Captured",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "Phone Number",
    dataIndex: "phone",
    key: "phone",
  },
];

const cooperativesMemberDuesListColumns = [
  {
    title: "Due",
    dataIndex: "due",
    key: "due",
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "Add Payment",
    dataIndex: "pay",
    key: "pay",
  },
];

export {
  cooperativesListColumns,
  cooperativesDuesListColumns,
  cooperativeMembersListColumns,
  farmersNotInCooperativeListColumns,
  associationsListColumns,
  associationsDuesListColumns,
  associationMembersListColumns,
  farmersNotInAssociationListColumns,
  cooperativesMemberDuesListColumns,
};
