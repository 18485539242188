import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useGroupServices from "../../../utils/hooks/user/useGroupServices";
import AgroButton from "../../misc/AgroButton";
import { Form, message } from "antd";
import { InlineError } from "../..";
import { useSelector } from "react-redux";
import ListMembers from "./components/Cooperative/ListMembers";
import AddMember from "./components/Cooperative/AddMember";
const error = "An error has occured";

const CooperativeMembers = () => { 
  const [cooperative, setCooperative] = useState(null);
  const [tab, setTab] = useState(0);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    size: 10,
  });
  const { search } = useLocation();
  const navigate = useNavigate();
  const id = new URLSearchParams(search).get("id");
  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
  }, []);
  const {
    getCooperative,
    cooperativeDuesLoading,
    cooperativeDuesFailure,
    setCooperativeDuesFailure,
    getCooperativeDues,

    farmerRecordNotInCooperativeLoading,
    getFarmerRecordNotInCooperative,
  } = useGroupServices();
  const { cooperativeDues, farmersNotInCooperative } = useSelector(
    (state) => state.global
  );

  useEffect(() => {
    if (id) {
      getCooperative(id).then((data) => {
        if (data?.success) {
          setCooperative(data?.data?.cooperative);
        } else {
          message.error(data?.message || error);
        }
      });
    }
  }, []);

  const handleClick = (index) => {
    if (tab === 1)
      setPaginationData({
        page: 1,
        size: 10,
      });
    setTab(index);
  };

  useEffect(() => {
    getCooperativeDues({
      id,
      page: 1,
      size: 20,
    });
  }, []);

  const handleCompleted = () => {
    setTab(0);
    setPaginationData({
      page: 1,
      size: 10,
    });
  };

  const handleRefresh = () => {
    getCooperative(id).then((data) => {
      if (data?.success) {
        setCooperative(data?.data?.cooperative);
      } else {
        message.error(data?.message || error);
      }
    });
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current,
      size: pageSize,
    });
  };

  useEffect(() => {
    if (cooperative) {
      getFarmerRecordNotInCooperative({
        id: cooperative?.id,
        ...paginationData,
      });
    }
  }, [paginationData, cooperative]);

  return (
    <div>
      <InlineError
        control={cooperativeDuesFailure}
        message={cooperativeDuesFailure}
        onClose={() => setCooperativeDuesFailure(null)}
      />
      <div className={"flex justify-between items-center mb-4"}>
        <h1>Members</h1>
        <h1>{cooperative?.name}</h1>
      </div>
      {cooperative ? (
        <div className={"flex justify-end"}>
          <div className={"flex items-center gap-x-3"}>
            <AgroButton
              onClick={() => handleClick(0)}
              primary={tab === 0}
              text="List Members"
              className={"text-[10px]"}
            />
            <AgroButton
              onClick={() => handleClick(1)}
              primary={tab === 1}
              text="Add Member"
              className={"text-[10px]"}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {cooperative ? (
        <>
          {tab === 0 ? (
            <>
              <ListMembers
                cooperative={cooperative}
                loading={cooperativeDuesLoading}
                cooperativeDues={cooperativeDues}
              />
            </>
          ) : (
            <AddMember
              farmersNotInCooperative={farmersNotInCooperative}
              paginationChangeHandler={paginationChangeHandler}
              loading={farmerRecordNotInCooperativeLoading}
              completed={() => {
                handleCompleted();
                handleRefresh();
              }}
              cooperative={cooperative}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CooperativeMembers;
