import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useGroupServices from "../../../utils/hooks/user/useGroupServices";
import AgroButton from "../../misc/AgroButton";
import AddCooperative from "./components/Cooperative/AddCooperative";
import ListCooperatives from "./components/Cooperative/ListCooperatives";

const Cooperatives = () => {
  const [tab, setTab] = useState(0);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    size: 10,
  });
  const { cooperativesLoading, getCooperatives } = useGroupServices();
  const { cooperatives } = useSelector((state) => state.global);

  const handleClick = (index) => {
    if (tab === 1)
      setPaginationData({
        page: 1,
        size: 10,
      });
    setTab(index);
  };

  useEffect(() => {
    getCooperatives(paginationData);
  }, [paginationData]);

  const handleCompleted = () => {
    setTab(0);
    setPaginationData({
      page: 1,
      size: 10,
    });
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current,
      size: pageSize,
    });
  };
  return (
    <div>
      <div className={"flex justify-end gap-x-3"}>
        <AgroButton
          onClick={() => handleClick(0)}
          primary={tab === 0}
          text="List Cooperatives"
          className={"text-[12px]"}
        />
        <AgroButton
          onClick={() => handleClick(1)}
          primary={tab === 1}
          text="Add Cooperative"
          className={"text-[12px]"}
        />
      </div>
      {tab === 0 ? (
        <>
          <ListCooperatives
            cooperatives={cooperatives}
            paginationChangeHandler={paginationChangeHandler}
            loading={cooperativesLoading}
          />
        </>
      ) : (
        <AddCooperative
          cooperatives={cooperatives}
         completed={handleCompleted} 
         />
      )}
    </div>
  );
};

export default Cooperatives;
