import React, {useEffect, useState} from "react";
import useCountriesStates from "../../../../utils/hooks/auth/useCountriesStates";
import {Form, Input, message} from "antd";

const valuesInit = {
    country: "",
    state: "",
    city: "",
    address: "",
    country_name: "",
    state_name: "",
    city_name: "",
};

const AddIndividualEvent = ({form, setFocuses, disable}) => {
    const { TextArea } = Input;
    const [venuValue, setVenuValue] = useState(null);
    const [location, setLocation] = useState(valuesInit);
    const [locations, setLocations] = useState([]);
    const { countriesData, getCountries } = useCountriesStates();

    const {
        statesData,
        setStatesData,
        getStates,
        citiesData,
        setCitiesData,
        getCities,
    } = useCountriesStates();


    const addLocation = () => {
        form
            .validateFields([
                "country",
                "state",
                "city",
                "address",
                "contact_email",
                "contact_phone",
            ])
            .then((vals) => {
                const data = {
                    ...vals,
                    country_name: "",
                    state_name: "",
                    city_name: citiesData.find((c) => c.id == vals.city)?.name,
                };
                setLocations([...locations, { ...data }]);
                reset();
            })
            .catch((error) => {});
    };


    useEffect(() => {
        getCountries();
    }, []);

    const handleVenueChange = (e) => {
        const value = e.target.value;
        setVenuValue(value);
    };

    const handleCountryChange = (e) => {
        const id = e.target.value;
        setLocation({ ...location, country: id });
        getStates(id);
    };

    const handleCityChange = (e) => {
        const id = e.target.value;
        setLocation({
            ...location,
            city: id,
        });
        // reset();
    };

    const handleStateChange = (e) => {
        const id = e.target.value;
        setLocation({ ...location, state: id });
        getCities(id);
    };


    const reset = () => {
        setStatesData([]);
        setCitiesData([]);
        form.setFieldsValue({ country: "", state: "", city: "", address: "" });
        setLocation({ ...location, ...valuesInit });
        // setVisibility(false);
    };


    return (
        <>
            <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
                <div className="w-full mb-2 flex justify-between items-center">
                    <p className="mb-2 text-lg lg:text-[18px] font-bold">Create Event</p>
                </div>
                <div className="w-full md:w-[29%] lg:my-4 ">
                    <p className="form-label">Title</p>
                    <Form.Item
                        name="title"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Start Date</p>
                    <Form.Item
                        name="start_date"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            type="datetime-local"
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">End Date</p>
                    <Form.Item
                        name="end_date"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            type="datetime-local"
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Venue</p>
                    <Form.Item onChange={handleVenueChange} name={"venue_type"}>
                        <select
                            className="form-field__input__2 form-field__sel bg-white"
                            disabled={disable}
                        >
                            <option value={""}>Select Venue</option>
                            <option value="virtual">Virtual</option>
                            <option value="physical">Physical</option>

                        </select>
                    </Form.Item>
                </div>

                <>
                    {venuValue === "virtual"
                        &&
                        <div className="w-full md:w-[29%] lg:my-0 ">
                            <p className="form-label">Venu Link</p>
                            <Form.Item
                                name="year"
                                rules={[
                                    { required: true, message: "Required" },
                                    { min: 2, message: "Must be at least 2 characters" },
                                ]}
                            >
                                <Input
                                    className="form-field__input__2"
                                    placeholder=" "
                                    disabled={disable}
                                />
                            </Form.Item>
                        </div>
                    }
                </>

                <div className="w-full md:w-[29%] lg:my-0">
                    <p className="form-label text-xs">Country</p>
                    <Form.Item
                        onChange={handleCountryChange}
                        name={"country"}
                        rules={[{ required: venuValue === "physical" ? true : false, message: "Required" }]}
                    >
                        <select className="form-field__input__2 bg-white form-field__sel">
                            <option value={""}>Select Country</option>
                            {countriesData?.map((item, index) => (
                                <option key={index} value={item.id}>
                                    {item?.name?.length > 20
                                        ? `${item.name.substring(0, 30)}...`
                                        : item.name}
                                </option>
                            ))}
                        </select>
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0">
                    <p className="form-label text-xs">State</p>
                    <Form.Item
                        onChange={handleStateChange}
                        name={"state"}
                        rules={[{ required: venuValue === "physical" ? true : false, message: "Required" }]}
                    >
                        <select className="form-field__input__2 bg-white form-field__sel">
                            <option value={""}>Select State</option>
                            {statesData?.map((state) => (
                                <option key={state?.name} value={state?.id}>
                                    {state.name}
                                </option>
                            ))}
                        </select>
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0">
                    <p className="form-label text-xs">City</p>
                    <Form.Item
                        onChange={handleCityChange}
                        name={"city"}
                        rules={[{ required: venuValue === "physical" ? true : false, message: "Required" }]}
                    >
                        <select className="form-field__input__2 form-field__sel bg-white">
                            <option value={""}>Select City</option>
                            {citiesData?.map((city) => (
                                <option key={city?.name} value={city?.id}>
                                    {city.name}
                                </option>
                            ))}
                        </select>
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Address</p>
                    <Form.Item
                        name="address"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Active</p>
                    <Form.Item  name={"is_active"}>
                        <select
                            className="form-field__input__2 form-field__sel bg-white"
                            disabled={disable}
                        >
                            <option value={""}>Select Is Active</option>
                            <option value="1">Yes</option>
                            <option value="0">No</option>
                        </select>
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Registration Details</p>
                    <Form.Item
                        name="registration_details"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>
            </div>
          </>
    );
};

export default AddIndividualEvent;
