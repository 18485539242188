import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { AgroButton, PaddedContainer } from "..";
import { homeTopBlogs } from "../../utils/misc/homeData";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../slices/globalSlice";

function NavBlogs() {
  const { user } = useSelector((state) => state.global);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleButtonNavigation = () => {
    if (!user) {
      navigate("/auth/signin");
    } else {
     
      navigate("/user/home");
    }
  };
  return (
    <Wrapper className="posts-wrapper bg-white flex flex-col items-center justify-center">
      <PaddedContainer>
        <section className="container mt-9 flex flex-wrap items-center justify-between">
          <div className="w-full lg:block md:flex md:justify-between md:items-center bg-red group lg:w-[38%]">
            <div>
              <h4 className={"font-bold sm:text-[2.3rem] text-[1.5rem]"}> Digitizing the Agricultural Community</h4>
        {/*       <p className={"font-medium sm:text-[1.1rem] text-sm sm:mt-4"}>
                Digitizing the Agricultural Value Chain
              </p> */}
            </div>
            <AgroButton
              onClick={ handleButtonNavigation}
              primary
              className={
                "sm:py-4 py-2 sm:px-8 px-4 text-[1.1rem] mt-4 sm:mt-8 rounded-[4px] lg:mt-8 md:mt-0"
              }
              hasBorder
              text= "Get Started"
            />
          </div>
          <Wrap className="w-full gap-y-5 h-full lg:w-[60%]">
            {homeTopBlogs.map(({ itemLeft, itemRight }, index) => (
              <div
                key={index + "items"}
                className={`flex w-full justify-between ${
                  index === 0 ? "mt-16" : ""
                } ${
                  index === 1
                    ? "md:px-0 lg:px-4 xl:px-0"
                    : "md:px-8 lg:px-4 xl:px-8"
                }`}
              >
                <div
                  data-aos-delay={itemLeft.delay}
                  data-aos={"flip-up"}
                  data-aos-offset={0}
                  className={"flex items-center home-card duration-300"}
                >
                  <div
                    className={`${itemLeft.css} item-icon flex items-center justify-center`}
                  >
                    <img
                      src={itemLeft.imgPath}
                      alt="logo"
                      className={"w-4 h-4 sm:w-10 sm:h-10"}
                    />
                  </div>
                  <Title className={"text-xs sm:text-md"}>{itemLeft.label}</Title>
                </div>

                <div
                  data-aos-delay={itemRight.delay}
                  data-aos-offset={0}
                  data-aos={"flip-down"}
                  className={"flex items-center home-card duration-300"}
                >
                  <div
                    className={`${itemRight.css} item-icon flex items-center justify-center`}
                  >
                    <img
                      src={itemRight.imgPath}
                      alt="logo"
                      className={"w-4 h-4 sm:w-10 sm:h-10"}
                    />
                  </div>
                  <Title className={"text-xs sm:text-md"}>{itemRight.label}</Title>
                </div>
              </div>
            ))}
          </Wrap>
        </section>
      </PaddedContainer>
    </Wrapper>
  );
}

export default NavBlogs;

const Wrapper = styled.nav`
  background: #f9fcfe;
`;

const Wrap = styled.div`
  background-image: url("/images/bannerImg_1.png");
  background-position: top;
  background-size: inherit;
  background-repeat: no-repeat;
`;

const Title = styled.p`
  font-weight: 600;
  display: inline-block;
  vertical-align: middle;
`;
