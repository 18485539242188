import React from "react";
import Location from "./Location";

const LocationInfo = ({
  form,
  countries,
  setLocations,
  disable,
  existingLocations,
  farmRecordId,
  handleRemove,
}) => {
  const getLocations = (locations) => {
    setLocations(locations);
  };
  return (
    <>
      <div className="flex items-center justify-start md:justify-between flex-wrap w-full">
        <div className="w-full mb-2 flex justify-between items-center">
          <p className="form-label">Location</p>
        </div>
        <Location
          countries={countries}
          form={form}
          getLocations={(locations) => getLocations(locations)}
          disable={disable}
          existingLocations={existingLocations}
          farmRecordId={farmRecordId}
          handleRemove={handleRemove}
        />
      </div>
    </>
  );
};

export default LocationInfo;
