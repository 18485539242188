import { useState } from "react";
import axiosInstance from "../../axios/axiosInterceptors";

function useCountriesStates() {
  const [countriesLoading, setCountriesLoading] = useState(false);
  const [countriesError, setCountriesError] = useState(null);
  const [countriesData, setCountriesData] = useState([]);
  const [statesLoading, setStatesLoading] = useState(false);
  const [statesError, setStatesError] = useState(null);
  const [statesData, setStatesData] = useState([]);
  const [citiesLoading, setCitiesLoading] = useState(false);
  const [citiesError, setCitiesError] = useState(null);
  const [citiesData, setCitiesData] = useState([]);

  const getCountries = async () => {
    setCountriesLoading(true);
    setCountriesError(null);
    try {
      const { data } = await axiosInstance.get("generic/countries");
      const serverResponse = data?.data?.countries;
      setCountriesData(serverResponse);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setCountriesError(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCountriesLoading(false);
    }
  };

  const getStates = async (id) => {
    setStatesLoading(true);
    setStatesError(null);
    try {
      const { data } = await axiosInstance.get(`generic/states/${id}`);
      const serverResponse = data?.data?.states;
      setStatesData(serverResponse);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setStatesError(isObject ? Object.values(message).join(" | ") : message);
      setStatesLoading(false);
    }
  };

  const getCities = async (id) => {
    setCitiesLoading(true);
    setCitiesError(null);
    try {
      const { data } = await axiosInstance.get(`generic/cities/${id}`);
      const serverResponse = data?.data?.cities;
      setCitiesData(serverResponse);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setCitiesError(isObject ? Object.values(message).join(" | ") : message);
      setCitiesLoading(false);
    }
  };

  return {
    countriesLoading,
    countriesError,
    countriesData,
    getCountries,
    statesLoading,
    statesError,
    statesData,
    setStatesData,
    getStates,
    citiesLoading,
    citiesError,
    citiesData,
    setCitiesData,
    getCities,
  };
}

export default useCountriesStates;
