import React, { useEffect, useState } from "react";
import { Form, Input, message } from "antd";
import { AgroButton } from "../../..";
import MultiSelectedFormItems from "./MultiSelectedItems";
import ContactPersonList from "./ViewAndEditFarmerData/ContactPersonList";
import useFarmerData from "../../../../utils/hooks/user/useFarmerData";
const success = "Successful";
const error = "An error has occured";

const valuesInit = {
  title: "",
  first_name: "",
  last_name: "",
  other_name: "",
  email: "",
  phone: "",
};

const ContactPerson = (props) => {
  const {
    form,
    getContactPersons,
    disable,
    existingContacts,
    farmRecordId,
    handleRemove,
  } = props;
  const [contactPersons, setContactPersons] = useState([]);
  const [values, setValues] = useState(valuesInit);
  const [visibility, setVisibility] = useState(true);
  const titles = ["Mr", "Mrs", "Miss", "Ms", "Dr"];
  const { deleteContactPersonLoading, deleteContactPerson } = useFarmerData();

  const addContactPerson = () => {
    form
      .validateFields([
        "contact_title",
        "contact_first_name",
        "contact_last_name",
        "contact_other_name",
        "contact_email",
        "contact_phone",
      ])
      .then((vals) => {
        const data = {
          title: vals?.contact_title,
          first_name: vals?.contact_first_name,
          last_name: vals?.contact_last_name,
          other_name: vals?.contact_other_name,
          email: vals?.contact_email,
          phone: vals?.contact_phone,
          name: `${vals?.contact_title}, ${vals?.contact_first_name} ${vals?.contact_last_name}`,
        };
        setContactPersons([...contactPersons, { ...data }]);
        reset();
      })
      .catch((error) => {});
  };

  const reset = () => {
    form.setFieldsValue({
      contact_title: "",
      contact_first_name: "",
      contact_last_name: "",
      contact_other_name: "",
      contact_email: "",
      contact_phone: "",
    });
    setValues({ ...values, ...valuesInit });
    setVisibility(false);
  };

  const remove = (index) => {
    contactPersons.splice(index, 1);
    setContactPersons(contactPersons.filter((_, i) => i !== index));
  };

  const handleDelete = (index, id) => {
    console.log(farmRecordId, contactPersons[index]);
    deleteContactPerson(farmRecordId, contactPersons[index]?.id).then(
      (data) => {
        if (data?.success) {
          message.success(data?.message || success);
          handleRemove(id);
        } else {
          message.error(data?.message || error);
        }
      }
    );
  };

  useEffect(() => {
    getContactPersons(contactPersons);
  }, [contactPersons]);

  useEffect(() => {
    console.log(existingContacts);
    console.log(existingContacts);
    if (existingContacts) {
      console.log(existingContacts);
      const _contactPersons = [];
      existingContacts.map((c) => {
        const contact = {
          id: c?.id,
          title: c?.title,
          first_name: c?.first_name,
          last_name: c?.last_name,
          other_name: c?.other_name,
          email: c?.email,
          phone: c?.phone,
        };
        _contactPersons.push(contact);
        console.log(_contactPersons);
      });
      setContactPersons([..._contactPersons]);
    }
  }, [existingContacts]);

  return (
    <>
      {!existingContacts ? (
        <>
          {visibility ? (
            <>
              <div className="w-full md:w-[19%] lg:my-0">
                <p className="form-label text-xs">Title</p>
                <Form.Item
                  name={"contact_title"}
                  rules={[{ required: true, message: "Required" }]}
                >
                  <select
                    disabled={disable}
                    className="form-field__input__2 bg-white form-field__sel"
                  >
                    <option value={""}>Select Title</option>
                    {titles?.map((item, index) => (
                      <option key={index + item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </Form.Item>
              </div>

              <div className="w-full md:w-[34%] lg:my-0">
                <p className="form-label text-xs">First Name</p>
                <Form.Item
                  name={"contact_first_name"}
                  rules={[
                    { required: true, message: "Required" },
                    { min: 3, message: "Must be at least 3 characters" },
                  ]}
                >
                  <Input
                    disabled={disable}
                    className="form-field__input__2"
                    placeholder="Ojo"
                  />
                </Form.Item>
              </div>

              <div className="w-full md:w-[34%] lg:my-0">
                <p className="form-label text-xs">Last Name</p>
                <Form.Item
                  name={"contact_last_name"}
                  rules={[
                    { required: true, message: "Required" },
                    { min: 3, message: "Must be at least 3 characters" },
                  ]}
                >
                  <Input
                    disabled={disable}
                    className="form-field__input__2"
                    placeholder="Wale"
                  />
                </Form.Item>
              </div>

              <div className="w-full md:w-[29%] lg:my-0">
                <p className="form-label text-xs">Other Name</p>
                <Form.Item
                  name={"contact_other_name"}
                  rules={[{ min: 3, message: "Must be at least 3 characters" }]}
                >
                  <Input
                    disabled={disable}
                    className="form-field__input__2"
                    placeholder="Wale"
                  />
                </Form.Item>
              </div>

              <div className="w-full md:w-[29%] lg:my-0">
                <p className="form-label text-xs">Email</p>
                <Form.Item
                  name={"contact_email"}
                  rules={[
                    { required: true, message: "Required" },
                    { type: "email", message: "Invalid Email" },
                  ]}
                >
                  <Input
                    disabled={disable}
                    className="form-field__input__2"
                    placeholder="matt@agroclerk.com"
                  />
                </Form.Item>
              </div>

              <div className="w-full md:w-[29%] lg:my-0">
                <p className="form-label text-xs">Phone</p>
                <Form.Item
                  name={"contact_phone"}
                  rules={[
                    { required: true, message: "Required" },
                    { min: 11, message: "Must be at least 11 character" },
                  ]}
                >
                  <Input
                    disabled={disable}
                    type="number"
                    onInput={(e) => e.target.value = e.target.value.slice(0, 11)} // js validation to check that number does not exceed 11 characters
                    className="form-field__input__2"
                    placeholder="08012345678"
                  />
                </Form.Item>
              </div>
              <div className="w-full my-4 flex justify-end gap-x-6">
                {contactPersons.length ? (
                  <AgroButton
                    className={"bg-red-500 text-[#ffffff] hover:bg-red-800"}
                    text={"Close"}
                    type="button"
                    onClick={() => setVisibility(false)}
                  />
                ) : (
                  <></>
                )}
                {!disable ? (
                  <AgroButton
                    primary
                    text={"Save"}
                    type="button"
                    onClick={addContactPerson}
                  />
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <div className="w-full mb-4">
              <AgroButton
                primary
                text={"Add New Contact"}
                type="button"
                onClick={() => setVisibility(true)}
                className={"text-[10px]"}
              />
            </div>
          )}
          {contactPersons.length ? (
            <MultiSelectedFormItems
              items={contactPersons}
              prop={"name"}
              remove={remove}
              disable={disable}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {existingContacts && contactPersons.length ? (
        <ContactPersonList
          items={contactPersons}
          handleDelete={handleDelete}
          select={() => {}}
          disable={disable}
          deleteContactPersonLoading={deleteContactPersonLoading}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ContactPerson;
