import React, { useEffect, useState } from "react";
import {Form, Input, message} from "antd";
import { AgroButton, InlineError } from "../../..";
import { FaEdit } from "react-icons/fa";
import { RiCloseLine } from "react-icons/ri";
import useMediaService from "../../../../utils/hooks/user/useMediaService";
import useCountriesStates from "../../../../utils/hooks/auth/useCountriesStates";
const { TextArea } = Input;

const valuesInit = {
  country: "",
  state: "",
  city: "",
  address: "",
  country_name: "",
  state_name: "",
  city_name: "",
};


const ViewEditEvent = ({ completed, event, disable }) => {
  const [form] = Form.useForm();
  const {updateEventLoading, updateEventFailure, setUpdateEventFailure, updateEvent,} = useMediaService();
  const [state, setState] = useState(0);
  const [header, setHeader] = useState("View Event");

  const success = "Successful";
  const error = "An error has occured";

  const edit = () => {
    setHeader(state === 0 ? "Edit Event" : "View Event");
    setState(state === 0 ? 1 : 0);
  };


  const { TextArea } = Input;
  const [venuValue, setVenuValue] = useState(null);
  const [location, setLocation] = useState(valuesInit);
  const [locations, setLocations] = useState([]);
  const { countriesData, getCountries } = useCountriesStates();

  const {
    statesData,
    setStatesData,
    getStates,
    citiesData,
    setCitiesData,
    getCities,
  } = useCountriesStates();


  const addLocation = () => {
    form
        .validateFields([
          "country",
          "state",
          "city",
          "address",
          "contact_email",
          "contact_phone",
        ])
        .then((vals) => {
          const data = {
            ...vals,
            country_name: "",
            state_name: "",
            city_name: citiesData.find((c) => c.id == vals.city)?.name,
          };
          setLocations([...locations, { ...data }]);
          reset();
        })
        .catch((error) => {});
  };


  useEffect(() => {
    getCountries();
  }, []);

  const handleVenueChange = (e) => {
    const value = e.target.value;
    setVenuValue(value);
  };

  const handleCountryChange = (e) => {
    const id = e.target.value;
    setLocation({ ...location, country: id });
    getStates(id);
  };

  const handleCityChange = (e) => {
    const id = e.target.value;
    setLocation({
      ...location,
      city: id,
    });
    // reset();
  };

  const handleStateChange = (e) => {
    const id = e.target.value;
    setLocation({ ...location, state: id });
    getCities(id);
  };


  const reset = () => {
    setStatesData([]);
    setCitiesData([]);
    form.setFieldsValue({ country: "", state: "", city: "", address: "" });
    setLocation({ ...location, ...valuesInit });
    // setVisibility(false);
  };

  useEffect(() => {
    let isCancelled = false;
    form.setFieldsValue({
      title: event?.title,
      start_date: event?.start_date,
      end_date: event?.end_date,
      is_active: event?.is_active,
      registration_details: event?.registration_details,
      address: event?.address,
      venue_type: event?.venue_type,
    });

    return () => {
      isCancelled = true;
    };
  }, [event]);

  function generateDatabaseDateTime(date) {
    const p = new Intl.DateTimeFormat('en', {
      year:'numeric',
      month:'2-digit',
      day:'2-digit',
      hour:'2-digit',
      minute:'2-digit',
      second:'2-digit',
      hour12: false
    }).formatToParts(date).reduce((acc, part) => {
      acc[part.type] = part.value;
      return acc;
    }, {});

    return `${p.year}-${p.month}-${p.day} ${p.hour}:${p.minute}:${p.second}`;
  }

  const onSubmit = (values) => {
    let startDateTime = new Date(values.start_date?values.start_date : event?.start_date);
    let endDateTime = new Date(values.end_date?values.end_date : event?.end_date);
    const data = {
      title: values?.title ?? "",
      start_date: generateDatabaseDateTime(startDateTime),
      end_date: generateDatabaseDateTime(endDateTime),
      is_active: values?.is_active ?? "",
      address: values?.address ?? "",
      venue_type: values?.venue_type ?? "",
      registration_details: values?.registration_details ?? "",
    };
    updateEvent({ id: event.id, payload: data }).then((x) => {
      if (x !== false) {
        completed();
      }
    });
  };

  return (
      <div className={"mt-4"}>
        <InlineError
            control={updateEventFailure}
            message={updateEventFailure}
            onClose={() => setUpdateEventFailure(null)}
        />

        <Form form={form} onFinish={onSubmit} autoComplete="on" className="mt-6">
          <div className={"w-full justify-between items-center flex mb-4"}>
            <h2 className={"text-[#28812c] text-lg font-bold"}>{header}</h2>
            <h2 className={"text-lg cursor-pointer"} onClick={() => edit(state)}>
              {state === 0 ? (
                  <span className={"text-[#28812c]"}>
                <FaEdit />
              </span>
              ) : (
                  <span className={"text-[#ea580c] text-[28px]"}>
                <RiCloseLine />
              </span>
              )}
            </h2>
          </div>
          <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">

            <div className="w-full md:w-[29%] lg:my-0 ">
              <p className="form-label">Title</p>
              <Form.Item
                  name="title"
                  rules={[
                    { required: true, message: "Required" },
                    { min: 2, message: "Must be at least 2 characters" },
                  ]}
              >
                <Input
                    className="form-field__input__2"
                    placeholder=" "
                    disabled={state === 0}
                />
              </Form.Item>
            </div>

            <div className="w-full md:w-[29%] lg:my-0 ">
              <p className="form-label">Start Date {" "} {event?.start_date}</p>
              <Form.Item
                  name="start_date"
                  rules={[
                    { required: true, message: "Required" },
                    { min: 2, message: "Must be at least 2 characters" },
                  ]}
              >
                <Input
                    type="datetime-local"
                    className="form-field__input__2"
                    placeholder=" "
                    disabled={state === 0}
                />
              </Form.Item>
            </div>

            <div className="w-full md:w-[29%] lg:my-0 ">
              <p className="form-label">End Date {" "} {event?.start_date}</p>
              <Form.Item
                  name="end_date"
                  rules={[
                    { required: true, message: "Required" },
                    { min: 2, message: "Must be at least 2 characters" },
                  ]}
              >
                <Input
                    type="datetime-local"
                    className="form-field__input__2"
                    placeholder=" "
                    disabled={state === 0}
                />
              </Form.Item>
            </div>

            <div className="w-full md:w-[29%] lg:my-0 ">
              <p className="form-label">Venue</p>
              <Form.Item onChange={handleVenueChange} name={"venue_type"}>
                <select
                    className="form-field__input__2 form-field__sel bg-white"
                    disabled={state === 0}
                >
                  <option value={""}>Select Venue</option>
                  <option value="virtual">Virtual</option>
                  <option value="physical">Physical</option>

                </select>
              </Form.Item>
            </div>

            <>
              {venuValue === "virtual"
                  &&
                  <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Venu Link</p>
                    <Form.Item
                        name="year"
                        rules={[
                          { required: true, message: "Required" },
                          { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                      <Input
                          className="form-field__input__2"
                          placeholder=" "
                          disabled={state === 0}
                      />
                    </Form.Item>
                  </div>
              }
            </>

            <div className="w-full md:w-[29%] lg:my-0">
              <p className="form-label text-xs">Country</p>
              <Form.Item
                  onChange={handleCountryChange}
                  name={"country"}
                  // rules={[{ required: true, message: "Required" }]}
              >
                <select
                    className="form-field__input__2 form-field__sel bg-white"
                    disabled={state === 0}
                >
                  <option value={""}>Select Country</option>
                  {countriesData?.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item?.name?.length > 20
                            ? `${item.name.substring(0, 30)}...`
                            : item.name}
                      </option>
                  ))}
                </select>
              </Form.Item>
            </div>

            <div className="w-full md:w-[29%] lg:my-0">
              <p className="form-label text-xs">State</p>
              <Form.Item
                  onChange={handleStateChange}
                  name={"state"}
                  // rules={[{ required: true, message: "Required" }]}
              >
                <select
                    className="form-field__input__2 form-field__sel bg-white"
                    disabled={state === 0}
                >
                  <option value={""}>Select State</option>
                  {statesData?.map((state) => (
                      <option key={state?.name} value={state?.id}>
                        {state.name}
                      </option>
                  ))}
                </select>
              </Form.Item>
            </div>

            <div className="w-full md:w-[29%] lg:my-0">
              <p className="form-label text-xs">City</p>
              <Form.Item
                  onChange={handleCityChange}
                  name={"city"}
                  // rules={[{ required: true, message: "Required" }]}
              >
                <select
                    className="form-field__input__2 form-field__sel bg-white"
                    disabled={state === 0}
                >
                  <option value={""}>Select City</option>
                  {citiesData?.map((city) => (
                      <option key={city?.name} value={city?.id}>
                        {city.name}
                      </option>
                  ))}
                </select>
              </Form.Item>
            </div>

            <div className="w-full md:w-[29%] lg:my-0 ">
              <p className="form-label">Address</p>
              <Form.Item
                  name="address"
                  rules={[
                    { required: true, message: "Required" },
                    { min: 2, message: "Must be at least 2 characters" },
                  ]}
              >
                <Input
                    className="form-field__input__2"
                    placeholder=" "
                    disabled={state === 0}
                />
              </Form.Item>
            </div>

            <div className="w-full md:w-[29%] lg:my-0 ">
              <p className="form-label">Active</p>
              <Form.Item  name={"is_active"}>
                <select
                    className="form-field__input__2 form-field__sel bg-white"
                    disabled={state === 0}
                >
                  <option value={""}>Select Is Active</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </Form.Item>
            </div>

            <div className="w-full md:w-[29%] lg:my-0 ">
              <p className="form-label">Registration Details</p>
              <Form.Item
                  name="registration_details"
                  rules={[
                    { required: true, message: "Required" },
                    { min: 2, message: "Must be at least 2 characters" },
                  ]}
              >
                <Input
                    className="form-field__input__2"
                    placeholder=" "
                    disabled={state === 0}
                />
              </Form.Item>
            </div>
          </div>

          {state === 1 ? (
              <div className="flex justify-center my-1">
                <AgroButton
                    loading={updateEventLoading}
                    disabled={updateEventLoading}
                    primary
                    text={updateEventLoading ? "Updating" : "Update"}
                    type="submit"
                    className={"px-16"}
                />
              </div>
          ) : (
              <></>
          )}
        </Form>
      </div>
  );
};

export default ViewEditEvent;
