import React, {useEffect, useState} from "react";
import GenericLayout from "../../containers/layouts/GenericLayout";
import {Link} from "react-router-dom";
import {BiCalendar} from "react-icons/bi";
import moment from "moment/moment";
import {FaRegComments} from "react-icons/fa";
import HomeBlogItem from "../../components/forHomepage/blogSection/HomeBlogItem";
import styled from "styled-components";
import axiosInstance from "../../utils/axios/axiosInterceptors";
import Spinner from "../../components/misc/Spinner";
import {GrRefresh} from "react-icons/gr";
import BlogSection from "../../components/forHomepage/blogSection/BlogSection";

function Blog() {
	const [postsLoading, setPostsLoading] = useState(true);
	const [latestPosts, setLatestPosts] = useState([]);
	const [postsError, setPostsError] = useState(null);

	const fetchPosts = async () => {
		setPostsLoading(true);
		try {
			const { data } = await axiosInstance.get(
				"generic/posts?include=comments"
			);
			setLatestPosts(data?.data?.posts?.data);
			setPostsError(null);
			setPostsLoading(false);
			return data;
		} catch (error) {
			setPostsLoading(false);
			setPostsError("Error fetching latest posts.");
		}
	};

	console.log("latest",latestPosts)

	useEffect(() => {
		fetchPosts();
	}, []);


	return (
		<GenericLayout>
			{postsLoading ? (
				<Spinner primary />
			) : postsError ? (
				<div className="flex flex-col items-center justify-center">
					<p className="text-center text-green-600 text-lg px-4 mb-3">
						{postsError}
					</p>
					<GrRefresh
						className="text-green-600 cursor-pointer h-6 w-6"
						onClick={fetchPosts}
					/>
				</div>
			) : (
				<>
					<div className="container mt-9 mb-24">
						<div className="container w-fulld lg:grid gap-y-9 h-full lg:w-[80%]">
					<h5 className="font-bold sm:text-[2.3rem] text-[1.5rem]"> Blog Post</h5>
						{latestPosts?.slice(1,5).map((item) => (
									<Article
										key={item.title}
										className="blog-right flex flex-wrap md:flex-nowrap items-center bg-white my-8 lg:my-auto shadow-md">
										<div className="mr-1">
											<img
												src={item.featured_image}
												alt={item.title}
												className="right__blog-img object-cover"
												style={{width:"400px", height:"150px"}}
											/>
										</div>
										<div className="p-3 md:px-3 w-full">
											<p className="bg-[#3dd800] text-center text-sm text-white font-medium uppercase py-[1px] px-2 mb-0 w-max">
												{item.meta}
											</p>

											<Link to={`/#`}>
												<p className="my-3 font-semibold text-black uppercase hover:underline hover:text-[#3dd800] transition-all text-sm md:text-md">
													{item.title}
												</p>
											</Link>

											<div className="flex items-center text-black text-opacity-70 uppercase ">
												<p className="text-[10px] md:text-md lg:text-[13px] mb-0 font-semibold">
													BY ADMIN{" "}
												</p>
												<p className="mx-3 mb-0 flex items-center font-semibold text-[10px] md:text-md lg:text-[13px]">
													<BiCalendar className="mr-1 text-[12px] md:text-[13px] lg:text-[15px] mb-1" />{" "}
													<span>{moment(item.created_at).format("MMMM d, YYYY")}</span>
												</p>
												<span className="flex items-center font-semibold text-[10px] md:text-md lg:text-[13px]">
													<FaRegComments size="17px" className="mr-2" /> {item.comments.length}{" "}
													comments
												</span>
											</div>
										</div>
									</Article>
						))}

						</div>
					</div>
				</>
			)}
		</GenericLayout>
	);
}

const Article = styled.article`
  @media (min-width: 768px) {
    .left__blog-img {
      object-fit: cover;
    }
  }

  .img-overlay {
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.01),
      rgba(0, 0, 0, 0.06),
      rgba(0, 0, 0, 0.9)
    );
  }

  /* .post-title {
		position: relative;
		overflow-wrap: break-word;
	}

	.post-title::before {
		position: absolute;
		content: "";
		width: 100%;
		white-space: normal;
		height: 3px;
		bottom: -1px;
		background-color: #3dd800;
		visibility: hidden;
		transform: scaleX(0);
		transform-origin: left 2px;
		transition: all 0.3s ease-in-out;
	}

	.post-title:hover:before {
		visibility: visible;
		transform: scaleX(1);
	} */
`;


export default Blog;
