import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { AgroButton, InlineError } from "../../../../components";
import _ from "lodash";
import { message } from "antd";
import AddIndividualEvent from "./AddIndividualEvent";
import useMediaService from "../../../../utils/hooks/user/useMediaService";

const AddEvent = ({ completed, disable }) => {
    const [form] = Form.useForm();
    const [buttonText, setButtonText] = useState("Create Event");
    const [eventId, setEventId] = useState(null);

    const {
        createEventLoading,
        createEventFailure,
        setCreateEventFailure,
        createEvent,
    } = useMediaService();

    const success = "Successful";
    const error = "An error has occured";


    function generateDatabaseDateTime(date) {
        const p = new Intl.DateTimeFormat('en', {
            year:'numeric',
            month:'2-digit',
            day:'2-digit',
            hour:'2-digit',
            minute:'2-digit',
            second:'2-digit',
            hour12: false
        }).formatToParts(date).reduce((acc, part) => {
            acc[part.type] = part.value;
            return acc;
        }, {});

        return `${p.year}-${p.month}-${p.day} ${p.hour}:${p.minute}:${p.second}`;
    }


    const onSubmit = (values) => {
        let startDateTime = new Date(values.start_date?values.start_date : new Date());
        let endDateTime = new Date(values.end_date?values.end_date : new Date());
            const data = {
                title: values?.title ?? "",
                start_date: generateDatabaseDateTime(startDateTime),
                end_date: generateDatabaseDateTime(endDateTime),
                venue_type: values?.venue_type ?? "",
                address: values?.address ?? "",
                registration_details: values?.registration_details ?? "",
                country_id: values?.country ?? "",
                state_id: values?.state ?? "",
                city_id: values?.city ?? "",
                is_active: values?.is_active ?? "",
            };
            const formData = new FormData();
            _.forEach(data, (value, key) => {
                if (typeof value === "object") {
                    if (value?.length) {
                        for (let i = 0; i < value.length; i++) {
                            _.forEach(value[i], (item, prop) => {
                                if (item && key !== "focusItems") {
                                    formData.append(`${key}[${i}][${prop}]`, item);
                                }
                                if (item && key === "focusItems") {
                                    formData.append(`${key}[]`, item);
                                }
                            });
                        }
                    }
                } else {
                    formData.append(key, value);
                }
            });

        createEvent(formData).then((data) => {
                if (data?.success) {
                    setEventId(data?.data?.events?.id);
                    message.success(data?.message || success);
                    if (!createEventFailure) {
                        completed();
                    }
                } else {
                    message.error(data?.message || error);
                }
            });
      };

    return (
        <div>
            <InlineError
                control={createEventFailure}
                message={createEventFailure}
                onClose={() => setCreateEventFailure(null)}
            />
            <Form form={form} onFinish={onSubmit} autoComplete="on" className="mt-5">
                <AddIndividualEvent
                    form={form}
                />
                <div className="flex justify-end mt-4 mb-1 gap-x-4">
                    <AgroButton
                        loading={createEventLoading}
                        disabled={createEventLoading}
                        primary
                        text={createEventLoading ? "Please wait..." : buttonText}
                        type="submit"
                        className={"px-10"}
                    />
                </div>
            </Form>
        </div>
    );
};

export default AddEvent;
