import React from "react";
import { useSelector } from "react-redux";
import { FarmerDataLeft } from "../../components";
import UserLayout from "../../containers/layouts/UserLayout";
import { Outlet } from "react-router-dom";

function FarmerData() {
  const { user } = useSelector((state) => state.global);

  return (
    <UserLayout leftSidebar={<FarmerDataLeft />} rightSidebar>
      <div className={"border-b px-3 pb-3"}>
        <p className="text-2xl font-bold">Farmer Data</p>
      </div>

      

      <div className={"mt-4 px-3"}>
        
        <Outlet />
        
      </div>

    </UserLayout>
  );
}

export default FarmerData;
