import React from "react";
import { mediaItems } from "../../utils/misc/homeData";

function MediaServices() {
	return (
		<div className="mb-5 py-5 my-5 bg-grayj-50">
			<p className="my-2 text-lg lg:text-[30px] tracking-wide font-bold tracking -wide">
				Media Services
			</p>
			<p>
				Get informed and get involved in discussions that help shape the
				industries.
			</p>
			<div className="flex flex-wrap items-start justify-center lg:justify-between mt-3">
				{mediaItems.map(({ title, children }) => (
					<div
						key={title}
						className="borderd md:w-[48%] lg:w-[24%] mx-auto my-4 shadow-lg">
						<p className="mb-2 pl-2 text-lg font-semibold tracking-wide lg:text-[20px]">
							{title}
						</p>
						<div className="p-2 flex flex-wrap items-center justify-between">
							{children.map((child, index) => (
								<p
									key={index}
									className="px-2 py-1 border m-1 cursor-pointer hover:bg-green-600 hover:text-white transition-colors">
									{child}
								</p>
							))}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default MediaServices;
