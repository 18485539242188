import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import {
	footerLeftOptions,
	footerMidOptions,
} from "../../utils/misc/footerData";

function Footer() {
	return (
		<footer className="bg-gray-700 p-8 flex flex-wrap items-start justify-start md:justify-center text-white">
			<div className="w-full my-3 md:w-1/4">
				<p className="mb-1 text-lg font-bold tracking-wider">Company</p>
				<div className="flex flex-col items-start">
					{footerLeftOptions.map((item) => (
						<Link
							key={item.title}
							to={item.path}
							className="hover:text-orange-400 hover:ml-2 transition-all">
							<p className="my-1 text-[14px] tracking-wide">{item.title}</p>
						</Link>
					))}
				</div>
			</div>
			<div className="w-full my-3 md:w-1/4">
				<p className="mb-1 text-lg font-bold tracking-wider">Explore</p>
				<div className="flex flex-col items-start">
					{footerMidOptions.map((item) => (
						<Link
							key={item.title}
							to={item.path}
							className="hover:text-orange-300 hover:ml-2 transition-all">
							<p className="my-1 text-[14px] tracking-wide">{item.title}</p>
						</Link>
					))}
				</div>
			</div>
			<div className="w-full my-3 md:w-2/6">
				<p className="mb-1 text-lg font-bold tracking-wider">Stay Connected</p>
				<p className="">Continue your experience on our mobile apps</p>

				<div className="flex my-2">
					<div className="cursor-pointer">
						<img
							src="/icons/mobileApps/appstore.png"
							alt="apple-app-store"
							className="w-[150px] object-cover mr-1.5"
						/>
					</div>

					<div className="cursor-pointer">
						<img
							src="/icons/mobileApps/playstore.png"
							alt="apple-app-store"
							className="w-[150px] object-cover ml-1.5"
						/>
					</div>
				</div>

				<div className="my-3">
					<p className="text-justify text-[12px] leading-[1.3rem]">
						Corporate Responsibility, Privacy & Legal Notices: AgroClerk is a
						licensed real estate broker, licensed to do business as AgroClerk
						Nigeria, USA, Idaho, New Jersey, Pennsylvania and Tennessee,
						AgroClerk Business in Washington, DC, Wyoming and Idaho, AgroClerk
						Group in Missouri and Kansas, and AgroClerk South Carolina LLC in
						South Carolina. California License # 01991628, 1527235, 1527365,
						1356742.
					</p>

					<div className="mt-3 flex flex-wrap items-center">
						<Link to="/">
							<p className="hover:text-orange-300 text-[12px] transition-colors">
								Privacy Policy &nbsp;
							</p>
						</Link>
						|{" "}
						<Link to="/">
							<p className="hover:text-orange-300 text-[12px] transition-colors">
								{" "}
								&nbsp; Terms of Use &nbsp;
							</p>
						</Link>
						|{" "}
						<Link to="/">
							<p className="hover:text-orange-300 text-[12px] transition-colors">
								{" "}
								&nbsp; Cookie Policy &nbsp;
							</p>
						</Link>
						|{" "}
						<Link to="/">
							<p className="hover:text-orange-300 text-[12px] transition-colors">
								{" "}
								&nbsp; Legal &nbsp;
							</p>
						</Link>
					</div>
				</div>
			</div>
			<div className="mt-2">
				<p className="flex items-center mt-1.5">
					<AiOutlineCopyrightCircle className="h-4 w-4" />
					&nbsp;Agroclerk&nbsp;
					<span className="text-[12px]">
						{new Date().getFullYear()}. All rights reserved.
					</span>
				</p>
			</div>
		</footer>
	);
}

export default Footer;
