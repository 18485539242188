import React, {useState} from "react";
import { Form, Input } from "antd";

const AddIndividualDirectory = ({
                            form, categories, setFocuses, disable,}) => {
    const getFocuses = (focuses) => {
        setFocuses(focuses);
    };
    const { TextArea } = Input;
    const [focus, setFocus] = useState({});
    const handleCategoryChange = (e) => {
        const id = e.target.value;
        setFocus({ ...focus, category: id });
    };

    return (
        <>
            <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
                <div className="w-full mb-2 flex justify-between items-center">
                    <p className="form-label">Directory</p>
                </div>
                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Name</p>
                    <Form.Item
                        name="name"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Category</p>
                    <Form.Item onChange={handleCategoryChange} name={"category"}>
                        <select
                            className="form-field__input__2 form-field__sel bg-white"
                            disabled={disable}
                        >
                            <option value={""}>Select Category</option>
                            {categories?.map((category) => (
                                <option key={category?.name} value={category?.id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">SubCategory</p>
                    <Form.Item onChange={handleCategoryChange} name={"category"}>
                        <select
                            className="form-field__input__2 form-field__sel bg-white"
                            disabled={disable}
                        >
                            <option value={""}>Select Category</option>
                            {categories?.map((category) => (
                                <option key={category?.name} value={category?.id}>
                                    {category.name}
                                </option>
                            ))}
                        </select>
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Email</p>
                    <Form.Item
                        name="email"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                        <p className="form-label">Website</p>
                        <Form.Item
                            name="website"
                            initialValue="https://"
                        >
                            <Input
                                className="form-field__input__2"
                                disabled={disable}
                            />
                        </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Year of Establishment</p>
                    <Form.Item
                        name="year"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>
            </div>

            <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
                <div className="w-full mb-2 flex justify-between items-center">
                    <p className="form-label">Location</p>
                </div>
                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Street</p>
                    <Form.Item
                        name="street"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Address</p>
                    <Form.Item
                        name="address"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Landmark</p>
                    <Form.Item
                        name="landmark"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Local Government</p>
                    <Form.Item
                        name="lcg"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Phone 1</p>
                    <Form.Item
                        name="phone1"
                    >
                        <Input
                            className="form-field__input__2"
                            disabled={disable}
                        />
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Phone 2</p>
                    <Form.Item
                        name="phone2"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>
            </div>


            <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
                <div className="w-full mb-2 flex justify-between items-center">
                    <p className="form-label">Socials</p>
                </div>
                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Facebook URL</p>
                    <Form.Item
                        name="fb"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Instagram URL</p>
                    <Form.Item
                        name="address"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Twitter</p>
                    <Form.Item
                        name="twitter"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Skype URL</p>
                    <Form.Item
                        name="skype"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">TikTok URL</p>
                    <Form.Item
                        name="tiktok"
                    >
                        <Input
                            className="form-field__input__2"
                            disabled={disable}
                        />
                    </Form.Item>
                </div>

                <div className="w-full md:w-[29%] lg:my-0 ">
                    <p className="form-label">Youtube URL</p>
                    <Form.Item
                        name="youtube"
                        rules={[
                            { required: true, message: "Required" },
                            { min: 2, message: "Must be at least 2 characters" },
                        ]}
                    >
                        <Input
                            className="form-field__input__2"
                            placeholder=" "
                            disabled={disable}
                        />
                    </Form.Item>
                </div>
            </div>
        </>
    );
};

export default AddIndividualDirectory;
