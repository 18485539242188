import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useGroupServices from "../../../utils/hooks/user/useGroupServices";
import AgroButton from "../../misc/AgroButton";
import { Form, message } from "antd";
import { InlineError } from "../..";
import { useSelector } from "react-redux";
import ListMembers from "./components/Associations/ListMembers";
import AddMember from "./components/Associations/AddMember";
const error = "An error has occured";

const AssociationMembers = () => { 
  const [association, setAssociation] = useState(null);
  const [tab, setTab] = useState(0);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    size: 10,
  });
  const { search } = useLocation();
  const navigate = useNavigate();
  const id = new URLSearchParams(search).get("id");
  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
  }, []);
  const {
    getAssociation,
    associationDuesLoading,
    associationDuesFailure,
    setAssociationDuesFailure,
    getAssociationDues,
    farmerRecordNotInAssociationLoading,
    getFarmerRecordNotInAssociation,
  } = useGroupServices();
  const { associationDues, farmersNotInAssociation } = useSelector(
    (state) => state.global
  );

  useEffect(() => {
    if (id) {
      getAssociation(id).then((data) => {
        if (data?.success) {
            console.log(data);
          setAssociation(data?.data?.association);
        } else {
          message.error(data?.message || error);
        }
      });
    }
  }, []);

  const handleClick = (index) => {
    if (tab === 1)
      setPaginationData({
        page: 1,
        size: 10,
      });
    setTab(index);
  };

  useEffect(() => {
    getAssociationDues({
      id,
      page: 1,
      size: 20,
    });
  }, []);

  const handleCompleted = () => {
    setTab(0);
    setPaginationData({
      page: 1,
      size: 10,
    });
  };

  const handleRefresh = () => {
    getAssociation(id).then((data) => {
      if (data?.success) {
        setAssociation(data?.data?.association);
      } else {
        message.error(data?.message || error);
      }
    });
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current,
      size: pageSize,
    });
  };

  useEffect(() => {
    if (association) {
      getFarmerRecordNotInAssociation({
        id: association?.id,
        ...paginationData,
      });
    }
  }, [paginationData, association]);

  return (
    <div>
      <InlineError
        control={associationDuesFailure}
        message={associationDuesFailure}
        onClose={() => setAssociationDuesFailure(null)}
      />
      <div className={"flex justify-between items-center mb-4"}>
        <h1>Members</h1>
        <h1>{association?.name}</h1>
      </div>
      {association ? (
        <div className={"flex justify-end"}>
          <div className={"flex items-center gap-x-3"}>
            <AgroButton
              onClick={() => handleClick(0)}
              primary={tab === 0}
              text="List Members"
              className={"text-[10px]"}
            />
            <AgroButton
              onClick={() => handleClick(1)}
              primary={tab === 1}
              text="Add Member"
              className={"text-[10px]"}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {association ? (
        <>
          {tab === 0 ? (
            <>
              <ListMembers
                association={association}
                loading={associationDuesLoading}
                associationDues={associationDues}
              />
            </>
          ) : (
            <AddMember
              farmersNotInAssociation={farmersNotInAssociation}
              paginationChangeHandler={paginationChangeHandler}
              loading={farmerRecordNotInAssociationLoading}
              completed={() => {
                handleCompleted();
                handleRefresh();
              }}
              association={association}
            />
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AssociationMembers;
