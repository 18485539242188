import React, {useState} from "react";
import {Table, Pagination, message} from "antd";
import { blogListColumns } from "../../../../utils/misc/mediaServices";
import { AgroButton } from "../../..";
import useMediaService from "../../../../utils/hooks/user/useMediaService";
import DOMPurify from "dompurify";


const ListAggregation = (props) => {
  const { forums, completed, deleteBlog, paginationChangeHandler, loading, view } = props;
  const defaultCurrent = 1;
  const defaultPageSize = 10;
  const data = forums?.data;

    const {createForumFailure,} = useMediaService();

    const [modalDelete, setModalDelete] = useState(false)
    const [blogRow, setBlogRow] = useState({})

    const closeOpenModal = () => {
        setModalDelete(false)
        setBlogRow("")
    }

    const success = "Successful";
    const error = "An error has occured";

    const handleView = (row) => {
    view(row, 2);
  };

    const handleDelete = (blogRow) => {
        deleteBlog(blogRow.id).then((data) => {
            if (data?.success) {
                message.success(data?.message || success);
                // window.location.reload()
                if (!createForumFailure) {
                    completed();
                    setModalDelete(false)
                }
            } else {
                message.error(data?.message || error);
                setModalDelete(false)
            }
        });
    };

    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        };
    };

    const dataSource =
    data &&
    data.length > 0 &&
    data.map((row, index) => {
      return {
        key: row?.id,
        sn: index + 1,
        title: row?.title ?? "N/A",
        meta: row?.meta ?? "N/A",
        post_type: row?.post_type ?? "N/A",
          body: <div className="preview" dangerouslySetInnerHTML={createMarkup(row?.body ? row?.body : "N/A")}></div>,
        is_active: row?.is_active ? "Active" : "Not Active",
        action: (
          <div className={"flex gap-x-2"}>
              <AgroButton
                  onClick={() => handleView(row)}
                  primary
                  text="View"
                  className={"h-fit text-[10px]"}
              />

              <AgroButton
                  onClick={() => handleDelete(row)}
                  secondary
                  text="Delete"
                  className={"h-fit text-[10px]"}
              />
          </div>
        ),
      };
    });

  const onShowSizeChange = (current, pageSize) => {
    return { current, pageSize };
  };

  return (
    <>
      <div className={"mt-6"}>
        <Table
          className={"blogList"}
          rowClassName={(_, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          bordered={true}
          dataSource={dataSource}
          columns={blogListColumns}
          pagination={false}
          loading={loading}
        />
      </div>
      <div className="flex justify-end py-3">
        <Pagination
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={defaultCurrent}
          total={forums?.total}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={defaultPageSize}
          onChange={paginationChangeHandler}
        />
      </div>

        <>
            {modalDelete &&
            <div className="modal-div overflow-auto">
                <div className="modal-box-container">
                    <div className="col-11 col-md-8 table-popup">
                        {/*<div className="w-full mb-2 flex justify-between items-center">*/}
                        <div className="w-full mb-2">
                            <p className="mb-2 text-lg lg:text-[18px] font-bold">Are You Sure</p>
                            <p className="mb-2 text-lg lg:text-[18px] ">You want to delete this Record?</p>
                        </div>

                        <div className={"flex justify-end mt-4 gap-x-2"}>
                            <AgroButton
                                onClick={() => closeOpenModal()}
                                secondary
                                text="No"
                                className={"h-fit text-[10px]"}
                            />

                            <AgroButton
                                onClick={() => handleDelete(blogRow)}
                                primary
                                text="Yes"
                                className={"h-fit text-[10px]"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    </>
  );
};

export default ListAggregation;
