import React, {useState} from "react";
import AgroButton from "../../misc/AgroButton";
import AddGroup from "./components/AddGroup";
import { Space, Table } from 'antd';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <a>{text}</a>,
  },

  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Groups',
    key: 'Groups',
    dataIndex: 'Groups',
    
  },
    
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <AgroButton
          primary={true}
          text="Manage"
           />

        <AgroButton
          primary={true}
          text="Delete"
          />
      </Space>
    ),
  },
];
const data = [
 /*  {
    key: '1',
    name: 'John Brown',
    address: 'New York No. 1 Lake Park',
    Groups: "10",
  },
  {
    key: '2',
    name: 'Jim Green',
    address: 'London No. 1 Lake Park',
    Groups: "20",
  },
  {
    key: '3',
    name: 'Joe Black',
    address: 'Sidney No. 1 Lake Park',
    Groups: "30",
  }, */
];

function Groups() {
  const [tab, setTab] = useState(0);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    size: 10,
  });

  const handleClick = (index) => {
    if (tab === 1)
      setPaginationData({
        page: 1,
        size: 10,
      });
    setTab(index);
  };

  const handleCompleted = () => {
    setTab(0);
    setPaginationData({
      page: 1,
      size: 10,
    });
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current,
      size: pageSize,
    });
  };
  
  return (
    <div>
      <h1>
     
        <>
        
          <div className={"flex justify-end gap-x-3"}>
            <AgroButton
              primary={tab === 0}
              text="List Group"
              className={"text-[12px]"}
              onClick={() => handleClick(0)}
            />
            <AgroButton
              primary={tab === 1}
              text="Add Group"
              className={"text-[12px]"}
              onClick={() => handleClick(1)}
            />
        
          </div>

            <div className="mt-5">
              {tab === 0 ?
                  <Table columns={columns} dataSource={data}/>
                  :
                  <>
                    <AddGroup completed={handleCompleted}/>
                  </>
              }
            </div>
          </>

</h1>
</div>
  )
}
export default Groups;
