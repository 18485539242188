import React from "react";
import { Form, Input } from "antd";
import { banks } from "../../../../utils/misc/farmerData";

const BankDetails = ({ disable }) => {
  return (
    <>
      <div className="w-full md:w-[47%] lg:my-0">
        <p className="form-label text-xs">Bank</p>
        <Form.Item name="bank">
          <select
            className="form-field__input__2 form-field__sel bg-white"
            disabled={disable}
          >
            <option value={""}>Select Bank</option>
            {banks.map((bank) => (
              <option key={bank.name} value={bank.name}>
                {bank.name}
              </option>
            ))}
          </select>
        </Form.Item>
      </div>

      <div className="w-full md:w-[47%] lg:my-0">
        <p className="form-label text-xs">Account Name</p>
        <Form.Item
          name="account_name"
          rules={[{ min: 5, message: "Must be at least 5 characters" }]}
        >
          <Input
            disabled={disable}
            className="form-field__input__2"
            placeholder="Wale"
          />
        </Form.Item>
      </div>

      <div className="w-full md:w-[47%] lg:my-0">
        <p className="form-label text-xs">Account Number</p>
        <Form.Item
          name="account_number"
          rules={[{ min: 10, message: "Must be at least 10 character" }]}
        >
          <Input
            disabled={disable}
            maxLength = "10"
            type= "number"
            onInput={(e) => e.target.value = e.target.value.slice(0, 10)} // ensures input does not exceed 10 digits
            className="form-field__input__2"
            placeholder="xxxxxxxxxx"
          />
        </Form.Item>
      </div>

      <div className="w-full md:w-[47%] lg:my-0">
        <p className="form-label text-xs">BVN</p>
        <Form.Item
          name="bvn"
          rules={[{ min: 11, message: "Must be at least 11 character" }]}
        >
          <Input
            disabled={disable}
            maxLength = "11"
            type="number"
             onInput={(e) => e.target.value = e.target.value.slice(0, 11)}
            className="form-field__input__2"
            placeholder="xxxxxxxxxxx"
          />
        </Form.Item>
      </div>
    </>
  );
};

export default BankDetails;
