import React from "react";
import { Steps } from "antd";
import CorporateInfo from "./CorporateInfo";
import LocationInfo from "./LocationInfo";
import ContactPersonInfo from "./ContactPersonInfo";
import BankDetailsInfo from "./BankDetailsInfo";

const { Step } = Steps;

const AddCorporateFarmer = (props) => {
  const {
    countries,
    categories,
    form,
    setFocuses,
    setLocations,
    setContactPersons,
    current,
  } = props;

  const steps = [
    {
      title: "Create Farmer",
      content: (
        <CorporateInfo
          form={form}
          categories={categories}
          setFocuses={(focuses) => setFocuses(focuses)}
        />
      ),
    },
    {
      title: "Farmer Location",
      content: (
        <LocationInfo
          form={form}
          countries={countries}
          setLocations={(locations) => setLocations(locations)}
        />
      ),
    },
    {
      title: "Contact Person",
      content: (
        <ContactPersonInfo
          form={form}
          setContactPersons={(contactPersons) =>
            setContactPersons(contactPersons)
          }
        />
      ),
    },
    {
      title: "Bank Details",
      content: <BankDetailsInfo />,
    },
  ];

  return (
    <div>
      <Steps size={"small"} current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
    </div>
  );
};

export default AddCorporateFarmer;
