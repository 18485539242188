import React, { useEffect, useState } from "react";
import {Form, Input, message} from "antd";
import { AgroButton, InlineError } from "../../..";
import { FaEdit } from "react-icons/fa";
import { RiCloseLine } from "react-icons/ri";
import useMediaService from "../../../../utils/hooks/user/useMediaService";
import {TagsInput} from "react-tag-input-component";
import useGeneric from "../../../../utils/hooks/user/useGeneric";
import DOMPurify from "dompurify";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import {convertToHTML} from "draft-convert";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Select from "react-select";
const { TextArea } = Input;

const ViewEditForum = ({ completed, blog, disable }) => {
  const [form] = Form.useForm();
  const {updateBlogLoading, updateBlogFailure, setUpdateBlogFailure, updateBlog,} = useMediaService();
  const [state, setState] = useState(0);
  const [header, setHeader] = useState("View Blog");

  const success = "Successful";
  const error = "An error has occured";

  const [featured_image, setFeaturedImage] = useState(null);
  const [title, setTitle] = useState("");
  const [post_type, setPostType] = useState("");
  const [tags, setTags] = useState(null);
  const [categories, setCategories] = useState(null)
  const [optionSec, setOptionSec] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedCategoryValue, setSelectedCategoryValue] = useState([]);


  const { blogCategoriesData, getBlogCategories } = useGeneric();
  useEffect(() => {
    getBlogCategories();
  }, []);

  const handleCategoryChange = (e) => {
    const id = parseInt(e?.target?.value ?? e, 10);
    setCategories(id);
  };

  const handleImage = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e);
    setFeaturedImage(e);
    reader.onload = function () {
      // setPhoto(reader.result);
    };
  };

  const handlePostType = (e) => {
    const type = e.target.value;
    setPostType(type);
  };

  const handleAddTags = (tags) => {
    setTags(tags );
  };

  const edit = () => {
    setHeader(state === 0 ? "Edit Forum" : "View Forum");
    setState(state === 0 ? 1 : 0);
  };

  useEffect(() => {
    setOptions(blogCategoriesData?.map(category => ({
      label: category?.name,
      value: category?.id,
    })));
  }, [blogCategoriesData]);

  useEffect(() => {
    setOptionSec(blog?.categories.map(category => ({
      label: category?.name,
      value: category?.id,
    })));
  }, [blog?.categories]);

  // handle onChange event of the dropdown
  const handleChange = (e, no) => {
    setSelectedCategoryValue(Array.isArray(e) ? e.map((x) => x.value) : [])
  };

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      handleCategoryChange(blog?.categories);

      form.setFieldsValue({
        title: blog?.title,
        body: blog?.body,
        post_type: blog?.post_type,
        is_active: blog?.is_active,
        meta: blog?.meta,
        categories: blog?.categories,
        tags: blog?.tags,
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [blog]);

  const [postBody, setpostBody] = useState("");

  const [editorState, setEditorState] = useState(() =>
      EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState(null);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
    setpostBody(currentContentAsHTML);
  };


  const filteredTags = blog.tags
      .reduce((result, {name}) => [...result, ...name ? [name] : []], []);


  // const contentBlocks = htmlToDraft(blog?.body)
  const contentBlocks = convertFromHTML(blog?.body)
  const contentState = ContentState.createFromBlockArray(contentBlocks)
  const rawHtml = convertToRaw(contentState)

  var fmData = rawHtml.blocks.map((val, i) =>{
    return val.text
  })


  const onSubmit = (values) => {
    const formData = new FormData();
    formData.append("_method", "PUT");
    formData.append("title", values?.title);
    formData.append("body", postBody? postBody : fmData.toString());
    selectedCategoryValue?.map((cat,i) => {formData.append(`categories[${i}]`, cat)});
    tags.map((tag,i) => {formData.append(`tags[${i}]`, tag)})
    formData.append("is_active", values.is_active ? "1" : "0");
    formData.append("meta", values?.meta);
    formData.append("post_type", "forum");

    // updateBlog({ id: blog.id, payload: data }).then((x) => {
    updateBlog({ id: blog.id, payload: formData }).then((x) => {
      if (x !== false) {
        completed();
      }
    });
  };


  return (
    <div className={"mt-4"}>
      <InlineError
        control={updateBlogFailure}
        message={updateBlogFailure}
        onClose={() => setUpdateBlogFailure(null)}
      />

      <Form form={form} onFinish={onSubmit} autoComplete="on" className="mt-6">
        <div className={"w-full justify-between items-center flex mb-4"}>
          <h2 className={"text-[#28812c] text-lg font-bold"}>{header}</h2>
          <h2 className={"text-lg cursor-pointer"} onClick={() => edit(state)}>
            {state === 0 ? (
              <span className={"text-[#28812c]"}>
                <FaEdit />
              </span>
            ) : (
              <span className={"text-[#ea580c] text-[28px]"}>
                <RiCloseLine />
              </span>
            )}
          </h2>
        </div>
        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label">Title</p>
            <Form.Item
              name="title"
              rules={[
                { required: true, message: "Required" },
                { min: 3, message: "Must be at least 3 characters" },
              ]}
            >
              <Input
                type="text"
                className="form-field__input__2"
                disabled={state === 0}
              />
            </Form.Item>
          </div>

          <div className="w-full md:w-[29%] lg:my-0 ">
            <p className="form-label">Category</p>
            {
                optionSec.length > 0
                &&
                <Select
                    className="dropdown mb-24"
                    placeholder="Select Option"
                    options={options} // set list of the data
                    onChange={(event) => handleChange(event, 0)} // assign onChange function
                    isMulti
                    defaultValue={optionSec.map(ele => ele)}
                    closeMenuOnSelect={false}
                    isDisabled={state === 0}
                />
            }
          </div>

          <div className="w-full md:w-[29%] lg:my-0 ">
            <p className="form-label">Picture</p>
            <input
                disabled={state === 0}
                className="form-field__input__2 mb-24"
                type="file"
                onChange={(e) => handleImage(e.target.files[0])}
                accept="image/png, image/gif, image/jpeg"
            />
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full md:w-[100%] lg:my-0 ">
            <p className="form-label">Body</p>
            <Editor
                editorState={editorState}
                contentState={rawHtml}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={handleEditorChange}
            />
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full md:w-[29%] lg:my-0" style={{marginBottom:"24px"}}>
            <p className="form-label">Tags</p>
            {/*<pre>{JSON.stringify(tags)}</pre>*/}

            <TagsInput
                name="tags"
                // maxTags={this.state.limit}
                placeholder="Enter Tags & press enter"
                onChange={handleAddTags}
                value={filteredTags}
                disabled={state === 0}
            />
          </div>

          <div className="w-full md:w-[29%] lg:my-4 ">
            <p className="form-label">Meta</p>
            <Form.Item
                name="meta"
                rules={[
                  { required: true, message: "Required" },
                  { min: 3, message: "Must be at least 3 characters" },
                ]}
            >
              <Input
                  type="text"
                  className="form-field__input__2"
                  disabled={state === 0}
              />
            </Form.Item>
          </div>
          <div className="w-full md:w-[29%] lg:my-0 ">
            <p className="form-label">Featured Active</p>
            <Form.Item  name={"is_active"}>
              <select
                  className="form-field__input__2 form-field__sel bg-white"
                  disabled={state === 0}
              >
                <option value={""}>Select Is Active</option>
                <option value={blog.is_active == "true" ? false : true}>Yes</option>
                <option value={blog.is_active == "false" ? true : false}>No</option>
              </select>
            </Form.Item>
          </div>

        </div>


        {state === 1 ? (
          <div className="flex justify-center my-1">
            <AgroButton
              loading={updateBlogLoading}
              disabled={updateBlogLoading}
              primary
              text={updateBlogLoading ? "Updating" : "Update"}
              type="submit"
              className={"px-16"}
            />
          </div>
        ) : (
          <></>
        )}
      </Form>
    </div>
  );
};

export default ViewEditForum;
