import React from "react";
import GenericLayout from "../../containers/layouts/GenericLayout";

function Cart() {
	return (
		<GenericLayout>
			<p>Here's the cart page</p>
		</GenericLayout>
	);
}

export default Cart;
