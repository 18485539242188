import { Space, Table } from 'antd';
import React, {useState} from 'react';
import AgroButton from '../../misc/AgroButton';


const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <a>{text}</a>,
  },

  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'User',
    key: 'User',
    dataIndex: 'User',
    
  },
    
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <AgroButton
          primary={true}
          text="Manage"
           />

        <AgroButton
          primary={true}
          text="Delete"
          />
      </Space>
    ),
  },
];
const data = [
 /*  {
    key: '1',
    name: 'John Brown',
    address: 'New York No. 1 Lake Park',
    User: "10",
  },
  {
    key: '2',
    name: 'Jim Green',
    address: 'London No. 1 Lake Park',
    User: "20",
  },
  {
    key: '3',
    name: 'Joe Black',
    address: 'Sidney No. 1 Lake Park',
    User: "30",
  }, */
];

function AddGrantRequest() {
  const [tab, setTab] = useState(0);
  
  return (
    <div>
      <h1>
     
        <>
        
          <div className={"flex justify-end gap-x-3"}>
            <AgroButton
              primary={tab === 0}
              text="List GrantRequest"
              className={"text-[12px]"}
            />
            <AgroButton
              primary={tab === 1}
              text="Add GrantRequest"
              className={"text-[12px]"}
            />
        
          </div>

          <div>
          <Table columns={columns} dataSource={data} />
          </div>
         
          </>

</h1>
</div>
  )
}
export default AddGrantRequest;
