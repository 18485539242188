import React from "react";
import { Table, Pagination } from "antd";
import { farmerListColumns } from "../../../../utils/misc/farmerData";
import SolidButton from "../../../misc/SolidButton";
import { FaEye } from "react-icons/fa";

const ListFarmer = (props) => {
  const {
    farmerList,
    paginationChangeHandler,
    loading,
    setScreen,
    setFarmerData,
  } = props;
  const defaultCurrent = 1;
  const defaultPageSize = 10;
  const data = farmerList?.data;
  let dataSource =
    data &&
    data.length > 0 &&
    data.map((row, index) => {
      return {
        key: row?.id,
        sn: index + 1,
        name:
          row?.first_name || row?.last_name
            ? `${row?.first_name ?? ""} ${row?.last_name ?? ""}`
            : row?.company_name,
        type: row?.type_id == 1 ? "Individual" : "Corporate",
        state: row?.addresses?.length
          ? row?.addresses[0]?.state?.name ?? "-"
          : "-",
        city: row?.addresses?.length
          ? row?.addresses[0]?.city?.name ?? "-"
          : "-",
        phone_number: row?.phone ?? "-",
        // focus: row?.fdc_item?.name ?? "-",
        focus:   <div>
                    {row?.focus_items?.map((data, i) =>
                        <p key={i}>{data?.fdc_item?.name}</p>
                    )}
                </div>,
        view: (
          <SolidButton
            Icon={<FaEye />}
            text={"View"}
            onClick={() => {
              setScreen("edit");
              setFarmerData(row);
            }}
          />
        )
      };
    });

  const onShowSizeChange = (current, pageSize) => {
    return { current, pageSize };
  };

  return (
    <>
      <div className={"mt-6"}>
        <Table
          className={"farmerList text-[14px]"}
          rowClassName={(_, index) =>
            index % 2 === 0 ? "table-row-light text-[13px]" : "table-row-dark text-[13px]"
          }
          bordered={true}
          dataSource={dataSource}
          columns={farmerListColumns}
          pagination={false}
          loading={loading}
        />
      </div>
      <div className="flex justify-end py-3">
        <Pagination
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={defaultCurrent}
          total={farmerList?.total}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={defaultPageSize}
          onChange={paginationChangeHandler}
          className={"text-[13px]"}
        />
      </div>
    </>
  );
};

export default ListFarmer;
