import axios from "axios";
import { store } from "../../app/store";

const headers = {
  "Content-Type": "application/json",
  "Accept": "*/*",
  
  //   Authorization: `Bearer ${store.getState().global.user?.token}`,
  // Authorization: `Bearer ${process.env.REACT_APP_AUTH_TOKEN}`,
};


const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers,
});

axiosInstance.interceptors.request.use((request) => {
  if (store.getState().global.user?.token) {
    request.headers.Authorization = `Bearer ${
      store.getState().global.user?.token
    }`;
  }
  return request;
});




export default axiosInstance;
