import React from "react";
import { BiArrowBack } from "react-icons/bi";
import _ from "lodash";
import EditCorporateInfo from "./EditCorporateInfo";
import EditLocationInfo from "./EditLocationInfo";
import EditBankDetailsInfo from "./EditBankDetailsInfo";
import EditIndividualInfo from "./EditIndividualInfo";
import EditContactPersonInfo from "./EditContactPersonInfo";
import CreateBankDetails from "../CreateBankDetails";


const ViewAdEditFarmerData = (props) => {
  const { setScreen, farmerData, disable } = props;
  return (
    <div>
      <div className={"flex justify-start"}>
        <a
          onClick={() => setScreen("main")}
          className={
            "transition-all text-[#172a4d] text-sm flex items-center"
          }
        >
          <BiArrowBack /> <span className={"text-sm ml-2"}>Back</span>
        </a>
      </div>
      {farmerData?.type_id === 1 ? (
        <EditIndividualInfo farmerData={farmerData} />
      ) : (
        <EditCorporateInfo farmerData={farmerData} />
      )}
      <EditLocationInfo farmerData={farmerData} />
      {farmerData?.type_id !== 1 ? (
        <EditContactPersonInfo farmerData={farmerData} />
      ) : (
        <></>
      )}
     {farmerData?.bank_details[0]?.id ? (<EditBankDetailsInfo farmerData={farmerData} />) 
     : (<CreateBankDetails farmerData={farmerData} />) }
    </div>
  );
};

export default ViewAdEditFarmerData;
