import React, {useEffect, useState} from 'react'
import { Space, Table } from 'antd';
import AgroButton from '../../misc/AgroButton';
import AddAdvert from "./components/AddAdvert";
import useMediaService from "../../../utils/hooks/user/useMediaService";
import {useSelector} from "react-redux";
import {InlineError} from "../../index";
import ListAdvert from "./components/ListAdvert";
import ViewEditAdvert from "./components/ViewEditAdvert";

function UserAdverts() {
  const [tab, setTab] = useState(0);
  const [advert, setAdvert] = useState(null);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    size: 10,
  });

  const {
    advertsLoading,
    advertsFailure,
    setAdvertsFailed,
    getAdverts,
    deleteAdvertLoading,
    deleteAdvert,
  } = useMediaService();

  const { adverts } = useSelector((state) => state.global);
  const handleClick = (index) => {
    if (tab === 1)
      setPaginationData({
        page: 1,
        size: 10,
      });
    setTab(index);
  };

  const handleView = (advert, tab) => {
    setAdvert(advert);
    setTab(tab);
  };

  useEffect(() => {
    getAdverts(paginationData);
  }, [paginationData]);


  const handleCompleted = () => {
    setTab(0);
    setPaginationData({
      page: 1,
      size: 10,
    });
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current,
      size: pageSize,
    });
  };

  return (
      <div>
        <>
          <div className={"flex justify-end gap-x-3"}>
            <AgroButton
                primary={tab === 0}
                text="List Advert"
                className={"text-[12px]"}
                onClick={() => handleClick(0)}
            />
            <AgroButton
                primary={tab === 1}
                text="Add Advert"
                className={"text-[12px]"}
                onClick={() => handleClick(1)}
            />

          </div>
          <div>
            {tab === 0 ?(
                <>
                  <div className={"w-full mt-4"}>
                    <InlineError
                        control={advertsFailure}
                        message={advertsFailure}
                        onClose={() => setAdvertsFailed(null)}
                    />
                  </div>
                  <ListAdvert
                      deleteAdvert={deleteAdvert}
                      adverts={adverts}
                      paginationChangeHandler={paginationChangeHandler}
                      loading={advertsLoading}
                      view={(advert, tab) => handleView(advert, tab)}
                      completed={handleCompleted}
                  />
                </>
            ) : tab === 1 ?(
                <>
                  <AddAdvert completed={handleCompleted} />
                </>
            ):(
                <ViewEditAdvert
                    completed={handleCompleted}
                    advert={advert}
                />
            )}
          </div>
        </>
      </div>
  )
}

export default UserAdverts