import React from "react";
import HomeLeftSidebar from "../../components/user/userHome/HomeLeftSidebar";
import { useSelector } from "react-redux";
import UserLayout from "../../containers/layouts/UserLayout";
import { Card, Col, Row } from "antd";

function UserHome() {
  const { user } = useSelector((state) => state.global);
  const firstName = user?.user?.name.split(" ")[0];

  return (
    <UserLayout leftSidebar={<HomeLeftSidebar />} rightSidebar>
      <div className={"border-b px-3 pb-3"}>
        <p className="text-2xl font-bold">Welcome, {firstName}</p>
      </div>
      
      <div  className="site-card-wrapper mt-8">
    <Row gutter={12}>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
        title="Total Farmers" bordered={false}>
         300
        </Card>
      </Col>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
        title="Cooperative" bordered={false}>
          Mowe Egg Cooperative
        </Card>
      </Col>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
         title="Association" bordered={false}>
          Kebbi Rice
        </Card>
      </Col>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
         title="Farmer Group" bordered={false}>
         Dangote Offtaker
        </Card>
      </Col>
    </Row>
    <Row gutter={12} className = "mt-14">
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
        title="Loans" bordered={false}>
       <p>Bank A  Loan</p>
       <p>Bank B  Loan</p>
       <p> </p>
        </Card>
      </Col>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
        title="Grants" bordered={false}>
           <p>UN Grant</p>
           <p>GIZ Grant</p>
        </Card>
      </Col>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
         title="Technical Assistant" bordered={false}>
          <p>NIRSAL</p>
          <p>FMARD</p>
          <p> </p>
        </Card>
      </Col>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
         title="Tenders" bordered={false}>
         <p>Dangote</p>
         <p>FMN</p>
         <p> </p>
        </Card>
      </Col>
    </Row>
  </div>
    </UserLayout>
  );
}

export default UserHome;
