import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/general/Homepage";
import { UserHome } from "./pages";
import { appRoutes } from "./utils/misc/navData";
import Aos from "aos";
import "aos/dist/aos.css";
import { userRoutes } from "./utils/misc/userHomeData";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 1000,
      easing: "ease-in-sine",
      delay: 100,
    });
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<UserHome />} />
        {appRoutes.map(({ path, element, children }) =>
          !children?.length ? (
            <Route key={path} path={path} element={element} />
          ) : (
            <Route key={path} path={path} element={element}>
              {children.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}
            </Route>
          )
        )}

        {/* {userRoutes.map(({ basePath, base, children }) => (
					<Route key={basePath} path={basePath} element={base}>
						{children.map(({ path, element }) => (
							<Route key={path} path={path} element={element} />
						))}
					</Route>
				))} */}
      </Routes>
    </Router>
  );
}

export default App;
