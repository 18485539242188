import { Dropdown, Menu } from "antd";
import { IoIosArrowForward } from "react-icons/io";

function AboutOverlay() {
/*   const menu = (
    <Menu>
      <Menu.Item>Single Post Layout 01</Menu.Item>
      <Menu.Item>Single Post Layout 02</Menu.Item>
      <Menu.Item>Single Post Layout 03</Menu.Item>
      <Menu.Item>Single Post Layout 04</Menu.Item>
    </Menu>
  ); */

  return (
    <div className="relative p-3 lg:p-5 mt-4 bg-white shadow-lg transition-all duration-300">
      <div className="absolute -top-1 left-0 nav-bg w-[70px] h-1" />

      <p className="relative group my-1.5 cursor-pointer text-black text-opacity-70 hover:text-green-400 transition-all text-left">
        Blog
        <span className="absolute top-1.5 -left-2 w-1 h-0 bg-white group-hover:bg-green-300 group-hover:h-2.5 transition-all duration-500" />
      </p>

      <p className="relative group my-1.5 cursor-pointer text-black text-opacity-70 hover:text-green-400 transition-all text-left">
       Directory
        <span className="absolute top-1.5 -left-2 w-1 h-0 bg-white group-hover:bg-green-300 group-hover:h-2.5 transition-all duration-500" />
      </p>

      <p className="relative group my-1.5 cursor-pointer text-black text-opacity-70 hover:text-green-400 transition-all text-left">
       Forum
        <span className="absolute top-1.5 -left-2 w-1 h-0 bg-white group-hover:bg-green-300 group-hover:h-2.5 transition-all duration-500" />
      </p>



  {/*     <Dropdown overlay={menu}>
        <div className="flexed relative group text-black text-opacity-70 hover:text-green-400 transition-all">
          <p className="cursor-pointer mb-0">Single Post Layout</p>
          <IoIosArrowForward className="ml-1 font-semibold" size="15px" />
          <span className="absolute top-1.5 -left-2 w-1 h-0 bg-white group-hover:bg-green-300 group-hover:h-2.5 transition-all duration-500" />
        </div>
      </Dropdown> */}
    </div>
  );
}

export default AboutOverlay;
