import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { IoCloseSharp, IoMenuOutline } from "react-icons/io5";
import SignIn from "../../pages/auth/SignIn";
import UserNav from "../navigation/user/UserNav";
import Footer from "../footer/Footer";
import { toggleUserNav } from "../../slices/globalSlice";
import { useDispatch } from "react-redux";

function UserLayout({ leftSidebar, rightSidebar, mobileNavItems, children }) {
  const dispatch = useDispatch();
  const { user, isUserMobNavOpen } = useSelector((state) => state.global);
  const savedUser = JSON.parse(localStorage.getItem("agro-user"));

  return !user && !savedUser ? (
    <SignIn />
  ) : (
    <>
      <UserNav />
      <main className="flex items-start bg-gray-200 justify-center lg:justify-between">
        <Nav className="hidden lg:inline h-full w-[17%] py-3">
          {leftSidebar}
        </Nav>
        <Div className={`w-full px-6 relative py-3 ${rightSidebar && "lg:w-[85%]"}`}>
          <div
            onClick={() => dispatch(toggleUserNav())}
            className="lg:hidden fixed right-0 top-16 z-50 shadow-md bg-white cursor-pointer transition-all"
          >
            {isUserMobNavOpen ? (
              <IoCloseSharp size="2rem" className="grn-text" />
            ) : (
              <IoMenuOutline size="2rem" className="grn-text" />
            )}
          </div>
          {children}

          <div
            className={`absolute top-0 left-0 w-3/4 md:w-1/4 h-full bg-gray-300 shadow-xl transition-all border border-r-gray-700 lg:hidden flex flex-col ${
              isUserMobNavOpen
                ? "translate-x-0 opacity-100"
                : "-translate-x-[110%] opacity-0"
            }`}
          >
            {leftSidebar}
          </div>
        </Div>
        {rightSidebar && (
          <div className="hidden w-[17%]  py-3 pl-1.5">
            <div className="mb-3 flex flex-col p-2">
              <p className="my-2 font-bold text-lg text-black text-opacity-60">
                Sponsored
              </p>
              <img
                src="/images/advertImgs/lucozade.jpg"
                className="object-cover"
                alt="advert"
              />
            </div>
            {rightSidebar}
          </div>
        )}
      </main>
      <Footer />
    </>
  );
}

export default UserLayout;

const Div = styled.div`
  min-height: calc(100vh);
  padding-top: 20px;
  margin-bottom: 16px;
  box-shadow: 1px 0px 10px 1px rgba(202, 202, 202, 0.05);
  -webkit-box-shadow: 1px 0px 10px 1px rgba(202, 202, 202, 0.05);
  -moz-box-shadow: 1px 0px 10px 1px rgba(202, 202, 202, 0.05);
  background: #ffffff;
`;

const Nav = styled.div`
  min-height: calc(100vh);
  padding-top: 20px;
  margin-bottom: 16px;
  box-shadow: 5px 1px 11px 0px rgba(158, 149, 149, 0.2);
  -webkit-box-shadow: 5px 1px 11px 0px rgba(158, 149, 149, 0.2);
  -moz-box-shadow: 5px 1px 11px 0px rgba(158, 149, 149, 0.2);
  background: #ffffff;
`;
