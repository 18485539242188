import React, {useState, useEffect, useRef} from "react";
import { Table, Pagination } from "antd";
import { cooperativesMemberDuesListColumns } from "../../../../../utils/misc/groupServices";
import useCountriesStates from "../../../../../utils/hooks/auth/useCountriesStates";
import { Link } from "react-router-dom";
import AgroButton from "../../../../misc/AgroButton";

const CooperativeMemberDueDetail = (props) => {
  const openModal = props.openModal;

    // let dueFormRefs = useRef([React.createRef(), React.createRef()]);

/*   const stateGetter = (countryId) => {
    getStates(countryId)
    console.log(countriesData);
  }
 */

  const [statArray, setStateArray] = useState([])
  const { dues, paginationChangeHandler, loading, singleDueLoading } = props;
  const defaultCurrent = 1;
  const defaultPageSize = 10;
  const data = dues;

  let dataSource =
    data &&
    data.length > 0 &&
    data.map((row, index) => {
  
      return {
        key: row?.id,
        due: row?.name,
        createdAt: row?.created_at.substring(0, 10),
        type: row?.type,
        amount: row?.amount,
        status: 'Unpaid',
        pay: (
          <div className={"flex justify-between"}>
           
              <AgroButton
                primary
                text={"Pay"}
                type="button"
                className={"text-[10px]"}
                id={row?.id}
                onClick={openModal}
              />
          </div> 
          
         
        ),
       
      };
    });

   
  const onShowSizeChange = (current, pageSize) => {
    return { current, pageSize };
  };

//   useEffect(() => {
//     dueFormRefs.current[0].current.focus();
//   }, []);

  return (
    <>
      <div className={"mt-6"}>
        <Table
          className={"farmerList text-[14px]"}
          rowClassName={(_, index) =>
            index % 2 === 0
              ? "table-row-light text-[13px]"
              : "table-row-dark text-[13px]"
          }
          bordered={true}
          dataSource={dataSource}
          columns={cooperativesMemberDuesListColumns}
          pagination={false}
          // loading={loading?loading:(singleDueLoading&singleDueLoading)}
          loading={singleDueLoading}
        />
      </div>
      <div className="flex justify-end py-3">
        <Pagination
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={defaultCurrent}
          total={dues?.total}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={defaultPageSize}
          onChange={paginationChangeHandler}
          className={"text-[13px]"}
        />
      </div>
    </>
  );
};

export default CooperativeMemberDueDetail;
