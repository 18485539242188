import React, { useState } from "react";
import { Table, Pagination, message } from "antd";
import _ from "lodash";
import useGroupServices from "../../../../../utils/hooks/user/useGroupServices";
import InlineError from "../../../../misc/InlineError";
import { formatDate } from "../../../../../utils/misc/handlers";
import { farmersNotInCooperativeListColumns } from "../../../../../utils/misc/groupServices";
import AgroButton from "../../../../misc/AgroButton";
const success = "Successful";
const error = "An error has occured";

const AddMember = (props) => {
  const {
    farmersNotInCooperative,
    paginationChangeHandler,
    loading,
    completed,
    cooperative,
  } = props;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const defaultCurrent = 1;
  const defaultPageSize = 10;
  const data = farmersNotInCooperative?.data;
  let dataSource =
    data &&
    data.length > 0 &&
    data.map((row, index) => {
      return {
        key: row?.id,
        sn: index + 1,
        name:
          row?.first_name || row?.last_name
            ? `${row?.first_name ?? ""} ${row?.last_name ?? ""}`
            : row?.company_name,
        createdAt: formatDate(row?.created_at),
        phone: row?.phone ?? "-",
      };
    });
  const {
    addCooperativeMembersLoading,
    addCooperativeMembersFailure,
    setAddCooperativeMembersFailure,
    addCooperativeMembers,
  } = useGroupServices();

  const onShowSizeChange = (current, pageSize) => {
    return { current, pageSize };
  };

  const addMembers = () => {
    const data = {
      farmer_records: selectedRowKeys,
    };
    const formData = new FormData();
    _.forEach(data, (value, key) => {
      if (typeof value === "object") {
        if (value?.length) {
          for (let i = 0; i < value.length; i++) {
            formData.append(`${key}[]`, value[i]);
          }
        }
      } else {
        formData.append(key, value);
      }
    });

    addCooperativeMembers(cooperative?.id, formData).then((data) => {
      if (data?.success) {
        message.success(data?.message || success);
        setSelectedRowKeys([]);
        completed();
      } else {
        message.error(data?.message || error);
      }
    });
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <InlineError
        control={addCooperativeMembersFailure}
        message={addCooperativeMembersFailure}
        onClose={() => setAddCooperativeMembersFailure(null)}
      />
      <div className={"mt-2"}>
        <div className={"flex items-center gap-x-6 mb-2"}>
          <AgroButton
            loading={addCooperativeMembersLoading}
            primary
            onClick={addMembers}
            disabled={addCooperativeMembersLoading || !hasSelected}
            text={
              addCooperativeMembersLoading ? "Please wait..." : "Add Member"
            }
            className={"text-[10px]"}
          />
          <span>
            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
          </span>
        </div>
        <Table
          className={"farmerList text-[14px]"}
          rowClassName={(_, index) =>
            index % 2 === 0
              ? "table-row-light text-[13px]"
              : "table-row-dark text-[13px]"
          }
          bordered={true}
          dataSource={dataSource}
          columns={farmersNotInCooperativeListColumns}
          pagination={false}
          loading={loading}
          rowSelection={rowSelection}
        />
      </div>
      <div className="flex justify-end py-3">
        <Pagination
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={defaultCurrent}
          total={farmersNotInCooperative?.total}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={defaultPageSize}
          onChange={paginationChangeHandler}
          className={"text-[13px]"}
        />
      </div>
    </>
  );
};

export default AddMember;
