import React from "react";
import moment from "moment";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BiCalendar } from "react-icons/bi";
import { FaRegComments } from "react-icons/fa";
import HomeBlogItem from "./HomeBlogItem";
import { PaddedContainer } from "../..";

function BlogSection({ latestPosts }) {
  return (
    latestPosts?.length > 0 && (
      <PaddedContainer>
        <Section className="mt-9 flex flex-wrap items-start justify-between container">
          <div className="w-full group lg:w-[49%] shadow-md">
            <div className="relative">
              <img
                src={latestPosts[0].featured_image}
                alt="blog-image"
                className="left__blog-img transition-all duration-500"
              />
              <div className="absolute img-overlay left-0 bottom-0 top-0 right-0 w-full h-full">
                <div className="mt-auto absolute bottom-2 md:bottom-5 left-5 md:left-10 pr-4">
                  <p className="bg-[#3dd800] text-center text-sm lg:text-[15px] text-white font-bold uppercase tracking-wide py-1 px-3 mb-2 md:mb-3 w-max">
                    {latestPosts[0].meta}
                  </p>
                  <Link to={`/#`}>
                    <p className="text-white post-title hover:text-[#3dd800] transition-all font-black md:text-lg lg:text-[20px] mb-2">
                      {latestPosts[0].title}
                    </p>
                  </Link>

                  <div className="flex items-center text-white uppercase ">
                    <p className="text-[10px] md:text-md lg:text-[15px] mb-0 font-semibold">
                      BY ADMIN{" "}
                    </p>
                    <span className="mx-3 flex items-center font-semibold text-[10px] md:text-md lg:text-[15px]">
                      <BiCalendar className="mr-1 text-[12px] md:text-md lg:text-[15px]" />{" "}
                      {moment(latestPosts[0].created_at).format("MMMM d, YYYY")}
                    </span>
                    <span className="flex items-center font-semibold text-[10px] md:text-md lg:text-[15px]">
                      <FaRegComments size="17px" className="mr-2" />{" "}
                      {latestPosts[0].comments.length} comments
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-fulld lg:grid gap-y-9 h-full lg:w-[49%]">
            {latestPosts?.slice(1, 4).map((item) => (
              <HomeBlogItem item={item} key={item.title} />
            ))}
          </div>
        </Section>
      </PaddedContainer>
    )
  );
}

const Section = styled.section`
  @media (min-width: 768px) {
    .left__blog-img {
      object-fit: cover;
    }
  }

  .img-overlay {
    background-image: linear-gradient(
      rgba(0, 0, 0, 0.01),
      rgba(0, 0, 0, 0.06),
      rgba(0, 0, 0, 0.9)
    );
  }

  /* .post-title {
		position: relative;
		overflow-wrap: break-word;
	}

	.post-title::before {
		position: absolute;
		content: "";
		width: 100%;
		white-space: normal;
		height: 3px;
		bottom: -1px;
		background-color: #3dd800;
		visibility: hidden;
		transform: scaleX(0);
		transform-origin: left 2px;
		transition: all 0.3s ease-in-out;
	}

	.post-title:hover:before {
		visibility: visible;
		transform: scaleX(1);
	} */
`;

export default BlogSection;
