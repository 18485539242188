import React from "react";
import { Form, Input } from "antd";
import Focus from "./Focus";

const IndividualInfo = ({
  form,
  categories,
  setFocuses,
  existingFocuses,
  disable,
  handleRemove,
}) => {
  const getFocuses = (focuses) => {
    setFocuses(focuses);
  };

  return (
    <>
      <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
        <div className="w-full md:w-[29%] lg:my-0 ">
          <p className="form-label">First Name</p>
          <Form.Item
            name="first_name"
            rules={[
              { required: true, message: "Required" },
              { min: 2, message: "Must be at least 2 characters" },
            ]}
          >
            <Input
              className="form-field__input__2"
              placeholder="John"
              disabled={disable}
            />
          </Form.Item>
        </div>
        <div className="w-full md:w-[29%] lg:my-0 ">
          <p className="form-label">Last Name</p>
          <Form.Item
            name="last_name"
            rules={[
              { required: true, message: "Required" },
              { min: 2, message: "Must be at least 2 characters" },
            ]}
          >
            <Input
              className="form-field__input__2"
              placeholder="Doe"
              disabled={disable}
            />
          </Form.Item>
        </div>
        <div className="w-full md:w-[29%] lg:my-0 ">
          <p className="form-label">Middle Name</p>
          <Form.Item
            name="middle_name"
            rules={[{ min: 2, message: "Must be at least 2 characters" }]}
          >
            <Input
              className="form-field__input__2"
              placeholder="Clark"
              disabled={disable}
            />
          </Form.Item>
        </div>
      </div>

      <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
        <div className="w-full md:w-[47%] lg:my-0">
          <p className="form-label">Phone Number</p>
          <Form.Item
            name="phone_number"
            rules={[
              { required: true, message: "Required" },
              { min: 11, message: "Must be at least 11 characters" },
            ]}
          >
            <Input
              type="number"
              onInput={(e) => e.target.value = e.target.value.slice(0, 11)} // js validation to check that number does not exceed 11 characters
              className="form-field__input__2"
              placeholder="08012345678"
              disabled={disable}
            />
          </Form.Item>
        </div>

        <div className="w-full md:w-[47%] lg:my-0">
          <p className="form-label">Email Address</p>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Required" },
              { type: "email", message: "Invalid Email" },
            ]}
          >
            <Input
              className="form-field__input__2"
              placeholder="matt@agroclerk.com"
              disabled={disable}
            />
          </Form.Item>
        </div>
      </div>

      <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
        <div className="w-full lg:my-0">
          <p className="form-label">Website</p>
          <Form.Item
            name="website"
            initialValue="https://"
          >
            <Input
              className="form-field__input__2"
              disabled={disable}
            />
          </Form.Item>
        </div>
      </div>

      <div className="flex items-center justify-start md:justify-between flex-wrap w-full">
        <div className="w-full mb-2 flex justify-between items-center">
          <p className="form-label">Focus</p>
        </div>
        <Focus
          categories={categories}
          form={form}
          getFocuses={(focuses) => getFocuses(focuses)}
          existingFocuses={existingFocuses}
          disable={disable}
          handleRemove={(index) => handleRemove(index)}
        />
      </div>
    </>
  );
};

export default IndividualInfo;
