import React from "react";

function PaddedContainer({ children }) {
	return (
		<section className="mb-4 w-full">
			{children}
		</section>
	);
}

export default PaddedContainer;
