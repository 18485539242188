import { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { Link } from "react-router-dom";
/*  import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";  */
/* import en from "world_countries_lists/data/countries/en/world.json"; */
import useCountriesStates from "../../utils/hooks/auth/useCountriesStates";
import useRegister from "../../utils/hooks/auth/useRegister";
import _ from "lodash";
import AuthLayout from "../../containers/layouts/AuthLayout";
import { AgroButton, InlineError } from "../../components";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function SignUp() {
  const [form] = Form.useForm();
  const { countriesData, getCountries, statesData, getStates } =
    useCountriesStates();
  const [phoneId, setPhoneId] = useState(null)

  console.log("value", phoneId)
  const { regLoading, regFailure, setRegFailure, registerUser } = useRegister();
/*   const [phone, setPhone] = useState({ short: "ng" }); */

  const onSubmit = (values) => {
    const data = {
      ...values,
      full_name: `${values?.first_name} ${values?.last_name}`,
      'phone_number': phoneId,
    };
    delete data?.first_name;
    delete data?.last_name;
    const formData = new FormData();
    _.forEach(data, (value, key) => {
      formData.append(key, value);
    });
    registerUser(formData);
  };


  /*   const handleSetPhone = (value) => {
      setPhone(value);
      form.setFieldsValue({ phone_number: value?.phone });
    };
   */
  function handleCountryChange(e) {
    const id = e.target.value;
    getStates(id);
  }

  useEffect(() => {
    getCountries();
  }, []);

  const uppercaseRegex = /(?=.*[A-Z])/;

  return (
    <AuthLayout>
      <p className="mb-4 font-semibold text-lg lg:text-xl text-center">
        Create an account
      </p>
      <InlineError
        control={regFailure}
        message={regFailure}
        onClose={() => setRegFailure(null)}
      />
      {/*  <ConfigProvider locale={en}>  */}
        <Form
          form={form}
          onFinish={onSubmit}
          autoComplete="on"
          className="mt-5"
        >
          <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
            <div className="w-full md:w-[47%] lg:my-0 ">
              <p className="form-label">First Name</p>
              <Form.Item
                name="first_name"
                rules={[
                  { required: true, message: "Required" },
                  { min: 2, message: "Must be at least 2 characters" },
                ]}
              >
                <Input
                  className="form-field__input"
                  placeholder="Matthew Jones"
                />
              </Form.Item>
            </div>

            <div className="w-full md:w-[47%] lg:my-0">
              <p className="form-label">Last Name</p>
              <Form.Item
                name="last_name"
                rules={[
                  { required: true, message: "Required" },
                  { min: 2, message: "Must be at least 2 characters" },
                ]}
              >
                <Input className="form-field__input" placeholder="MattJones" />
              </Form.Item>
            </div>
          </div>

         {/* <div className="flex items-center justify-start md:justify-between flex-wrap w-full">*/}
         {/*   <div className="w-full md:w-[47%] lg:my-0">*/}
         {/*     <p className="form-label">Phone Number</p>*/}
         {/*/!*      <Form.Item*/}
         {/*       initialValue={{*/}
         {/*         short: "ng",*/}
         {/*       }}*/}
         {/*       className={"m-0"}*/}
         {/*     >*/}
         {/*       <CountryPhoneInput*/}
         {/*         value={phone}*/}
         {/*         onChange={(v) => {*/}
         {/*           handleSetPhone(v);*/}
         {/*         }}*/}
         {/*         className="form-field__input phone_input"*/}
         {/*       /> */}
         {/*       </Form.Item>  *!/*/}
         {/*       */}
         {/*       <Form.Item*/}
         {/*   name="phone_number"*/}
         {/*   rules={[*/}
         {/*     { required: true, message: "Required" },*/}
         {/*     { min: 11, message: "Must be at least 11 characters" },*/}
         {/*   ]}*/}
         {/* >*/}
         {/*       <Input*/}
         {/*     type="number"*/}
         {/*     onInput={(e) => e.target.value = e.target.value.slice(0, 11)} // js validation to check that number does not exceed 11 characters*/}
         {/*     className="form-field__input"*/}
         {/*     placeholder="08012345678"*/}
         {/*  */}
         {/*   />*/}
         {/* </Form.Item> */}
         {/*   </div>*/}

         {/*   <div className="w-full md:w-[47%] lg:my-0">*/}
         {/*     <p className="form-label">Email Address</p>*/}
         {/*     <Form.Item*/}
         {/*       name="email"*/}
         {/*       rules={[*/}
         {/*         { required: true, message: "Required" },*/}
         {/*         { type: "email", message: "Invalid Email" },*/}
         {/*       ]}*/}
         {/*     >*/}
         {/*       <Input*/}
         {/*         className="form-field__input"*/}
         {/*         placeholder="matt@agroclerk.com"*/}
         {/*       />*/}
         {/*     </Form.Item>*/}
         {/*   </div>*/}
         {/* </div>*/}

         <div className="flex items-center justify-start md:justify-between flex-wrap w-full">
            <div className="w-full md:w-[47%] lg:my-0" style={{marginTop:"-23px"}}>
              <p className="form-label">Phone Number</p>
                  <PhoneInput
                      placeholder="08012345678"
                      // onInput={(e) => e.target.value = e.target.value.slice(0, 11)} // js validation to check that number does not exceed 11 characters
                      value={phoneId}
                      country={'ng'}
                      enableSearch={true}
                      countryCodeEditable={false}
              inputProps={{ naPhoneInputme: 'phone_number', required: true, autoFocus: true, maxLength:"17"}}
                      onChange={setPhoneId}
                  />
            </div>

            <div className="w-full md:w-[47%] lg:my-0">
              <p className="form-label">Email Address</p>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Required" },
                  { type: "email", message: "Invalid Email" },
                ]}
              >
                <Input
                  className="form-field__input"
                  placeholder="matt@agroclerk.com"
                />
              </Form.Item>
            </div>
          </div>

          <div className="flex items-center justify-start md:justify-between flex-wrap w-full">
            <div className="w-full md:w-[47%] lg:my-0">
              <p className="form-label">Password</p>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Required" },
                  { min: 8, message: "Must be at least 8 characters" },
                  {
                    validator: (_, value) =>
                      !value || (value && value.match(uppercaseRegex))
                        ? Promise.resolve()
                        : Promise.reject("Must include one uppercase letter"),
                  },
                ]}
              >
                <Input.Password
                  className="form-field__input"
                  placeholder="Enter your password"
                />
              </Form.Item>
            </div>

            <div className="w-full md:w-[47%] lg:my-0">
              <p className="form-label">Confirm Password</p>
              <Form.Item
                name="password_confirmation"
                rules={[
                  { required: true, message: "Required" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("Passwords do not match");
                    },
                  }),
                ]}
              >
                <Input.Password
                  className="form-field__input"
                  placeholder="Confirm password"
                />
              </Form.Item>
            </div>
          </div>

          <div className="flex items-center justify-start md:justify-between flex-wrap w-full">
            <div className="w-full md:w-[47%] lg:my-0 ">
              <p className="form-label">Country</p>
              <Form.Item
                onChange={handleCountryChange}
                name="country_id"
                rules={[{ required: true, message: "Required" }]}
              >
                <select className="form-field__input bg-transparent form-field__sel">
                  <option value={""}>Select Country</option>
                  {countriesData?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item?.name?.length > 20
                        ? `${item.name.substring(0, 30)}...`
                        : item.name}
                    </option>
                  ))}
                </select>
              </Form.Item>
            </div>

            <div className="w-full md:w-[47%] lg:my-0">
              <p className="form-label">State</p>
              <Form.Item
                className="w-fulllg:my-0"
                name="state_id"
                rules={[{ required: true, message: "Required" }]}
              >
                <select className="form-field__input bg-transparent form-field__sel">
                  <option value={""}>Select State</option>
                  {statesData?.map((state) => (
                    <option key={state?.name} value={state?.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </Form.Item>
            </div>
          </div>

          <div className="w-full lg:my-0 ">
            <p className="form-label">Referral Code</p>
            <Form.Item name="referral_code" dependencies={["password"]}>
              <Input
                type="tel"
                className="form-field__input"
                placeholder="Optional"
              />
            </Form.Item>
          </div>

          <div className="flex justify-center my-1">
            <AgroButton
              loading={regLoading}
              disabled={regLoading}
              primary
              text={regLoading ? "Submitting" : "Sign Up"}
              type="submit"
            />
          </div>

          <p className="text-sm mt-3">
            <Link
              className="text-orange-400 hover:underline hover:text-orange-400 ml-1.5  font-semibold tracking-wide"
              to="/auth/signin"
            >
              Already have an account?
            </Link>
          </p>
        </Form>
    {/*    </ConfigProvider>  */} 
    </AuthLayout>
  );
}

export default SignUp;
