import React from "react";
import { ImSpinner9 } from "react-icons/im";

const AgroButton =  React.forwardRef(({
	text,
	className,
	primary,
	secondary,
	hasIcon,
	Icon,
	loading,
	disabled,
	type,
	hasBorder,
	onClick,
	id
}, ref)=>{
	return (
		<button
			type={type}
			disabled={disabled}
			onClick={onClick}
			id={id}
			ref={ref}
			className={`transition-all hover:bg-[#28812c] tracking-wider rounded-[2px] flex items-center ${
				primary ? "text-white" : "text-black hover:text-white"
			} py-[6px] px-3 ${
				hasBorder ? "border border-white" : "border border-gray-400"
			} ${primary ? "bg-[#3dd800]" : "bg-transparent"} ${className}`}>
			{loading && (
				<span>
					<ImSpinner9 className="w-5 h-5 mr-3 text-white animate-spin" />
				</span>
			)}
			{text}
			{Icon && <span className="text-lg ml-2">{Icon}</span>}
		</button>
	);
})

export default AgroButton;
