import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import useCountriesStates from "../../../../utils/hooks/auth/useCountriesStates";
import { AgroButton, InlineError } from "../../..";
import useGeneric from "../../../../utils/hooks/user/useGeneric";
import useFieldService from "../../../../utils/hooks/user/useFieldService";

const { TextArea } = Input;

const CreateAggregation = ({ completed }) => {
  const {
    createAggregationLoading,
    createAggregationFailure,
    setCreateAggregationFailure,
    createAggregation,
  } = useFieldService();

  const [pickupStates, setPickupStates] = useState([]);
  const [pickupCities, setPickupCities] = useState([]);
  const [deliveryStates, setDeliveryStates] = useState([]);
  const [deliveryCities, setDeliveryCities] = useState([]);
  const [mode, setMode] = useState(1);

  const {
    countriesData,
    getCountries,
    statesData,
    getStates,
    setStatesData,
    citiesData,
    getCities,
    setCitiesData,
  } = useCountriesStates();

  const {
    categoriesData,
    getCategories,
    subcategoriesData,
    setSubcategoriesData,
    getSubcategories,
    itemsData,
    setItemsData,
    getItems,
  } = useGeneric();

  const handleCountryChange = (e, mode) => {
    const id = e.target.value;
    setMode(mode);
    setStatesData([]);
    setCitiesData([]);
    getStates(id);
  };

  const handleStateChange = (e, mode) => {
    const id = e.target.value;
    setMode(mode);
    setCitiesData([]);
    getCities(id);
  };

  const handleCategoryChange = (e) => {
    const id = e.target.value;
    setSubcategoriesData([]);
    setItemsData([]);
    getSubcategories(id);
  };

  const handleSubCategoryChange = (e) => {
    const id = e.target.value;
    setItemsData([]);
    getItems(id);
  };

  useEffect(() => {
    getCountries();
    getCategories();
  }, []);

  useEffect(() => {
    if (mode === 1) setPickupStates(statesData);
    if (mode === 2) setDeliveryStates(statesData);
  }, [statesData]);

  useEffect(() => {
    if (mode === 1) setPickupCities(citiesData);
    if (mode === 2) setDeliveryCities(citiesData);
  }, [citiesData]);

  const onSubmit = (values) => {
    const data = {
      name: values?.name,
      item_category_id: values.category,
      item_sub_category_id: values.sub_category,
      item_id: values.item,
      quantity: values.total_quantity,
      unit_measurement: "Kg",
      minimum_supply_quantity: values.minimum_supply_quantity,
      minimum_price: values.lower_price_range,
      maximum_price: values.higher_price_range,
      currency: "ngn",
      description: values.price_comment,
      supply_modes: [
        {
          country: values.pickup_country,
          state: values.pickup_state,
          city: values.pickup_city,
          address: values.pickup_address,
          mode_id: 1,
          description: values.pickup_description,
        },
        {
          country: values.delivery_country,
          state: values.delivery_state,
          city: values.delivery_city,
          address: values.delivery_address,
          mode_id: 2,
          description: values.delivery_description,
        },
      ],
    };
    createAggregation(data).then(() => {
      if (!createAggregationFailure) {
        completed();
      }
    });
  };

  return (
    <div>
      <InlineError
        control={createAggregationFailure}
        message={createAggregationFailure}
        onClose={() => setCreateAggregationFailure(null)}
      />

      <Form onFinish={onSubmit} autoComplete="on" className="mt-10">
        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full lg:my-0">
            <p className="form-label">Product Name</p>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "Required" },
                { min: 3, message: "Must be at least 3 characters" },
              ]}
            >
              <Input
                type="text"
                className="form-field__input__2"
                placeholder="Yam"
              />
            </Form.Item>
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full md:w-[29%] lg:my-0 mt-10">
            <p className="form-label">Category</p>
            <Form.Item
              name={"category"}
              rules={[{ required: true, message: "Required" }]}
              onChange={handleCategoryChange}
            >
              <select className="form-field__input__2 form-field__sel bg-white">
                <option value={""}>Select Category</option>
                {categoriesData?.map((category) => (
                  <option key={category?.name} value={category?.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>
          <div className="w-full md:w-[29%] lg:my-0 mt-10">
            <p className="form-label">Sub Category</p>
            <Form.Item
              name={"sub_category"}
              rules={[{ required: true, message: "Required" }]}
              onChange={handleSubCategoryChange}
            >
              <select className="form-field__input__2 form-field__sel bg-white">
                <option value={""}>Select Sub Category</option>
                {subcategoriesData?.map((subcategory) => (
                  <option key={subcategory?.name} value={subcategory?.id}>
                    {subcategory.name}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>
          <div className="w-full md:w-[29%] lg:my-0 mt-10">
            <p className="form-label">Item</p>
            <Form.Item
              name={"item"}
              rules={[{ required: true, message: "Required" }]}
            >
              <select className="form-field__input__2 form-field__sel bg-white">
                <option value={""}>Select Item</option>
                {itemsData?.map((item) => (
                  <option key={item?.name} value={item?.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full md:w-[47%] lg:my-0">
            <p className="form-label">Total Quantity</p>
            <Form.Item
              name="total_quantity"
              rules={[
                { required: true, message: "Required" },
                { min: 1, message: "Must be at least 1 characters" },
              ]}
            >
              <Input
                type="tel"
                className="form-field__input__2"
                placeholder="10"
              />
            </Form.Item>
          </div>

          <div className="w-full md:w-[47%] lg:my-0">
            <p className="form-label">Minimum Supply Quantity</p>
            <Form.Item
              name="minimum_supply_quantity"
              rules={[
                { required: true, message: "Required" },
                { min: 1, message: "Must be at least 1 character" },
              ]}
            >
              <Input
                type="tel"
                className="form-field__input__2"
                placeholder="10"
              />
            </Form.Item>
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full md:w-[47%] lg:my-0">
            <p className="form-label">Lower Price Range</p>
            <Form.Item
              name="lower_price_range"
              rules={[
                { required: true, message: "Required" },
                { min: 1, message: "Must be at least 1 characters" },
              ]}
            >
              <Input
                type="tel"
                className="form-field__input__2"
                placeholder="2000"
              />
            </Form.Item>
          </div>

          <div className="w-full md:w-[47%] lg:my-0">
            <p className="form-label">Higher Price Range</p>
            <Form.Item
              name="higher_price_range"
              rules={[
                { required: true, message: "Required" },
                { min: 1, message: "Must be at least 1 character" },
              ]}
            >
              <Input
                type="tel"
                className="form-field__input__2"
                placeholder="10000"
              />
            </Form.Item>
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full lg:my-0">
            <p className="form-label">Price Comment</p>
            <Form.Item
              name="price_comment"
              rules={[
                { required: true, message: "Required" },
                { min: 5, message: "Must be at least 5 characters" },
              ]}
            >
              <Input
                type="text"
                className="form-field__input__2"
                placeholder="Best price"
              />
            </Form.Item>
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full mb-2 flex justify-between items-center">
            <p className="form-label">Delivery</p>
          </div>
          <div className="w-full md:w-[29%] lg:my-0 mt-10">
            <p className="form-label text-xs">Country</p>
            <Form.Item
              onChange={(e) => handleCountryChange(e, 2)}
              name={"pickup_country"}
              rules={[{ required: true, message: "Required" }]}
            >
              <select className="form-field__input__2 bg-white form-field__sel">
                <option value={""}>Select Country</option>
                {countriesData?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item?.name?.length > 20
                      ? `${item.name.substring(0, 30)}...`
                      : item.name}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">State</p>
            <Form.Item
              onChange={(e) => handleStateChange(e, 2)}
              name={"pickup_state"}
              rules={[{ required: true, message: "Required" }]}
            >
              <select className="form-field__input__2 bg-white form-field__sel">
                <option value={""}>Select State</option>
                {deliveryStates?.map((state) => (
                  <option key={state?.name} value={state?.id}>
                    {state.name}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">City</p>
            <Form.Item
              name={"pickup_city"}
              rules={[{ required: true, message: "Required" }]}
            >
              <select className="form-field__input__2 form-field__sel bg-white">
                <option value={""}>Select City</option>
                {deliveryCities?.map((city) => (
                  <option key={city?.name} value={city?.id}>
                    {city.name}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>

          <div className="w-full lg:my-0">
            <p className="form-label">Address</p>
            <Form.Item
              name="pickup_address"
              rules={[
                { required: true, message: "Required" },
                { min: 5, message: "Must be at least 5 characters" },
              ]}
            >
              <Input
                type="text"
                className="form-field__input__2"
                placeholder="57, Avenue Street"
              />
            </Form.Item>
          </div>

          <div className="w-full lg:my-0">
            <p className="form-label">Description</p>
            <Form.Item
              name="pickup_description"
              rules={[
                { required: true, message: "Required" },
                { min: 5, message: "Must be at least 5 characters" },
              ]}
            >
              <TextArea
                rows={3}
                className="form-field__input__2"
                placeholder="Description."
              />
            </Form.Item>
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full mb-2 flex justify-between items-center">
            <p className="form-label">Pick Up</p>
          </div>
          <div className="w-full md:w-[29%] lg:my-0 mt-10">
            <p className="form-label text-xs">Country</p>
            <Form.Item
              onChange={(e) => handleCountryChange(e, 1)}
              name={"delivery_country"}
              rules={[{ required: true, message: "Required" }]}
            >
              <select className="form-field__input__2 bg-white form-field__sel">
                <option value={""}>Select Country</option>
                {countriesData?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item?.name?.length > 20
                      ? `${item.name.substring(0, 30)}...`
                      : item.name}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">State</p>
            <Form.Item
              onChange={(e) => handleStateChange(e, 1)}
              name={"delivery_state"}
              rules={[{ required: true, message: "Required" }]}
            >
              <select className="form-field__input__2 bg-white form-field__sel">
                <option value={""}>Select State</option>
                {pickupStates?.map((state) => (
                  <option key={state?.name} value={state?.id}>
                    {state.name}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">City</p>
            <Form.Item
              name={"delivery_city"}
              rules={[{ required: true, message: "Required" }]}
            >
              <select className="form-field__input__2 form-field__sel bg-white">
                <option value={""}>Select City</option>
                {pickupCities?.map((city) => (
                  <option key={city?.name} value={city?.id}>
                    {city.name}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>

          <div className="w-full lg:my-0">
            <p className="form-label">Address</p>
            <Form.Item
              name="delivery_address"
              rules={[
                { required: true, message: "Required" },
                { min: 5, message: "Must be at least 5 characters" },
              ]}
            >
              <Input
                type="text"
                className="form-field__input__2"
                placeholder="57, Avenue Street"
              />
            </Form.Item>
          </div>

          <div className="w-full lg:my-0">
            <p className="form-label">Description</p>
            <Form.Item
              name="delivery_description"
              rules={[
                { required: true, message: "Required" },
                { min: 5, message: "Must be at least 5 characters" },
              ]}
            >
              <TextArea
                rows={3}
                className="form-field__input__2"
                placeholder="Description."
              />
            </Form.Item>
          </div>
        </div>

        <div className="flex justify-center my-1">
          <AgroButton
            loading={createAggregationLoading}
            disabled={createAggregationLoading}
            primary
            text={createAggregationLoading ? "Submitting" : "Submit"}
            type="submit"
            className={"px-16"}
          />
        </div>
      </Form>
    </div>
  );
};

export default CreateAggregation;
