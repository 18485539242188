import React from "react";
import { PaddedContainer } from "..";
import { serviceOfferings } from "../../utils/misc/homeData";

function ServiceOfferings() {
  return (
    <PaddedContainer>
      {/* <p className="my-5 tracking-wide text-center text-lg lg:text-2xl font-bold">
				Platform Services
			</p> */}

    {/*   <div className="my-6 mx-auto">
        <img
          src="/images/advertImgs/banner_ad.png"
          alt="advert"
          className="cursor-pointer"
        />
      </div>  */}
      <div className="px-2 md:px-0 mx-auto flex flex-wrap my-5 items-center justify-center lg:justify-between">
        {serviceOfferings.map(({ Icon, title, subtitle }) => (
          <div
            key={title}
            className="pl-10 flex text-center items-center p-3 border border-gray-300 w-full mb-3 h-[80px] md:w-2/4 lg:w-1/4"
          >
           
            <div>
              <p className=" text-black font-bold text-xs md:text-md tracking-wide font-medium text-[15px]">
                {title}
              </p>
              <p className="text-black">{subtitle}</p>
            </div>
            <Icon className="fill-[#3dd800] orng ml-8" />
          </div>
        ))}
      </div>
    </PaddedContainer>
  );
}

export default ServiceOfferings;
