import React, { useEffect, useState, useRef } from "react";
import { Form, Input } from "antd";
import MultiSelectedFormItems from "./MultiSelectedItems";
import AgroButton from "../../../../misc/AgroButton";
import useGeneric from "../../../../../utils/hooks/user/useGeneric";

const valuesInit = {
  item: ""
};

const FocusItem = React.forwardRef((props, {itemRef}) => {

  const { 
    handleCategoryChange, 
    handleSubCategoryChange, 
    handleItemChange, 
    form, 
    getFocusItems, 
    focus, 
    categoriesData,
    subcategoriesData,
    itemsData,
    retrieveFocusItems
} = props;

  const [values, setValues] = useState(valuesInit);
  const [visibility, setVisibility] = useState(true);
  const [focusItems, setFocusItems] = useState([]);
  const [detailedFocusItems, setDetailedFocusItems] =useState([]);

//   const {
//     categoriesData,
//     subcategoriesData,
//     itemsData,
//   } = useGeneric();
  

  const addFocusItem = () => {
   
    form
      .validateFields([
        "item"
      ])
      .then((vals) => {
        
        const data = focusItems
        
        if(data.includes(vals.item)){
            return;
        }else{
            data.push(vals.item);
            setFocusItems(data);

            const pickData = (currentData)=>{ 
              return data.includes(currentData.id.toString());
            }
            const detailedData = itemsData.filter(pickData);
            const oldDetailedFocusItem = [...detailedFocusItems]

            detailedData.forEach(function(item, index){
              if(!oldDetailedFocusItem.includes(item)){
                oldDetailedFocusItem.push(item);
              }
               
            })
           
            setDetailedFocusItems(oldDetailedFocusItem);
            retrieveFocusItems(data);
            reset();
        }
       
      })
      .catch((error) => {});
  };

  const reset = () => {
    form.setFieldsValue({
      item: ""
    });
    setValues({ ...values, ...valuesInit });
    setVisibility(false);
  };

  const remove = (index) => {
    focusItems.splice(index, 1);
    setFocusItems(focusItems.filter((_, i) => i !== index));
    retrieveFocusItems(focusItems.filter((_, i) => i !== index));
    setDetailedFocusItems(detailedFocusItems.filter((_, i) => i !== index))
  };

  useEffect(() => {
    getFocusItems(focusItems);
    // console.log("detailed: "+ detailedFocusItems)
    // getDetailedFocusItems(detailedFocusItems);
    // console.log("focus: "+ focusItems)
  }, [focusItems]);

  return (
    <>
     {visibility ? (
        <>
        <div className="w-full md:w-[29%] lg:my-0">
        <p className="form-label text-xs">Category</p>
        <Form.Item
            onChange={handleCategoryChange}
            name={"category"}
            rules={[{ required: true, message: "Required" }]}
        >
        <select className="form-field__input__2 form-field__sel bg-white">
          <option value={""}>Select Category</option>
          {categoriesData?.map((category) => (
            <option key={category?.id} value={category?.id}>
              {category.name}
            </option>
          ))}
        </select>
      </Form.Item>
    </div>

    <div className="w-full md:w-[29%] lg:my-0">
      <p className="form-label text-xs">Sub Category</p>
      <Form.Item
        onChange={handleSubCategoryChange}
        name={"sub_category"}
        rules={[{ required: true, message: "Required" }]}
      >
        <select className="form-field__input__2 form-field__sel bg-white">
          <option value={""}>Select Sub Category</option>
          {focus?.category ? (
            <>
              {subcategoriesData?.map((subcategory) => (
                <option key={subcategory?.name} value={subcategory?.id}>
                  {subcategory.name}
                </option>
              ))}
            </>
          ) : (
            <></>
          )}
        </select>
      </Form.Item>
    </div>

    <div className="w-full md:w-[29%] lg:my-0">
      <p className="form-label text-xs">Item</p>
      <Form.Item
        onChange={handleItemChange}
        name={"item"}
        rules={[{ required: true, message: "Required" }]}
      >
        <select className="form-field__input__2 form-field__sel bg-white" ref={itemRef}>
          <option value={""}>Select Item</option>
          {focus?.sub_category ? (
            <>
              {itemsData?.map((item) => (
                <option key={item?.name} value={item?.id}>
                  {item.name}
                </option>
              ))}
            </>
          ) : (
            <></>
          )}
        </select>
      </Form.Item>
    </div>
    </>
     )
    : (
        <div className="w-full mb-4">
          <AgroButton
            primary
            text={"Add New Focus Item"}
            type="button"
            onClick={() => setVisibility(true)}
            className={"text-[10px]"}
          />
        </div>
      )}

    <div className="w-full my-4 flex justify-end gap-x-6">
            {focusItems.length ? (
              <AgroButton
                className={
                  "bg-red-500 text-[#ffffff] hover:bg-red-800 text-[10px]"
                }
                text={"Close"}
                type="button"
                onClick={() => setVisibility(false)}
              />
            ) : (
              <></>
            )}
            <AgroButton
              primary
              text={"Save Focus Item"}
              type="button"
              onClick={addFocusItem}
              className={"text-[10px]"}
            />
          </div>

          {focusItems.length ? (
            <MultiSelectedFormItems
            items={detailedFocusItems}
            prop={"name"}
            remove={remove}
            />
        ) : (
            <></>
        )}
  </>
  );
});

export default FocusItem;
