import React from "react";
import { Form, Input, message } from "antd";
import { AgroButton } from "../../../..";
import useFarmerData from "../../../../../utils/hooks/user/useFarmerData";
import _ from "lodash";
const success = "Successful";
const error = "An error has occured";

const ContactPersonModal = (props) => {
  const { setVisible, addContactPerson, farmRecordId } = props;
  const titles = ["Mr", "Mrs", "Miss", "Ms", "Dr"];
  const [form] = Form.useForm();
  const { createContactPersonLoading, createContactPerson } = useFarmerData();

  const reset = () => {
    form.resetFields();
  };

  const onSubmitForm = (values) => {
    console.log(values);
    const contacts = [
      {
        email: values?.contact_email,
        first_name: values?.contact_first_name,
        last_name: values?.contact_last_name,
        other_name: values?.contact_other_name,
        phone: values?.contact_phone,
        title: values?.contact_title,
      },
    ];
    const data = {
      contacts: contacts,
    };
    console.log(data);
    const formData = new FormData();
    _.forEach(data, (value, key) => {
      if (typeof value === "object") {
        if (value?.length) {
          for (let i = 0; i < value.length; i++) {
            _.forEach(value[i], (item, prop) => {
              formData.append(`${key}[${i}][${prop}]`, item);
            });
          }
        }
      } else {
        formData.append(key, value);
      }
    });
    createContactPerson(formData, farmRecordId).then((data) => {
      if (data?.success) {
        console.log(data);
        message.success(data?.message || success);
        addContactPerson(data?.data?.farmer_data_capture_contact);
        reset();
      } else {
        message.error(data?.message || error);
      }
    });
  };

  return (
    <Form form={form} onFinish={onSubmitForm} autoComplete="on">
      <div className="w-full lg:my-0">
        <p className="form-label text-xs">Title</p>
        <Form.Item
          name={"contact_title"}
          rules={[{ required: true, message: "Required" }]}
        >
          <select className="form-field__input__2 bg-white form-field__sel">
            <option value={""}>Select Title</option>
            {titles?.map((item, index) => (
              <option key={index + item} value={item}>
                {item}
              </option>
            ))}
          </select>
        </Form.Item>
      </div>

      <div className="w-full lg:my-0">
        <p className="form-label text-xs">First Name</p>
        <Form.Item
          name={"contact_first_name"}
          rules={[
            { required: true, message: "Required" },
            { min: 3, message: "Must be at least 3 characters" },
          ]}
        >
          <Input className="form-field__input__2" placeholder="Ojo" />
        </Form.Item>
      </div>

      <div className="w-full lg:my-0">
        <p className="form-label text-xs">Last Name</p>
        <Form.Item
          name={"contact_last_name"}
          rules={[
            { required: true, message: "Required" },
            { min: 3, message: "Must be at least 3 characters" },
          ]}
        >
          <Input className="form-field__input__2" placeholder="Wale" />
        </Form.Item>
      </div>

      <div className="w-full lg:my-0">
        <p className="form-label text-xs">Other Name</p>
        <Form.Item
          name={"contact_other_name"}
          rules={[{ min: 3, message: "Must be at least 3 characters" }]}
        >
          <Input className="form-field__input__2" placeholder="Wale" />
        </Form.Item>
      </div>

      <div className="w-full lg:my-0">
        <p className="form-label text-xs">Email</p>
        <Form.Item
          name={"contact_email"}
          rules={[
            { required: true, message: "Required" },
            { type: "email", message: "Invalid Email" },
          ]}
        >
          <Input
            className="form-field__input__2"
            placeholder="matt@agroclerk.com"
          />
        </Form.Item>
      </div>

      <div className="w-full lg:my-0">
        <p className="form-label text-xs">Phone</p>
        <Form.Item

          name={"contact_phone"}
          rules={[
            { required: true, message: "Required" },
          ]}
        >
          <Input
              type="number"
               onInput={(e) => e.target.value = e.target.value.slice(0, 11)}
            className="form-field__input__2"
            placeholder="08012345678"
          />
        </Form.Item>
      </div>

      <div className="w-full flex gap-x-4 justify-end lg:my-0">
        <AgroButton
          text={"Cancel"}
          type="button"
          className={"px-6 text-[12px]"}
          onClick={() => setVisible(false)}
        />
        <AgroButton
          loading={createContactPersonLoading}
          disabled={createContactPersonLoading}
          primary
          text={
            createContactPersonLoading ? "Please wait..." : "Add Contact Person"
          }
          type="submit"
          className={"text-[12px]"}
        />
      </div>
    </Form>
  );
};

export default ContactPersonModal;
