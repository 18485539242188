import React from "react";
import Footer from "../footer/Footer";
import DarkNav from "../navigation/DarkNav";
import Navbar from "../navigation/Navbar";

function GenericLayout({ children, isProtected }) {
  return (
    <>
      <DarkNav />
      <Navbar />
      <section>{children}</section>
      <Footer />
    </>
  );
}

export default GenericLayout;
