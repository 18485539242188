import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { GrRefresh } from "react-icons/gr";
import Spinner from "../../components/misc/Spinner";
import GenericLayout from "../../containers/layouts/GenericLayout";
import axiosInstance from "../../utils/axios/axiosInterceptors";
import Adverts from "../../components/forHomepage/Adverts";
import ProductsSection from "../../components/forHomepage/ProductsSection";
import Testimonials from "../../components/forHomepage/Testimonials";
import ServiceOffers from "../../components/forHomepage/ServiceOffers";
import BlogSection from "../../components/forHomepage/blogSection/BlogSection";
import AgroButton from "../../components/misc/AgroButton";
import MediaServices from "../../components/forHomepage/MediaServices";
import NavBlogs from "../../components/forHomepage/NavBlogs";
import ServiceOfferings from "../../components/forHomepage/ServiceOfferings";

function Homepage() {
  const [postsLoading, setPostsLoading] = useState(true);
  const [latestPosts, setLatestPosts] = useState([]);
  const [postsError, setPostsError] = useState(null);

  const fetchPosts = async () => {
    setPostsLoading(true);
    try {
      const { data } = await axiosInstance.get(
        "generic/posts?include=comments"
      );
      // const { data } = await axiosInstance.get(
      //   "user/posts?include=categories, tags, comments, comments.replies, comments.likes, comments.likes.user"
      // );
      setLatestPosts(data?.data?.posts?.data);
      setPostsError(null);
      setPostsLoading(false);
      return data;
    } catch (error) {
      setPostsLoading(false);
      setPostsError("Error fetching latest posts.");
    }
  };

  console.log("latest",latestPosts)

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <GenericLayout>
      <NavBlogs />
      <section className="posts-wrapper bg-white flex flex-col items-center justify-center px-5">
        {postsLoading ? (
          <Spinner primary />
        ) : postsError ? (
          <div className="flex flex-col items-center justify-center">
            <p className="text-center text-green-600 text-lg px-4 mb-3">
              {postsError}
            </p>
            <GrRefresh
              className="text-green-600 cursor-pointer h-6 w-6"
              onClick={fetchPosts}
            />
          </div>
        ) : (
          <BlogSection latestPosts={latestPosts} />
        )}
        <div className="flex items-center px-[5%] md:px-0 w-full justify-between md:justify-around mb-2">
          <Link to={"/user/media-services/blog"}>
            <AgroButton text="Submit an Article" />
          </Link>

          <Link to={"/blog"}>
            <AgroButton text="Read all Articles" />
          </Link>
        </div>
      </section>
      <section className={"container"}>
        {/*<ServiceOffers />*/}
        <ServiceOfferings />

        <ProductsSection />
        <Div className="w-full">
          <div className="py-5 flex flex-col items-center justify-center">
            <p className="font-bold tracking-wide text-lg lg:text-2xl mb-2 text-center">
              Hundreds of Hand Tools
            </p>
            <p className="text-center mb-3 font-medium px-3">
              Hammers, Drills, Hacksaws, Jigsaws, Planes, Nippers, Pliers, etc.
            </p>
            <AgroButton primary text="Shop Now" className="mx-auto" />
          </div>
        </Div>
        <MediaServices />
        <Testimonials />
        <Adverts />
      </section>
    </GenericLayout>
  );
}

export default Homepage;

const Div = styled.div`
  height: auto;
  background: url("/images/tools-banner.jpg") center/cover no-repeat;
`;
