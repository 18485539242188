import React from "react";
import { GiCancel } from "react-icons/gi";

const MultiSelectedFormItems = ({
  items = [],
  prop,
  remove,
  select,
  disable,
}) => {
  return (
    <div className="w-full flex mb-2 border p-2 flex-wrap gap-2">
      {items.map((item, i) => (
        <div
          onClick={() => select(i)}
          key={item + i}
          className={
            "border border-gray-400 rounded bg-gray-200 px-1 flex gap-x-1 items-center cursor-pointer"
          }
        >
          <span
            onClick={() => {
              if (!disable) {
                remove(i);
              }
            }}
          >
            <GiCancel className="h-2 text-gray-500 hover:text-gray-900 cursor-pointer" />
          </span>
          {item[prop]}
        </div>
      ))}
    </div>
  );
};

export default MultiSelectedFormItems;
