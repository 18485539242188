const aggregationListColumns = [
  {
    title: "S/N",
    dataIndex: "sn",
    key: "sn",
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "Sub Category",
    dataIndex: "sub_category",
    key: "sub_category",
  },
  {
    title: "Item",
    dataIndex: "item",
    key: "item",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Supply Mode",
    dataIndex: "supply_mode",
    key: "supply_mode",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

export { aggregationListColumns };
