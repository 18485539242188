import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useGroupServices from "../../../utils/hooks/user/useGroupServices";
import AgroButton from "../../misc/AgroButton";
import { message } from "antd";
import ViewCooperative from "./components/Cooperative/ViewCooperative";
import { InlineError } from "../..";
import EditCooperative from "./components/Cooperative/EditCooperative";
const success = "Successful";
const error = "An error has occured";


function ManageCooperative() {
  const [cooperative, setCooperative] = useState(null);
  const [tab, setTab] = useState(0);
  const { search } = useLocation();
  const navigate = useNavigate();
  const id = new URLSearchParams(search).get("id");
  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
  }, []);
  const handleClick = (index) => {
    setTab(index);
  };
  const { cooperativeFailure, setCooperativeFailure, getCooperative } =
    useGroupServices();
  useEffect(() => {
    if (id) {
      getCooperative(id).then((data) => {
        if (data?.success) {
          setCooperative(data?.data?.cooperative);
        } else {
          message.error(data?.message || error);
        }
      });
    }
  }, []);
  return (
    <div>
      <InlineError
        control={cooperativeFailure}
        message={cooperativeFailure}
        onClose={() => setCooperativeFailure(null)}
      />
      <div className={"flex justify-between items-center mb-4"}>
        <h1>Manage</h1>
        <h1>{cooperative?.name}</h1>
      </div>
      {cooperative ? (
        <div className={"flex justify-end gap-x-3"}>
          <AgroButton
            onClick={() => handleClick(0)}
            primary={tab === 0}
            text="View"
            className={"text-[10px]"}
          />
          <AgroButton
            onClick={() => handleClick(1)}
            primary={tab === 1}
            text="Edit"
            className={"text-[10px]"}
          />
        </div>
      ) : (
        <></>
      )}
      {cooperative ? (
        <>
          {tab === 0 ? (
            <>
              <ViewCooperative cooperative={cooperative} />
            </>
          ) : (
            <EditCooperative cooperative={cooperative} />
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ManageCooperative;
