import React, {useState} from "react";
import { Form, Input, message } from "antd";
import _ from "lodash";
import { useEffect } from "react";
import InlineError from "../../../../misc/InlineError";
import useGroupServices from "../../../../../utils/hooks/user/useGroupServices";
import AgroButton from "../../../../misc/AgroButton";

const success = "Successful";
const error = "An error has occured";


const UpdateFarmerDueForm = React.forwardRef(({ cooperative_id, closeModal, modalFormData, member_id}, {modalRef, formSubmitRef}) => {
  
  const [dueData, setDueData]  = useState(modalFormData);

  const [form] = Form.useForm();
  const { attachDueToFarmerLoading, attachDueToFarmerFailure, setAttachDueToFarmerFailure, attachDueToFarmer } =
    useGroupServices();
  const onSubmit = (values) => {
    console.log(values);
    const data = {
      ...values
    };
    const formData = new FormData();
    formData.append("farmer_record_id", member_id)
    _.forEach(data, (value, key) => {
      if (typeof value === "object") {
        if (value?.length) {
          for (let i = 0; i < value.length; i++) {
            _.forEach(value[i], (item, prop) => {
              formData.append(`${key}[${i}][${prop}]`, item);
            });
          }
        }
      } else {
        formData.append(key, value);
      }
    });

   const dueId = formSubmitRef.current.id;

    attachDueToFarmer(cooperative_id, dueId, formData).then((data) => {
      if (data?.success) {
        message.success(data?.message || success);
        closeModal()
      } else {
        message.error(data?.message || error);
      }
    });
  };

 

  const modifyAmountDue =(e)=>{

    const newData = {
      ...dueData,
      amount_due:e.target.value
    }
    setDueData(newData)
  }

  const modifyAmountPaid =(e)=>{

    const newData = {
      ...dueData,
      amount_paid:e.target.value
    }
    setDueData(newData)
  }

  useEffect(()=>{
    closeModal();
  }, []);

  useEffect(()=>{
    setDueData(modalFormData)
    console.log(dueData)
  }, [modalFormData]);

  return (
    <>
      
      <InlineError
        control={attachDueToFarmerFailure}
        message={attachDueToFarmerFailure}
        onClose={() => setAttachDueToFarmerFailure(null)}
      />
      {/* console.log(dueData); */}
      <div style={{ width:"50%", position:"fixed", backgroundColor:"white", top:"52%", left:"50%", transform:"translate(-50%, -50%)", overflowY:"auto", boxShadow:"1px -1px 5px 3px #888888"}} className="p-5" id={`memberDue-1`} ref={modalRef}>
          <span 
          className="px-3 py-1" 
          style={{backgroundColor:"grey", color:"white", cursor:"pointer", marginLeft:"auto"}}
          onClick={closeModal}
          > Close</span>
          <Form form={form} onFinish={onSubmit} autoComplete="on" className="mt-5">
          <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
            <div className="w-full lg:my-0 ">
              <p className="form-label">Amount Due</p>
              <Form.Item
                name="amount_due"
                rules={[
                  { required: true, message: "Required" },
                  { min: 3, message: "Must be at least 3 characters" },
                ]}
                onChange={modifyAmountDue}
                initialValue={dueData?.amount_due}
              >
                <Input
                  className="form-field__input__2"
                  placeholder="Enter Amount"
                />
              </Form.Item>
            </div>
          </div>

          <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
            <div className="w-full lg:my-0 ">
              <p className="form-label">Amount Paid</p>
              <Form.Item
                name={"amount_paid"}
                rules={[{ required: true, message: "Required" }]}
                onChange={modifyAmountPaid}
                initialValue={dueData.amount_paid}
           
              >
                <Input
                  className="form-field__input__2"
                  placeholder="Enter Amount"
                />
              </Form.Item>
            </div>
          </div>

          <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
            <div className="w-full lg:my-0 ">
              <p className="form-label">Date</p>
              <Form.Item
                name={"date"}
                rules={[{ required: true, message: "Required" }]}
                initialValue={dueData?.date}
              >
                <Input
                  className="form-field__input__2"
                  placeholder="Enter Amount"
                  type="date"
                />
              </Form.Item>
            </div>
          </div>

          <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
            <div className="w-full lg:my-0 ">
              <p className="form-label">Balance</p>
              <Form.Item
                name={"amount_balance"}
                rules={[{ required: true, message: "Required" }]}
                initialValue={parseFloat(dueData?.amount_due) - parseFloat(dueData?.amount_paid)}
              >
                <Input
                  className="form-field__input__2"
                  placeholder="Enter Balance"
                />
              </Form.Item>
            </div>
          </div>

          
          <div className="flex justify-center mt-4 mb-1 gap-x-4">
            <AgroButton
              loading={attachDueToFarmerLoading}
              disabled={attachDueToFarmerLoading}
              primary
              text={attachDueToFarmerLoading ? "Please wait..." : "Update Due"}
              type="submit"
              className={"px-10"}
              ref={formSubmitRef}
            />
          </div>
        </Form>
      </div>
     
    </>
  );
});

export default UpdateFarmerDueForm;
