import { Card, Col, Row } from 'antd'
import React from 'react'

const Dashboard = () => {
  return (
    <div>
    <div  className="site-card-wrapper mt-8">
<Row gutter={12}>
<Col span={6}>
<Card 
headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
title="Aggregation" bordered={false}>
3000
</Card>
</Col>
<Col span={6}>
<Card 
headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
title="Offtake" bordered={false}>
150
</Card>
</Col>
<Col span={6}>
<Card 
headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
title="Anchor Borrower" bordered={false}>
30
</Card>
</Col>
<Col span={6}>
<Card 
headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
title="Programmes" bordered={false}>
30
</Card>
</Col>
</Row>
</div>
</div>

  )
}

export default Dashboard