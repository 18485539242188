import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useGroupServices from "../../../utils/hooks/user/useGroupServices";
import AgroButton from "../../misc/AgroButton";
import ListAssociations from "./components/Associations/ListAssociations";
import AddAssociation from "./components/Associations/AddAssociation";

function Association() {
  const [tab, setTab] = useState(0);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    size: 10,
  });
  const { associationsLoading, getAssociations } = useGroupServices();
  const { associations } = useSelector((state) => state.global);

  const handleClick = (index) => {
    if (tab === 1)
      setPaginationData({
        page: 1,
        size: 10,
      });
    setTab(index);
  };

  useEffect(() => {
    getAssociations(paginationData);
  }, [paginationData]);

  const handleCompleted = () => {
    setTab(0);
    setPaginationData({
      page: 1,
      size: 10,
    });
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current,
      size: pageSize,
    });
  };
  return (
    <div>
      <div className={"flex justify-end gap-x-3"}>
        <AgroButton
          onClick={() => handleClick(0)}
          primary={tab === 0}
          text="List Associations"
          className={"text-[12px]"}
        />
        <AgroButton
          onClick={() => handleClick(1)}
          primary={tab === 1}
          text="Add Association"
          className={"text-[12px]"}
        />
      </div>
      {tab === 0 ? (
        <>
          <ListAssociations
            associations={associations}
            paginationChangeHandler={paginationChangeHandler}
            loading={associationsLoading}
          />
        </>
      ) : (
        <AddAssociation completed={handleCompleted} />
      )}
    </div>
  );
}

export default Association;
