import React, { useEffect, useState, useRef } from "react";
import { Form, Input, message } from "antd";
import ContactPerson from "./ContactPerson";
import _ from "lodash";
import useGeneric from "../../../../../utils/hooks/user/useGeneric";
import useCountriesStates from "../../../../../utils/hooks/auth/useCountriesStates";
import useGroupServices from "../../../../../utils/hooks/user/useGroupServices";
import InlineError from "../../../../misc/InlineError";
import AgroButton from "../../../../misc/AgroButton";
import FocusItem from './FocusItem';

const success = "Successful";
const error = "An error has occured";

const AddAssociation = ({ completed }) => {
  const [form] = Form.useForm();
  const [focus, setFocus] = useState({});
  const [location, setLocation] = useState({});
  const [contactPersons, setContactPersons] = useState([]);
  const [focusItems, setFocusItems] = useState([]);

  const itemRef = useRef();
  
  const {
    categoriesData,
    getCategories,
    subcategoriesData,
    setSubcategoriesData,
    getSubcategories,
    itemsData,
    setItemsData,
    getItems,
  } = useGeneric();
  const {
    countriesData,
    getCountries,
    statesData,
    setStatesData,
    getStates,
    citiesData,
    setCitiesData,
    getCities,
  } = useCountriesStates();
  const {
    createAssociationLoading,
    createAssociationFailure,
    setCreateAssociationFailure,
    createAssociation,
  } = useGroupServices();

  const handleCategoryChange = (e) => {
    const id = e.target.value;
    setFocus({ ...focus, category: id });
    setSubcategoriesData([]);
    // setItemsData([]);
    getSubcategories(id);
  };

  const handleSubCategoryChange = (e) => {
    const id = e.target.value;
    setFocus({ ...focus, sub_category: id });
    getItems(id);
  };

  const handleItemChange = (e) => {
    const id = e.target.value;
    console.log(id)
    setFocus({
      ...focus,
      item: id,
    });
  };

  const handleCountryChange = (e) => {
    const id = e.target.value;
    setLocation({ ...location, country: id });
    setStatesData([]);
    setCitiesData([]);
    getStates(id);
  };

  const handleStateChange = (e) => {
    const id = e.target.value;
    setLocation({ ...location, state: id });
    setCitiesData([]);
    getCities(id);
  };

  const handleCityChange = (e) => {
    const id = e.target.value;
    setLocation({
      ...location,
      city: id,
    });
  };

  const retrieveFocusItems = (items)=>{
    setFocusItems(items);
  }

  useEffect(() => {
    getCountries();
    getCategories();
  
  }, []);

  
  const onSubmit = (values) => {
    if(focusItems.length == 0){
      alert("add at least one focus Item");
      return;
      // itemRef.current.style.borderColor="red";
    }
    const contacts = values?.contact_email
      ? [
          ...contactPersons,
          {
            email: values?.contact_email,
            first_name: values?.contact_first_name,
            surname: values?.contact_last_name,
            // other_name:
            //   values?.contact_other_name !== "undefined" ||
            //   values?.contact_other_name !== undefined
            //     ? values?.contact_other_name
            //     : "",
            phone: values?.contact_phone,
            title: values?.contact_title,
            role: values?.contact_role,
          },
        ]
      : contactPersons;

    const data = {
      name: values?.name ?? "",
      website: values?.website ?? "",
      phone: values?.phone_number ?? "",
      email: values?.email ?? "",
      focus_items: focusItems,
      contacts: contacts,
      country: values?.country ?? "",
      state: values?.state ?? "",
      city: values?.city ?? "",
      address: values?.address ?? "",
    };
    const formData = new FormData();
    _.forEach(data, (value, key) => {
      if (typeof value === "object") {
        if (value?.length) {
          for (let i = 0; i < value.length; i++) {
            _.forEach(value[i], (item, prop) => {
              if (item && key !== "focus_items") {
                formData.append(`${key}[${i}][${prop}]`, item);
              }
              if (item && key === "focus_items") {
                formData.append(`${key}[]`, item);
              }
            });
          }
        }
      } else {
        formData.append(key, value);
      }
    });

    createAssociation(formData).then((data) => {
      if (data?.success) {
        message.success(data?.message || success);
        return;
        completed();
      } else {
        message.error(data?.message || error);
      }
    });
  };

  return (
    <>
      <InlineError
        control={createAssociationFailure}
        message={createAssociationFailure}
        onClose={() => setCreateAssociationFailure(null)}
      />
      <Form form={form} onFinish={onSubmit} autoComplete="on" className="mt-5">
        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full md:w-[47%] lg:my-0 ">
            <p className="form-label">Name</p>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "Required" },
                { min: 3, message: "Must be at least 3 characters" },
              ]}
            >
              <Input
                className="form-field__input__2"
                placeholder="Mowe Egg Cooperative"
              />
            </Form.Item>
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full">
          <div className="w-full mb-2 flex justify-between items-center">
            <p className="form-label">Focus</p>
          </div>
          <FocusItem 
            handleCategoryChange={handleCategoryChange} 
            handleSubCategoryChange={handleSubCategoryChange} 
            handleItemChange={handleItemChange} 
            form={form} 
            focus={focus} 
            categoriesData={categoriesData} 
            subcategoriesData={subcategoriesData} 
            itemsData={itemsData} 
            retrieveFocusItems={retrieveFocusItems} 
            getFocusItems={(focusItems) => setFocusItems(focusItems)}
            ref={{ itemRef:itemRef }}
          />
        
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full">
          <div className="w-full mb-2 flex justify-between items-center">
            <p className="form-label">Location</p>
          </div>
          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">Country</p>
            <Form.Item
              onChange={handleCountryChange}
              name={"country"}
              rules={[{ required: true, message: "Required" }]}
            >
              <select className="form-field__input__2 bg-white form-field__sel">
                <option value={""}>Select Country</option>
                {countriesData?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item?.name?.length > 20
                      ? `${item.name.substring(0, 30)}...`
                      : item.name}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">State</p>
            <Form.Item
              onChange={handleStateChange}
              name={"state"}
              rules={[{ required: true, message: "Required" }]}
            >
              <select className="form-field__input__2 bg-white form-field__sel">
                <option value={""}>Select State</option>
                {statesData?.map((state) => (
                  <option key={state?.name} value={state?.id}>
                    {state.name}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">City</p>
            <Form.Item
              onChange={handleCityChange}
              name={"city"}
              rules={[{ required: true, message: "Required" }]}
            >
              <select className="form-field__input__2 form-field__sel bg-white">
                <option value={""}>Select City</option>
                {citiesData?.map((city) => (
                  <option key={city?.name} value={city?.id}>
                    {city.name}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>

          <div className="w-full lg:my-0">
            <p className="form-label text-xs">Address</p>
            <Form.Item
              name="address"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input className="form-field__input__2" placeholder="Address" />
            </Form.Item>
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full lg:my-0">
            <p className="form-label">Website</p>
            <Form.Item
              name="website"
              initialValue="https://"
            //  rules={[{ min: 10, message: "Must be at least 10 characters" }]}
            >
              <Input
                className="form-field__input__2"
                placeholder="http://facebook.com"
              />
            </Form.Item>
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full md:w-[47%] lg:my-0">
            <p className="form-label">Phone Number</p>
            <Form.Item
              name="phone_number"
              rules={[
                { required: true, message: "Required" },
                { min: 11, message: "Must be at least 11 characters" },
              ]}
            >
              <Input
                 type="number"
                 onInput={(e) => e.target.value = e.target.value.slice(0, 11)} // js validation to check that number does not exceed 11 characters   
                className="form-field__input__2"
                placeholder="08012345678"
              />
            </Form.Item>
          </div>

          <div className="w-full md:w-[47%] lg:my-0">
            <p className="form-label">Email Address</p>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Required" },
                { type: "email", message: "Invalid Email" },
              ]}
            >
              <Input
                className="form-field__input__2"
                placeholder="matt@agroclerk.com"
              />
            </Form.Item>
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full">
          <div className="w-full mb-2 flex justify-between items-center">
            <p className="form-label">Contact Person</p>
          </div>
          <ContactPerson
            form={form}
            getContactPersons={(contactPersons) =>
              setContactPersons(contactPersons)
            }
          />
        </div>

        <div className="flex justify-center mt-4 mb-1 gap-x-4">
          <AgroButton
            loading={createAssociationLoading}
            disabled={createAssociationLoading}
            primary
            text={
              createAssociationLoading ? "Please wait..." : "Create Association"
            }
            type="submit"
            className={"px-10"}
          />
        </div>
      </Form>
    </>
  );
};

export default AddAssociation;
