import React from "react";
import GenericLayout from "../../containers/layouts/GenericLayout";

function Events() {
	return (
		<GenericLayout>
			<p>Here's the Events Page</p>
		</GenericLayout>
	);
}

export default Events;
