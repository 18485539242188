import React from "react";

function SolidButton({ text, className, Icon, disabled, type, onClick }) {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={`text-[10px] transition-all hover:bg-[#717a82] tracking-wider rounded-md flex items-center text-black hover:text-white py-[2px] px-3 border border-gray-400 bg-transparent ${className}`}
    >
      {Icon && <span className="text-sm mr-2">{Icon}</span>}
      {text}
    </button>
  );
}

export default SolidButton;
