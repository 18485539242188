import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import useFarmerData from '../../../../utils/hooks/user/useFarmerData';
import InlineError from '../../../misc/InlineError';
const success = "Successful";
const error = "An error has occured";



const UploadFarmer = () => {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [disable, setDisable] = useState(true);

    const { uploadFarmer,uploadFarmerLoading, uploadFarmerFailure, setUploadFarmerFailure, setUploadFarmerLoading } = useFarmerData();
    const handleUpload = () => {
      const formData = new FormData();
      fileList.forEach((file) => {
        formData.append('file', fileList[0]);
      });
     
      
    uploadFarmer(formData).then((data) => {
        if (data?.success) {
          message.success(data?.message || success);
          setFileList([]);
          
        } else {
          message.error(data?.message || error);
        }
      });

    };

    const props = {
        onRemove: (file) => {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
        },
        beforeUpload: (file) => {
          setFileList([...fileList, file]);
          return false;
        },
        fileList,
      };

      const closeErrors = () => {
     setUploadFarmerFailure(null);
      };
  return (
  <div>  <InlineError
  control={
  uploadFarmerFailure
  }
  message={
 "Your bulk farmer data upload failed, please try again or contact support"
  }
  onClose={() => closeErrors()}
/>
    <div className='grid place-items-center'>
        <div className='pt-12'>
      <Upload {...props}>
        <Button  size='large'  icon={<UploadOutlined />}>Select File</Button>
      </Upload>
      <Button
        size='large'
        type="primary"
        onClick={handleUpload}
        loading={uploadFarmerLoading}
        disabled= {fileList.length === 0}
        style={{
          marginTop: 16,
          backgroundColor: "#28812c",
          color: "#ffff",
          borderColor: "#28812c",
        }}
      >
        {uploading ? 'Uploading' : 'Start Upload'}
      </Button>
      <p className='text-sm mt-4'> Acceptable file format: .csv</p>
      </div>
    </div>
    </div>
);
  
}

export default UploadFarmer;