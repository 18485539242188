import { Form, Input } from "antd";
import { Link } from "react-router-dom";
import _ from "lodash";
import useLogin from "../../utils/hooks/auth/useLogin";
import { AgroButton, InlineError } from "../../components";
import AuthLayout from "../../containers/layouts/AuthLayout";

function SignIn() {
	const { loginLoading, loginFailure, setLoginFailure, loginUser } = useLogin();

	const onSubmit = (values) => {
		const formData = new FormData();
		_.forEach(values, (value, key) => {
			formData.append(key, value);
		});
		loginUser(formData);
	};

	return (
		<AuthLayout>
			<p className="mb-4 font-semibold text-lg lg:text-xl text-center">
				Login to your account
			</p>
			<InlineError
				control={loginFailure}
				message={loginFailure}
				onClose={() => setLoginFailure(null)}
			/>
			<Form onFinish={onSubmit} autoComplete="on" className="mt-5">
				<div className="mb-2">
					<p className="form-label">Email Address</p>
					<Form.Item
						name="email"
						rules={[
							{ required: true, message: "Required" },
							{ type: "email", message: "Invalid Email" },
						]}>
						<Input
							className="form-field__input bg-transparent"
							placeholder="Enter email address"
						/>
					</Form.Item>
				</div>

				<div className="mb-2">
					<p className="form-label">Password</p>
					<Form.Item
						name="password"
						rules={[{ required: true, message: "Required" }]}>
						<Input.Password
							className="form-field__input"
							placeholder="Enter your password"
						/>
					</Form.Item>
				</div>
				<div className="flex justify-center my-3">
					<AgroButton
						disabled={loginLoading}
						loading={loginLoading}
						primary
						text={loginLoading ? "Submitting" : "Login"}
						type="submit"
					/>
				</div>
			</Form>

			<div className="flex items-center justify-between">
				<div className="">
					<p className="text-sm">
						<Link
							className="text-orange-400 hover:underline hover:text-orange-400 ml-1.5  font-semibold tracking-wide"
							to="/auth/signup">
							Create Account
						</Link>
					</p>
				</div>
				<div className="">
					{" "}
					<p className="whitespace-nowrap text-sm">
						<Link
							className="text-orange-400 hover:underline font-semibold tracking-wide hover:text-orange-400 ml-2"
							to="/auth/signup">
							Recover Password
						</Link>
					</p>
				</div>
			</div>
		</AuthLayout>
	);
}

export default SignIn;
