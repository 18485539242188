import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

function Spinner({ primary, hasText }) {
	const spinIcon = (
		<LoadingOutlined
			style={{ fontSize: 45, color: primary ? "#009900" : "#fff" }}
			spin
		/>
	);

	return (
		<div className="text-center h-full py-3">
			<Spin indicator={spinIcon} />
			{hasText && <p className="pry-clr tracking-wide mt-3">Please Wait...</p>}
		</div>
	);
}

export default Spinner;
