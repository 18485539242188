import React from "react";
import { IoMdClose } from "react-icons/io";

function InlineError({ control, onClose, message }) {
	return control ? (
		<div className="flex items-center justify-between mx-auto transition-all w-full rounded-xs bg-red-100 border border-red-400 p-2">
			<p className="mb-0 text-red-500 text-sm text-center">
				{message || "An Error Occurred"}
			</p>
			<IoMdClose
				className="text-red-700 text-lg cursor-pointer h-5 w-5 transform hover:scale-110"
				onClick={onClose}
			/>
		</div>
	) : (
		""
	);
}

export default InlineError;
