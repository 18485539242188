import React, { useEffect, useState } from "react";
import { Form, message } from "antd";
import { FiEdit } from "react-icons/fi";
import { MdOutlineCancel } from "react-icons/md";
import { AgroButton } from "../../../..";
import _ from "lodash";
import useFarmerData from "../../../../../utils/hooks/user/useFarmerData";
import BankDetailsInfo from "../BankDetailsInfo";
const success = "Successful";
const error = "An error has occured";

const EditBankDetailsInfo = ({ farmerData }) => {
  const [disable, setDisable] = useState(true);
  const [form] = Form.useForm();
  const { createBankDetailsLoading, createBankDetails, updateBankDetails, updateBankLoading } = useFarmerData();


  useEffect(() => {
    console.log(farmerData?.bank_details[0]);
    form.setFieldsValue({
      bank: farmerData?.bank_details[0]?.bank_name ?? "",
      account_name: farmerData?.bank_details[0]?.bank_account_name ?? "",
      account_number:
        farmerData?.bank_details[0]?.bank_account_number?.toString() ?? "",
      bvn: farmerData?.bank_details[0]?.bank_bvn?.toString() ?? "",
    });
  }, [farmerData]);

  const onSubmitForm = (values) => {
    const data = {
      bank_name: values?.bank ?? "",
      bank_account_name: values?.account_name ?? "",
      bank_account_number: values?.account_number ?? "",
      bank_bvn: values?.bvn ?? "",
      _method: "PUT"
    };
    const formData = new FormData();
    _.forEach(data, (value, key) => {
      if (typeof value === "object") {
        if (value?.length) {
          for (let i = 0; i < value.length; i++) {
            _.forEach(value[i], (item, prop) => {
              formData.append(`${key}[${i}][${prop}]`, item);
            });
          }
        }
      } else {
        formData.append(key, value);
      }
    });

/*     createBankDetails(formData, farmerData?.id).then((data) => {
      if (data?.success) {
        message.success(data?.message || success);
        setDisable((prev) => ({ ...prev, form_4: true }));
      } else {
        message.error(data?.message || error);
      }
    }); */

    updateBankDetails(formData, farmerData?.id,farmerData?.bank_details[0].id).then((data) => {
      if (data?.success) {
        message.success(data?.message || success);
        setDisable((prev) => ({ ...prev, form_4: true }));
      } else {
        message.error(data?.message || error);
      }
    });

  };
  return (
    <div className={"mt-4"}>
      <div className={"border rounded-lg"}>
        <div
          className={
            "w-full bg-gray-300 py-2 px-4  flex items-center justify-between"
          }
        >
          <div className={"font-bold"}>Bank Details</div>
          <div onClick={() => setDisable(!disable)}>
            <a className={"flex gap-x-2 items-center"}>
              {disable ? (
                <>
                  <span className={"font-bold"}>
                    <FiEdit />
                  </span>
                  <span className={"font-bold"}>Edit</span>
                </>
              ) : (
                <>
                  <span className={"font-bold text-red-500"}>
                    <MdOutlineCancel />
                  </span>
                  <span className={"font-bold text-red-500"}>Cancel</span>
                </>
              )}
            </a>
          </div>
        </div>
        <Form
          form={form}
          onFinish={onSubmitForm}
          autoComplete="on"
          className={"w-full px-4 mt-4"}
        >
          <BankDetailsInfo disable={disable} />

          {!disable ? (
            <div className="flex justify-end my-4 gap-x-4">
              <AgroButton
                loading={updateBankLoading}
                disabled={updateBankLoading}
                primary
                text={updateBankLoading ? "Please wait..." : "Update"}
                type="submit"
                className={"px-10"}
              />
            </div>
          ) : (
            <></>
          )}
        </Form>
      </div>
    </div>
  );
};

export default EditBankDetailsInfo;
