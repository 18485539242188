import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { message } from "antd";
import ListDues from "./components/Cooperative/ListDues";
import AddDues from "./components/Cooperative/AddDues";
import { useSelector } from "react-redux";
import useGroupServices from "../../../utils/hooks/user/useGroupServices";
import InlineError from "../../misc/InlineError";
import AgroButton from "../../misc/AgroButton";
const success = "Successful";
const error = "An error has occured";

const CooperativeDues = () => {
  const [cooperative, setCooperative] = useState(null);
  const [tab, setTab] = useState(0);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    size: 10,
  });
  const { search } = useLocation();
  const navigate = useNavigate();
  const id = new URLSearchParams(search).get("id");
  useEffect(() => {
    if (!id) {
      navigate(-1);
    }
  }, []);
  const {
    getCooperative,
    cooperativeDuesLoading,
    cooperativeDuesFailure,
    setCooperativeDuesFailure,
    getCooperativeDues,
  } = useGroupServices();
  const { cooperativeDues } = useSelector((state) => state.global);

  useEffect(() => {
    if (id) {
      getCooperative(id).then((data) => {
        if (data?.success) {
          setCooperative(data?.data?.cooperative);
        } else {
          message.error(data?.message || error);
        }
      });
    }
  }, []);

  const handleClick = (index) => {
    if (tab === 1)
      setPaginationData({
        page: 1,
        size: 10,
      });
    setTab(index);
  };

  useEffect(() => {
    getCooperativeDues({ id, ...paginationData });
  }, [paginationData]);

  const handleCompleted = () => {
    setTab(0);
    setPaginationData({
      page: 1,
      size: 10,
    });
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current,
      size: pageSize,
    });
  };
  return (
    <div>
      <InlineError
        control={cooperativeDuesFailure}
        message={cooperativeDuesFailure}
        onClose={() => setCooperativeDuesFailure(null)}
      />
      <div className={"flex justify-between items-center mb-4"}>
        <h1>Dues</h1>
        <h1>{cooperative?.name}</h1>
      </div>
      {cooperative ? (
        <div className={"flex justify-end gap-x-3"}>
          <AgroButton
            onClick={() => handleClick(0)}
            primary={tab === 0}
            text="List Dues"
            className={"text-[10px]"}
          />
          <AgroButton
            onClick={() => handleClick(1)}
            primary={tab === 1}
            text="Add Dues"
            className={"text-[10px]"}
          />
        </div>
      ) : (
        <></>
      )}
      {cooperative ? (
        <>
          {tab === 0 ? (
            <>
              <ListDues
                dues={cooperativeDues}
                paginationChangeHandler={paginationChangeHandler}
                loading={cooperativeDuesLoading}
                cooperative={cooperative}
                completed={handleCompleted}
              />
            </>
          ) : (
            <AddDues cooperative={cooperative} completed={handleCompleted} />
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CooperativeDues;
