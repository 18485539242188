import React from "react";
import GenericLayout from "../../containers/layouts/GenericLayout";



function About() {
	return (
		<GenericLayout>
			 <section
      id="about"
      className="bg-[#f3f4fe] pt-20 pb-20 lg:pt-[120px] lg:pb-[120px]"
    >
      <div className="container">
        <div className="wow fadeInUp bg-white" data-wow-delay=".2s">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div
                className="items-center justify-between overflow-hidden border lg:flex"
              >
                <div
                  className="w-full py-12 px-7 sm:px-12 md:p-16 lg:max-w-[100%] lg:py-9 lg:px-16 xl:max-w-[100%] xl:p-[70px]"
                >
                  <span
                    className="mb-5 inline-block bg-primary py-2 px-5 text-sm font-medium text-green-600"
                  >
                    About Us
                  </span>
                  <h2
                    className="mb-6 text-2xl font-bold  sm:text-2xl sm:leading-snug 2xl:text-[30px]"
                  >
                    Digitizing the agricultural value chain.
                  </h2>
                  <p className="mb-9 text-base leading-relaxed text-body-color">
			Transforming Africa’s agriculture landscape involves fixing the agricultural value chains so that
			various stakeholders can engage with confidence across commercially healthy agricultural value
			chains. A first step to achieve this is to create a controlled environment for players in which the
			agricultural value chain is well structured. For this to be successfully achieved, it is imperative
			that technology is leveraged upon to enhance rapid information dissemination and service
			delivery within the agricultural sector.
                  </p>
                  <p className="mb-9 text-base leading-relaxed text-body-color">
		The African agricultural landscape is largely unstructured characterized by a disconnected value
		chain. The value chain is further plagued with poor infrastructure, inadequate access to credit,
		and low level of leverage in information technology.AgroClerk is focused on fixing some identified gaps in the agricultural value chain by leveraging
			on technology
				</p>
			
				  <p className="mb-9 text-base leading-relaxed text-body-color">
				The platform offers various benefits including but not limited to technical assistance, access to
			loans and credit, indexed directory services, rich agricultural blog, micro-ecommerce website,
			marketplace, aggregation, cooperative services, evacuation services, records management,
			crowdfunding etc.
                  </p>
			
				  <p className="mb-9 text-base leading-relaxed text-body-color">
			AgroClerk is an electronic agricultural platform that optimizes interaction between various
		aspects of the value chain and also automates activities within each value chain. The platform is
		available both on web and mobile channels.  The platform provide the following core services;
                  </p>
			
 <ul className=" mb-9 text-base leading-relaxed text-body-color list-disc">
	<li>Data Management Services</li>
	<li> Media Services
		 <ul className=" ml-6">
		<li>Targeted Adverts</li>
		<li> Blogs and Forum</li>
		</ul>
		</li>

		<li> Field Services
		<ul className=" ml-6">
		<li>Aggregation</li>
		<li>  Offtakers</li>
		<li>Anchor Borrowers</li>
		<li>Programs</li>
		</ul>
		
		</li>
	
		<li> Group Services
		<ul className="ml-6">
		<li>Associations</li>
		<li>Cooperatives</li>
		<li>Events</li>
		<li>Tenders</li>
		<li>Messaging</li>
		</ul>
		</li>
		<li>Trading</li>
		<li>Records Management</li>
		<li>Financial Services</li>
	</ul> 



	<p className="mb-9 text-base leading-relaxed text-body-color">
	Agroclerks targets the following players in the agribusiness space;
	</p>
<ul className="list-disc text-base ">
	<li> Agriculture Practitioners
	<ul className=" ml-6">
		<li > Input Suppliers</li>
		<li>  Producers</li>
		<li>Anchor Borrowers</li>
		<li> Processors</li>
		<li> Traders</li>
		<li>  Storage Providers</li>
		<li>etc</li>
		</ul>
		
		</li>
		<li>Government Institutions</li>
		<li>Financial Organization</li>
		<li>Agriculture Consultants</li>
		<li>Associations and Cooperatives</li>

</ul>
            
                </div>
           
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
		</GenericLayout>
	);
}

export default About;
