import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SignIn from "../../pages/auth/SignIn";
import Footer from "../footer/Footer";
import UserNav from "../navigation/user/UserNav";

function AuthLayout({ children }) {
	return (
		<section className="min-h-screen flex flex-col items-center justify-center bg-gray-300 px-4">
			<div className="mx-auto p-3">
				<Link to="/">
					<img
						src="/images/agro-logo.png"
						alt="logo"
						className="object-cover h-[60px]"
					/>
				</Link>
			</div>
			<div className="mx-auto w-full md:w-3/4 lg:w-2/5 bg-white shadow-lg rounded-md p-3 md:p-6 mb-8">
				{children}
			</div>
		</section>
	);
}

export default AuthLayout;

const Div = styled.div`
	min-height: calc(100vh - 64px);
`;
