import React, {useState} from "react";
import { Form, Input, message } from "antd";
import _ from "lodash";
import { useEffect } from "react";
import InlineError from "../../../../misc/InlineError";
import useGroupServices from "../../../../../utils/hooks/user/useGroupServices";
import AgroButton from "../../../../misc/AgroButton";
const success = "Successful";
const error = "An error has occured";
const types = [
  { name: "Recurring", id: "recurring" },
  { name: "One-Off", id: "one-off" },
];
const recurrents = [
  { name: "Yearly", id: "yearly" },
  { name: "Monthly", id: "monthly" },
  { name: "Weekly", id: "weekly" },
];
const { TextArea } = Input;



const EditDues = ({ cooperative, completed, due, setVisible }) => {
  const [recurrent, setRecurrent] = useState("");
  const [form] = Form.useForm();
  const { updateDueLoading, updateDueFailure, setUpdateDueFailure, updateDue } =
    useGroupServices();
  const onSubmit = (values) => {
    const data = {
      ...values,
      _method: "PUT",
    };
    const formData = new FormData();
    _.forEach(data, (value, key) => {
      if (typeof value === "object") {
        if (value?.length) {
          for (let i = 0; i < value.length; i++) {
            _.forEach(value[i], (item, prop) => {
              formData.append(`${key}[${i}][${prop}]`, item);
            });
          }
        }
      } else {
        formData.append(key, value);
      }
    });

    updateDue(cooperative?.id, due?.id, formData).then((data) => {
      if (data?.success) {
        message.success(data?.message || success);
        setVisible(false);
        completed();
      } else {
        message.error(data?.message || error);
      }
    });
  };

  useEffect(() => {
    if (due) {
      form.setFieldsValue({
        name: due?.name,
        type: due?.type,
        frequency: due?.frequency,
        amount: parseInt(due?.amount),
        description: due?.description,
      });
    }
    
  }, [due]);

  const handleTypeChange = (e) => {
    const _dueType = e.target.value;
    console.log(e.target.value)
    setRecurrent(_dueType);
  };

  return (
    <>
      <InlineError
        control={updateDueFailure}
        message={updateDueFailure}
        onClose={() => setUpdateDueFailure(null)}
      />
      <Form form={form} onFinish={onSubmit} autoComplete="on" className="mt-5">
        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full lg:my-0 ">
            <p className="form-label">Name</p>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: "Required" },
                { min: 3, message: "Must be at least 3 characters" },
              ]}
            >
              <Input
                className="form-field__input__2"
                placeholder="Enter Name"
              />
            </Form.Item>
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full lg:my-0 ">
            <p className="form-label">Type</p>
            <Form.Item
              name={"type"}
              rules={[{ required: true, message: "Required" }]}
              onChange={handleTypeChange}
            >
              <select className="form-field__input__2 form-field__sel bg-white">
                <option value={""}>Select Type</option>
                {types?.map((t) => (
                  <option key={t.name} value={t.id}>
                    {t.name}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>
        </div>

        {recurrent === "recurring" ?
       (     <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
       <div className="w-full lg:my-0 ">
            <p className="form-label">Recurrent</p>
            <Form.Item
              name={"frequency"}
              rules={[{ required: true, message: "Required" }]}
            >
              <select className="form-field__input__2 form-field__sel bg-white">
                <option value={""}>Select Recurrent</option>
                {recurrents?.map((r) => (
                  <option key={r.name} value={r.id}>
                    {r.name}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>
        </div>
        ): recurrent === "one-off" ? (<div></div>
        ) :  (
          <div></div>
        )
          }

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full lg:my-0 ">
            <p className="form-label">Amount</p>
            <Form.Item
              name="amount"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input
                type="number"
                className="form-field__input__2"
                placeholder="2000"
              />
            </Form.Item>
          </div>
        </div>

        <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
          <div className="w-full lg:my-0 ">
            <p className="form-label">Description</p>
            <Form.Item
              name="description"
              rules={[
                { required: true, message: "Required" },
                { min: 3, message: "Must be at least 3 characters" },
              ]}
            >
              <TextArea
                rows={3}
                className="form-field__input__2"
                placeholder="Description."
                minLength={3}
                
              />
            </Form.Item>
          </div>
        </div>
        <div className="flex justify-center mt-4 mb-1 gap-x-4">
          <AgroButton
            loading={updateDueLoading}
            disabled={updateDueLoading}
            primary
            text={updateDueLoading ? "Please wait..." : "Update Due"}
            type="submit"
            className={"px-10"}
          />
        </div>
      </Form>
    </>
  );
};

export default EditDues;
