import React, { useEffect, useState } from "react";
import { Form } from "antd";
import useGeneric from "../../../../../utils/hooks/user/useGeneric";
import { AgroButton } from "../../../..";

const FocusModal = (props) => {
  const { categories, setVisible, addFocus } = props;
  // const [focus, setFocus] = useState({});
  const [focus, setFocus] = useState({});
  const [form] = Form.useForm();
  const {
    subcategoriesData,
    setSubcategoriesData,
    getSubcategories,
    itemsData,
    setItemsData,
    getItems,
  } = useGeneric();

  const handleCategoryChange = (e) => {
    const id = e.target.value;
    setFocus({ ...focus, category: id });
    getSubcategories(id);
  };

  const handleSubCategoryChange = (e) => {
    const id = e.target.value;
    setFocus({ ...focus, sub_category: id });
    getItems(id);
  };

  const handleItemChange = (e) => {
    const id = e.target.value;
    console.log(focus);
    setFocus({
      ...focus,
      sub_category: id,
      item: id,
      item_name: itemsData.find((c) => c.id == id)?.name,
    });
  };

  const reset = () => {
    setSubcategoriesData([]);
    setItemsData([]);
    setFocus({ ...focus, category: "", sub_category: "", item: "" });
    form.resetFields();
  };

  useEffect(() => {
    console.log(categories);
  }, [categories]);

    const onSubmitForm = (values) => {
    // console.log("FFFFOOOCUUUS VALLUE",values);
    // console.log("rrrrrrrrrrr REEALL",focus);
    addFocus(focus);
    reset();
  };

  return (
    <Form form={form} onFinish={onSubmitForm} autoComplete="on">
      <div className="w-full lg:my-0">
        <p className="form-label text-xs">Category</p>
        <Form.Item
          rules={[{ required: true, message: "Required" }]}
          onChange={handleCategoryChange}
          name={"category"}
        >
          <select className="form-field__input__2 form-field__sel bg-white">
            <option value={""}>Select Category</option>
            {categories?.map((category) => (
              <option key={category?.name} value={category?.id}>
                {category.name}
              </option>
            ))}
          </select>
        </Form.Item>
      </div>

      <div className="w-full lg:my-0">
        <p className="form-label text-xs">Sub Category</p>
        <Form.Item
          rules={[{ required: true, message: "Required" }]}
          onChange={handleSubCategoryChange}
          name={"sub_category"}
        >
          <select className="form-field__input__2 form-field__sel bg-white">
            <option value={""}>Select Sub Category</option>
            {focus?.category ? (
              <>
                {subcategoriesData?.map((subcategory) => (
                  <option key={subcategory?.name} value={subcategory?.id}>
                    {subcategory.name}
                  </option>
                ))}
              </>
            ) : (
              <></>
            )}
          </select>
        </Form.Item>
      </div>

      <div className="w-full lg:my-0">
        <p className="form-label text-xs">Item</p>
        <Form.Item
          rules={[{ required: true, message: "Required" }]}
          onChange={handleItemChange}
          name={"item"}
        >
          <select className="form-field__input__2 form-field__sel bg-white">
            <option value={""}>Select Item</option>
            {focus?.sub_category ? (
              <>
                {itemsData?.map((item) => (
                  <option key={item?.name} value={item?.id}>
                    {item.name}
                  </option>
                ))}
              </>
            ) : (
              <></>
            )}
          </select>
        </Form.Item>
      </div>

      <div className="w-full flex gap-x-4 justify-end lg:my-0">
        <AgroButton
          text={"Cancel"}
          type="button"
          className={"px-6 text-[12px]"}
          onClick={() => setVisible(false)}
        />
        <AgroButton
          primary
          text={"Add Focus"}
          type="submit"
          className={"px-6 text-[12px]"}
        />
      </div>
    </Form>
  );
};

export default FocusModal;
