import React, {useEffect, useState} from 'react'
import { Space, Table } from 'antd';
import AgroButton from '../../misc/AgroButton';
import AddBlog from "./components/AddBlog";
import useMediaService from "../../../utils/hooks/user/useMediaService";
import {useSelector} from "react-redux";
import {InlineError} from "../../index";
import ListBlog from "./components/ListBlog";
import ViewEditBlog from "./components/ViewEditBlog";

function UserBlogs() {
  const [tab, setTab] = useState(0);
  const [blog, setBlog] = useState(null);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    size: 10,
  });

  const {
    blogsLoading,
    blogsFailure,
    setBlogsFailed,
    getBlogs,
      deleteBlogLoading,
      deleteBlog,
  } = useMediaService();

  const { blogs } = useSelector((state) => state.global);
  const handleClick = (index) => {
    if (tab === 1)
      setPaginationData({
        page: 1,
        size: 10,
      });
    setTab(index);
  };

  const handleView = (blog, tab) => {
    setBlog(blog);
    setTab(tab);
  };

  useEffect(() => {
    getBlogs(paginationData);
  }, [paginationData]);


  const handleCompleted = () => {
    setTab(0);
    setPaginationData({
      page: 1,
      size: 10,
    });
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current,
      size: pageSize,
    });
  };

  return (
      <div>
        <>
          <div className={"flex justify-end gap-x-3"}>
            <AgroButton
                primary={tab === 0}
                text="List Blog"
                className={"text-[12px]"}
                onClick={() => handleClick(0)}
            />
            <AgroButton
                primary={tab === 1}
                text="Add Blog"
                className={"text-[12px]"}
                onClick={() => handleClick(1)}
            />

          </div>
          <div>
            {tab === 0 ?(
                <>
                  <div className={"w-full mt-4"}>
                    <InlineError
                        control={blogsFailure}
                        message={blogsFailure}
                        onClose={() => setBlogsFailed(null)}
                    />
                  </div>
                  <ListBlog
                      deleteBlog={deleteBlog}
                      blogs={blogs}
                      paginationChangeHandler={paginationChangeHandler}
                      loading={blogsLoading}
                      view={(blog, tab) => handleView(blog, tab)}
                      completed={handleCompleted}
                  />
                </>
            ) : tab === 1 ?(
                <>
                  <AddBlog completed={handleCompleted} />
                </>
            ):(
                <ViewEditBlog
                    completed={handleCompleted}
                    blog={blog}
                />
            )}
          </div>
        </>
      </div>
  )
}

export default UserBlogs