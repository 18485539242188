import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { AgroButton, InlineError } from "../../../../components";
import _ from "lodash";
import useGeneric from "../../../../utils/hooks/user/useGeneric";
import { message } from "antd";
import AddIndividualDirectory from "./AddIndividualDirectory";

const AddDirectory = ({ completed, disable }) => {
    const [form] = Form.useForm();
    const [createDirectoryLoading, setCreateDirectoryLoading] = useState(null);
    const [buttonText, setButtonText] = useState("Create Directory");

    const { categoriesData, getCategories } = useGeneric();
    const [focuses, setFocuses] = useState([]);
    const success = "Successful";
    const error = "An error has occured";

    const onSubmit = (values) => {
     console.log("ready", values)
    };

    useEffect(() => {
        getCategories();
    }, []);


    return (
        <div>

            <Form form={form} onFinish={onSubmit} autoComplete="on" className="mt-5">
                <AddIndividualDirectory
                    categories={categoriesData}
                    form={form}
                    setFocuses={(focuses) => setFocuses(focuses)}
                />
                <div className="flex justify-end mt-4 mb-1 gap-x-4">
                    <AgroButton
                        loading={
                            createDirectoryLoading
                        }
                        disabled={
                            createDirectoryLoading
                        }
                        primary
                        text={
                            createDirectoryLoading
                                ? "Please wait..."
                                : buttonText
                        }
                        type="submit"
                        className={"px-10"}
                    />
                </div>
            </Form>
        </div>
    );
};

export default AddDirectory;
