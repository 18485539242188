import React, { useState } from "react";
import { Form, Input, message } from "antd";
import { AgroButton } from "../../../..";
import useCountriesStates from "../../../../../utils/hooks/auth/useCountriesStates";
import useFarmerData from "../../../../../utils/hooks/user/useFarmerData";
import _ from "lodash";
const success = "Successful";
const error = "An error has occured";

const LocationModal = (props) => {
  const { countries, setVisible, addLocation, farmRecordId } = props;
  const [location, setLocation] = useState({});
  const [form] = Form.useForm();
  const {
    statesData,
    setStatesData,
    getStates,
    citiesData,
    setCitiesData,
    getCities,
  } = useCountriesStates();
  const { createLocationLoading, createLocation } = useFarmerData();

  const handleCountryChange = (e) => {
    const id = e.target.value;
    setLocation({ ...location, country: id });
    getStates(id);
  };

  const handleStateChange = (e) => {
    const id = e.target.value;
    setLocation({ ...location, state: id });
    getCities(id);
  };

  const handleCityChange = (e) => {
    const id = e.target.value;
    setLocation({
      ...location,
      city: id,
      country_name: countries.find((c) => c.id == location.country)?.name,
      state_name: statesData.find((s) => s.id == location.state)?.name,
      city_name: citiesData.find((c) => c.id == id)?.name,
    });
  };

  const reset = () => {
    setStatesData([]);
    setCitiesData([]);
    setLocation({
      ...location,
      country: "",
      state: "",
      city: "",
      country_name: "",
      state_name: "",
      city_name: "",
    });
    form.resetFields();
  };

  const onSubmitForm = (values) => {
    console.log(values);
    const addresses = [
      {
        city_id: location.city,
        country_id: location.country,
        state_id: location.state,
        address: values.address,
      },
    ];
    const data = {
      addresses: addresses,
    };
    const formData = new FormData();
    _.forEach(data, (value, key) => {
      if (typeof value === "object") {
        if (value?.length) {
          for (let i = 0; i < value.length; i++) {
            _.forEach(value[i], (item, prop) => {
              formData.append(`${key}[${i}][${prop}]`, item);
            });
          }
        }
      } else {
        formData.append(key, value);
      }
    });

    createLocation(formData, farmRecordId).then((data) => {
      if (data?.success) {
        console.log(data);
        message.success(data?.message || success);
        addLocation({
          ...location,
          id: data?.data?.farmer_data_capture_address[0].id,
          address: values.address,
        });
        reset();
      } else {
        message.error(data?.message || error);
      }
    });
  };

  return (
    <Form form={form} onFinish={onSubmitForm} autoComplete="on">
      <div className="w-full lg:my-0">
        <p className="form-label text-xs">Country</p>
        <Form.Item
          rules={[{ required: true, message: "Required" }]}
          onChange={handleCountryChange}
          name={"country"}
        >
          <select className="form-field__input__2 bg-white form-field__sel">
            <option value={""}>Select Country</option>
            {countries?.map((item, index) => (
              <option key={index} value={item.id}>
                {item?.name?.length > 20
                  ? `${item.name.substring(0, 30)}...`
                  : item.name}
              </option>
            ))}
          </select>
        </Form.Item>
      </div>

      <div className="w-full lg:my-0">
        <p className="form-label text-xs">State</p>
        <Form.Item
          rules={[{ required: true, message: "Required" }]}
          onChange={handleStateChange}
          name={"state"}
        >
          <select className="form-field__input__2 bg-white form-field__sel">
            <option value={""}>Select State</option>
            {statesData?.map((state) => (
              <option key={state?.name} value={state?.id}>
                {state.name}
              </option>
            ))}
          </select>
        </Form.Item>
      </div>

      <div className="w-full lg:my-0">
        <p className="form-label text-xs">City</p>
        <Form.Item
          rules={[{ required: true, message: "Required" }]}
          onChange={handleCityChange}
          name={"city"}
        >
          <select className="form-field__input__2 form-field__sel bg-white">
            <option value={""}>Select City</option>
            {citiesData?.map((city) => (
              <option key={city?.name} value={city?.id}>
                {city.name}
              </option>
            ))}
          </select>
        </Form.Item>
      </div>

      <div className="w-full lg:my-0">
        <p className="form-label text-xs">Address</p>
        <Form.Item
          name="address"
          rules={[{ required: true, message: "Required" }]}
        >
          <Input className="form-field__input__2" placeholder="Address" />
        </Form.Item>
      </div>

      <div className="w-full flex gap-x-4 justify-end lg:my-0">
        <AgroButton
          text={"Cancel"}
          type="button"
          className={"px-6 text-[12px]"}
          onClick={() => setVisible(false)}
        />
        <AgroButton
          loading={createLocationLoading}
          disabled={createLocationLoading}
          primary
          text={createLocationLoading ? "Please wait..." : "Add Location"}
          type="submit"
          className={"text-[12px]"}
        />
      </div>
    </Form>
  );
};

export default LocationModal;
