import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CgMenuGridR } from "react-icons/cg";
import { MdNotificationsActive } from "react-icons/md";
import { TiArrowSortedDown } from "react-icons/ti";

function NavRight() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAlertOpen, setIsAlertsOpen] = useState(false);
  const [isAccountOpen, setIsAccountOpen] = useState(false);

  const rightNavItems = [
    {
      title: "Menu",
      control: isMenuOpen,
      Icon: CgMenuGridR,
      toggle: () => setIsMenuOpen(!isMenuOpen),
    },
    {
      title: "Notifications",
      control: isAlertOpen,
      Icon: MdNotificationsActive,
      toggle: () => setIsAlertsOpen(!isAlertOpen),
    },
    {
      title: "Account",
      control: isAccountOpen,
      Icon: TiArrowSortedDown,
      toggle: () => setIsAccountOpen(!isAccountOpen),
    },
  ];

  const { user } = useSelector((state) => state.global);
  const lastName = user?.user?.name.split(" ")[1];
  const name = lastName ? lastName : user?.user?.name.split(" ")[0];

  return (
    <div className="hidden lg:flex items-center justify-center">
  {/*     <div className="flex items-center py-[2px] pr-2 cursor-pointer rounded-lg hover:bg-gray-200 transition-all mr-3">
        <div className="h-7 w-7 mr-2 rounded-full bg-gray-700 flexed text-white">
          {name[0]}
        </div>
        <p className="mb-0 font-semibold">{name}</p>
      </div> */}
      <div className="flexed">
        {rightNavItems.map(({ title, control, Icon, toggle }) => (
          <div
            onClick={toggle}
            key={title}
            className="mx-2 group relative w-10 lg:w-14 cursor-pointer flexed flex-col"
          >
            <div className="rounded-full h-10 w-10 bg-gray-200 group-hover:bg-gray-300 transition-all p-1 flexed">
              <Icon className={`${control && "grn-text"} `} size="1.5rem" />
            </div>
            <div className="bg-black bg-opacity-90 rounded-md shadow-md hidden group-hover:block py-2 px-3 absolute top-[55px] -left-2">
              <p className="text-center text-white font-semibold whitespace-nowrap">
                {title}
              </p>
            </div>
          </div>
        ))}
      </div>

      {isMenuOpen && (
        <div className="absolute top-16 right-0 z-50 bg-green-300 p-5 w-[400px] shadow-md">
          <p className="mb-0 text-xl">FOR MENU</p>
        </div>
      )}

      {isAlertOpen && (
        <div className="absolute top-16 right-0 z-50 bg-green-300 p-5 w-[400px] shadow-md">
          <p className="mb-0 text-xl">FOR NOTIFICATIONS</p>
        </div>
      )}

      {isAccountOpen && (
        <div className="absolute top-16 right-0 z-50 bg-green-300 p-5 w-[400px] shadow-md">
          <p className="mb-0 text-xl">FOR ACCOUNT</p>
        </div>
      )}
    </div>
  );
}

export default NavRight;
