import React, {useState} from "react";
import {Table, Pagination, message} from "antd";
import { advertListColumns } from "../../../../utils/misc/mediaServices";
import { AgroButton } from "../../..";
import useMediaService from "../../../../utils/hooks/user/useMediaService";

const ListAggregation = (props) => {
  const { adverts, completed, deleteAdvert, paginationChangeHandler, loading, view } = props;
  const defaultCurrent = 1;
  const defaultPageSize = 10;
  const data = adverts?.data;

    const {
        createAdvertFailure,
    } = useMediaService();


    const [modalDelete, setModalDelete] = useState(false)
    const [advertRow, setAdvertRow] = useState({})

    const closeOpenModal = () => {
        setModalDelete(false)
        setAdvertRow("")
    }

    const success = "Successful";
    const error = "An error has occured";

    const handleView = (row) => {
    view(row, 2);
  };



    const handleDelete = (advertRow) => {
        deleteAdvert(advertRow.id).then((data) => {
            if (data?.success) {
                message.success(data?.message || success);
                if (!createAdvertFailure) {
                    completed();
                    setModalDelete(false)
                }
            } else {
                message.error(data?.message || error);
                setModalDelete(false)
            }
        });
    };

  const dataSource =
    data &&
    data.length > 0 &&
    data.map((row, index) => {
      return {
        key: row?.id,
        sn: index + 1,
          advert_url: <a target="_blank" href={row?.advert_url}>{row?.advert_url}</a> ?? "N/A",
        action: (
          <div className={"flex gap-x-2"}>
            <AgroButton
              onClick={() => handleView(row)}
              primary
              text="View"
              className={"h-fit text-[10px]"}
            />
              <AgroButton
                  // onClick={() => handleDelete(row)}
                  onClick={() => (setAdvertRow(row), setModalDelete(true))}

                  secondary
                  text="Delete"
                  className={"h-fit text-[10px]"}
              />
          </div>
        ),
      };
    });

  const onShowSizeChange = (current, pageSize) => {
    return { current, pageSize };
  };

  return (
    <>
      <div className={"mt-6"}>
        <Table
          className={"advertList"}
          rowClassName={(_, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          bordered={true}
          dataSource={dataSource}
          columns={advertListColumns}
          pagination={false}
          loading={loading}
        />
      </div>
      <div className="flex justify-end py-3">
        <Pagination
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={defaultCurrent}
          total={adverts?.total}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={defaultPageSize}
          onChange={paginationChangeHandler}
        />
      </div>

        <>
            {modalDelete &&
                <div className="modal-div overflow-auto">
                    <div className="modal-box-container">
                        <div className="col-11 col-md-8 table-popup">
                            {/*<div className="w-full mb-2 flex justify-between items-center">*/}
                            <div className="w-full mb-2">
                                <p className="mb-2 text-lg lg:text-[18px] font-bold">Are You Sure</p>
                                <p className="mb-2 text-lg lg:text-[18px] ">You want to delete this Record?</p>
                            </div>

                            <div className={"flex justify-end mt-4 gap-x-2"}>
                                <AgroButton
                                    onClick={() => closeOpenModal()}
                                    secondary
                                    text="No"
                                    className={"h-fit text-[10px]"}
                                />

                                <AgroButton
                                    onClick={() => handleDelete(advertRow)}
                                    primary
                                    text="Yes"
                                    className={"h-fit text-[10px]"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    </>
  );
};

export default ListAggregation;
