import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import MultiSelectedFormItems from "./MultiSelectedItems";
import AgroButton from "../../../../misc/AgroButton";
import { data } from "autoprefixer";



const ContactPerson = (props) => {
  const { form, getContactPersons, datas } = props;
  const [contactPersons, setContactPersons] = useState([]);
  
  const [visibility, setVisibility] = useState(true);
  const titles = ["Mr", "Mrs", "Miss", "Ms", "Dr"];
  const roles = ["Chairman", "Secretary", "Treasurer", "Officer"];

  const valuesInit = {
    title:typeof datas != 'undefined' && datas.length > 0 ? datas[0].title : "",
    first_name: typeof datas != 'undefined' && datas.length > 0 ? datas[0].first_name : "",
    surname: typeof datas != 'undefined' && datas.length > 0 ? datas[0].surname : "",
    other_name: typeof datas != 'undefined' && datas.length > 0 ? datas[0].other_name : "",
    contact_email:  typeof datas != 'undefined' && datas.length > 0 ? datas[0].email :"",
    contact_phone:  typeof datas != 'undefined' && datas.length > 0 ? datas[0].contact_phone :"",
    role:  typeof datas != 'undefined' && datas.length > 0 ? datas[0].role :"",
  };

  const [values, setValues] = useState(valuesInit);

  console.log("DAAATTTA", datas);

  const addContactPerson = () => {
    form
      .validateFields([
        "title",
        "first_name",
        "surname",
        "contact_email",
        "contact_phone",
        "role",
      ])
      .then((vals) => {
        const data = {
          title: vals?.title,
          first_name: vals?.first_name,
          surname: vals?.surname,
          contact_email: vals?.contact_email,
          contact_phone: vals?.contact_phone,
          role: vals?.role,
          name: `${vals?.title}, ${vals?.first_name} ${vals?.surname}`,
        };
        setContactPersons([...contactPersons, { ...data }]);
       reset();
      })
      .catch((error) => {});
  };

  // useEffect(() => {
  //   form.setFieldsValue({
  //     contact_email: typeof datas != 'undefined' && datas[0]? datas[0].contact_email,
  //     first_name: typeof datas != 'undefined' && datas[0]? datas[0].first_name,
  //     surname: typeof datas != 'undefined' && datas[0]? datas[0].surname,
  //     contact_other_name:
  //         typeof datas != 'undefined' && datas[0]? datas[0].other_name !== "undefined" ||
  //         typeof datas != 'undefined' && datas[0]? datas[0].other_name !== undefined
  //             ? typeof datas != 'undefined' && datas[0]? datas[0].other_name
  //             : "",
  //     contact_phone: typeof datas != 'undefined' && datas[0]? datas[0].contact_phone,
  //     title: typeof datas != 'undefined' && datas[0]? datas[0].title,
  //     role: typeof datas != 'undefined' && datas[0]? datas[0].role,
  //     contact_person_id: "3",
  //   });
  // }, []);

  const reset = () => {
    form.setFieldsValue({
      title: "",
      first_name: "",
      surname: "",
      contact_other_name: "",
      contact_email: "",
      contact_phone: "",
      role: "",
    });
    // form.setFieldsValue(valuesInit);
    setValues({ ...values, ...valuesInit });
    setVisibility(false);
  };

  const remove = (index) => {
    contactPersons.splice(index, 1);
    setContactPersons(contactPersons.filter((_, i) => i !== index));
  };

  useEffect(() => {
    getContactPersons(contactPersons);
  }, [contactPersons]);

  return (
    <>
      {visibility ? (
        <>
          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">Title</p>
            <Form.Item
              name={"title"}
              rules={[{ required: true, message: "Required" }]}
              initialValue={typeof datas != 'undefined' && datas[0]? datas[0].title : ''}
            >
              <select className="form-field__input__2 bg-white form-field__sel">
                <option value={""}>Select Title</option>
                {titles?.map((item, index) => (
                  <option key={index + item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">First Name</p>
            <Form.Item
              name={"first_name"}
              rules={[
                { required: true, message: "Required" },
                { min: 3, message: "Must be at least 3 characters" },
              ]}
              initialValue={typeof datas != 'undefined' && datas[0]? datas[0].first_name : ''}
            >
              <Input className="form-field__input__2" placeholder="Ojo"  />
            </Form.Item>
          </div>

          {/*<div className="w-full md:w-[25%] lg:my-0">*/}
          {/*  <p className="form-label text-xs">person id</p>*/}
          {/*  <Form.Item*/}
          {/*      name={"contact_person_id"}*/}
          {/*      rules={[*/}
          {/*        { required: true, message: "Required" },*/}
          {/*        { min: 1, message: "Must be at least 1 characters" },*/}
          {/*      ]}*/}
          {/*  >*/}
          {/*    <Input className="form-field__input__2" placeholder="Ojo" />*/}
          {/*  </Form.Item>*/}
          {/*</div>*/}

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">Last Name</p>
            <Form.Item
              name={"surname"}
              rules={[
                { required: true, message: "Required" },
                { min: 3, message: "Must be at least 3 characters" },
              ]} 
              initialValue={typeof datas != 'undefined' && datas[0]? datas[0].surname : ''}
            >
              <Input className="form-field__input__2" placeholder="Wale"  />
            </Form.Item>
          </div>
          {/* <div className="w-full md:w-[25%] lg:my-0">
            <p className="form-label text-xs">Other Name</p>
            <Form.Item
              name={"contact_person_id"}
              rules={[
                { required: true, message: "Required" },
                { min: 3, message: "Must be at least 3 characters" },
              ]}
              initialValue={typeof datas != 'undefined' && datas[0]? datas[0].contact_person_id : ''}
            >
              <Input className="form-field__input__2" placeholder="Wale" />
            </Form.Item>
          </div> */}

          {/* <div className="w-full md:w-[25%] lg:my-0">
            <p className="form-label text-xs">Other Name</p>
            <Form.Item
              name={"contact_other_name"}
              rules={[{ min: 3, message: "Must be at least 3 characters" }]}
              initialValue={typeof datas != 'undefined' && datas[0]? datas[0].contact_other_name : ''}
            >
              <Input className="form-field__input__2" placeholder="Wale"  />
            </Form.Item>
          </div> */}

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">Email</p>
            <Form.Item
              name={"contact_email"}
              rules={[
                { required: true, message: "Required" },
                { type: "email", message: "Invalid Email" },
              ]} 
              initialValue={typeof datas != 'undefined' && datas[0]? datas[0].email : ''}
            >
              <Input
                className="form-field__input__2"
                placeholder="matt@agroclerk.com"
               
              />
            </Form.Item>
          </div>

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">Phone</p>
            <Form.Item
              name={"contact_phone"}
              rules={[
                { required: true, message: "Required" },
                { min: 8, message: "Must be at least 8 character" },
              ]} 
              initialValue={typeof datas != 'undefined' && datas[0]? datas[0].phone : ''}
            >
              <Input
                 type="number"
                 maxLength= "11"
                className="form-field__input__2"
                placeholder="08012345678"
                onInput={(e) => e.target.value = e.target.value.slice(0, 11)}
               
                
              />
            </Form.Item>
          </div>

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">Role</p>
            <Form.Item
              name={"role"}
              rules={[{ required: true, message: "Required" }]} 
              initialValue={typeof datas != 'undefined' && datas[0]? datas[0].role : ''}
            >
              <select className="form-field__input__2 bg-white form-field__sel" >
                <option value={""}>Select Role</option>
                {roles?.map((item, index) => (
                  <option key={index + item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>
          <div className="w-full my-4 flex justify-end gap-x-6">
            {contactPersons.length ? (
              <AgroButton
                className={
                  "bg-red-500 text-[#ffffff] hover:bg-red-800 text-[10px]"
                }
                text={"Close"}
                type="button"
                onClick={() => setVisibility(false)}
              />
            ) : (
              <></>
            )}
            <AgroButton
              primary
              text={"Save"}
              type="button"
              onClick={addContactPerson}
              className={"text-[10px]"}
            />
          </div>
        </>
      ) : (
        <div className="w-full mb-4">
          <AgroButton
            primary
            text={"Add New Contact"}
            type="button"
            onClick={() => setVisibility(true)}
            className={"text-[10px]"}
          />
        </div>
      )}
      {contactPersons.length ? (
        <MultiSelectedFormItems
          items={contactPersons}
          prop={"name"}
          remove={remove}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ContactPerson;
