import React, { useState } from "react";
import { FaEye } from "react-icons/fa";
import { ImSpinner9 } from "react-icons/im";
import SolidButton from "../../../../misc/SolidButton";

const ContactPersonList = ({
  items = [],
  handleDelete,
  select,
  disable,
  deleteContactPersonLoading,
}) => {
  const [currIndex, setCurrIndex] = useState(null);
  return (
    <>
      {items.map((item, i) => (
        <div
          key={item + i}
          className="w-full flex mb-5 flex-wrap gap-2 justify-between border-gray-300 pb-6 border-b-2 border-gray-300"
        >
          <div className={"flex flex-wrap gap-y-2 gap-x-6 w-[80%]"}>
            <div className={"w-[20%]"}>
              Title
              <div className={"border p-2 flex justify-between"}>
                <div
                  onClick={() => select(i)}
                  className={
                    "border border-gray-400 rounded bg-gray-200 px-1 flex gap-x-1 items-center cursor-pointer"
                  }
                >
                  {item.title}
                </div>
              </div>
            </div>
            <div className={"w-[35%]"}>
              First Name
              <div className={"border p-2 flex justify-between"}>
                <div
                  onClick={() => select(i)}
                  className={
                    "border border-gray-400 rounded bg-gray-200 px-1 flex gap-x-1 items-center cursor-pointer"
                  }
                >
                  {item.first_name}
                </div>
              </div>
            </div>
            <div className={"w-[35%]"}>
              Last Name
              <div className={"border p-2 flex justify-between"}>
                <div
                  onClick={() => select(i)}
                  className={
                    "border border-gray-400 rounded bg-gray-200 px-1 flex gap-x-1 items-center cursor-pointer"
                  }
                >
                  {item.last_name}
                </div>
              </div>
            </div>
            <div className={"w-[30%]"}>
              Other Name
              <div className={"border p-2 flex justify-between"}>
                <div
                  onClick={() => select(i)}
                  className={
                    "border border-gray-400 rounded bg-gray-200 px-1 flex gap-x-1 items-center cursor-pointer"
                  }
                >
                  {item.other_name ?? ""}
                </div>
              </div>
            </div>
            <div className={"w-[30%]"}>
              Email
              <div className={"border p-2 flex justify-between"}>
                <div
                  onClick={() => select(i)}
                  className={
                    "border border-gray-400 rounded bg-gray-200 px-1 flex gap-x-1 items-center cursor-pointer"
                  }
                >
                  {item.email}
                </div>
              </div>
            </div>
            <div className={"w-[30%]"}>
              Phone
              <div className={"border p-2 flex justify-between"}>
                <div
                  onClick={() => select(i)}
                  className={
                    "border border-gray-400 rounded bg-gray-200 px-1 flex gap-x-1 items-center cursor-pointer"
                  }
                >
                  {item.phone}
                </div>
              </div>
            </div>
          </div>

          {!disable ? (
            <SolidButton
              Icon={
                deleteContactPersonLoading && currIndex === i ? (
                  <ImSpinner9 className="w-5 h-5 mr-3 text-gray-400 animate-spin" />
                ) : (
                  <FaEye />
                )
              }
              text={"Delete"}
              onClick={() => {
                if (!disable && !deleteContactPersonLoading) {
                  handleDelete(i, item.id);
                  setCurrIndex(i);
                }
              }}
              type={"button"}
            />
          ) : (
            <></>
          )}
        </div>
      ))}
    </>
  );
};

export default ContactPersonList;
