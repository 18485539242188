import React from "react";
import { Steps } from "antd";
import LocationInfo from "./LocationInfo";
import BankDetailsInfo from "./BankDetailsInfo";
import IndividualInfo from "./IndividualInfo";

const { Step } = Steps;

const AddIndividualFarmer = (props) => {
  const { countries, categories, form, setFocuses, setLocations, current } =
    props;

  const steps = [
    {
      title: "Create Farmer",
      content: (
        <IndividualInfo
          form={form}
          categories={categories}
          setFocuses={(focuses) => setFocuses(focuses)}
        />
      ),
    },
    {
      title: "Farmer Location",
      content: (
        <LocationInfo
          form={form}
          countries={countries}
          setLocations={(locations) => setLocations(locations)}
        />
      ),
    },
    {
      title: "Bank Details",
      content: <BankDetailsInfo />,
    },
  ];

  return (
    <div>
      <Steps size={"small"} current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
    </div>
  );
};

export default AddIndividualFarmer;
