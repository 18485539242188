import { Link } from "react-router-dom";

function ServicesOverlay() {
  const serviceItems = [
    { title: "Data Services", path: "/user/home" },
    { title: "Trading Services", path: "/user/home" },
    { title: "Field Services", path: "/user/home" },
    { title: "Technical Services", path: "/user/home" },
    { title: "Financial Services", path: "/user/home" },
    { title: "Agritech Services", path: "/user/home" },
  ];

  return (
    <div className="relative p-3 lg:p-5 mt-4 flex flex-col items-start justify-center bg-white shadow-lg transition-all duration-300">
      <div className="absolute -top-1 left-0 grn-bg w-[70px] h-1" />

      {serviceItems.map(({ title, path }) => (
        <Link to={path} key={title}>
          <p className="relative group my-1.5 cursor-pointer text-black text-opacity-70 hover:grn-text transition-all text-right">
            {title}
            <span className="absolute top-1.5 -left-2 w-1 h-0 bg-white group-hover:grn-bg group-hover:h-2.5 transition-all duration-500" />
          </p>
        </Link>
      ))}
    </div>
  );
}

export default ServicesOverlay;
