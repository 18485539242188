import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { FieldServicesLeft } from "../../components";
import UserLayout from "../../containers/layouts/UserLayout";

function FieldServices() {
  const { user } = useSelector((state) => state.global);

  return (
    <UserLayout leftSidebar={<FieldServicesLeft />} rightSidebar>
      <div className={"border-b px-3 pb-3"}>
        <p className="text-2xl font-medium">Field Services</p>
      </div>

      <div className={"mt-4 px-3"}>
        <Outlet />
      </div>
    </UserLayout>
  );
}

export default FieldServices;
