import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useFarmerData from "../../../utils/hooks/user/useFarmerData";
import AgroButton from "../../misc/AgroButton";
import AddFarmer from "./components/AddFarmer";
import ListFarmer from "./components/ListFarmer";
import { InlineError } from "../..";
import ViewAdEditFarmerData from "./components/ViewAndEditFarmerData/ViewAndEditFarmerData";
import UploadFarmer from "./components/UploadFarmer";

const FarmerRecord = () => {
  const [screen, setScreen] = useState("main");
  const [tab, setTab] = useState(0);
  const [farmerData, setFarmerData] = useState(null);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    size: 10,
  });
  const {
    farmerListLoading,
    farmerListFailure,
    setFarmerListFailed,
    getFarmerList,
  } = useFarmerData();
  const { farmerList } = useSelector((state) => state.global);

  const handleClick = (index) => {
    if (tab === 1)
      setPaginationData({
        page: 1,
        size: 10,
      });
    setTab(index);
  };

  useEffect(() => {
    getFarmerList(paginationData);
  }, [paginationData]);

  const handleCompleted = () => {
    setTab(0);
    setPaginationData({
      page: 1,
      size: 10,
    });
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current,
      size: pageSize,
    });
  };
  return (
    <div>
      {screen === "main" ? (
        <>
          <div className={"flex justify-end gap-x-3"}>
            <AgroButton
              onClick={() => handleClick(0)}
              primary={tab === 0}
              text="List Farmer"
              className={"text-[12px]"}
            />
            <AgroButton
              onClick={() => handleClick(1)}
              primary={tab === 1}
              text="Add Farmer"
              className={"text-[12px]"}
            />
            <AgroButton
              onClick={() => handleClick(2)}
              primary={tab === 2}
              text="Upload Farmer"
              className={"text-[12px]"}
            />
          </div>
          {tab === 0 ? (
            <>
              <div className={"w-full mt-4"}>
                <InlineError
                  control={farmerListFailure}
                  message={farmerListFailure}
                  onClose={() => setFarmerListFailed(null)}
                />
              </div>
              <ListFarmer
                farmerList={farmerList}
                paginationChangeHandler={paginationChangeHandler}
                loading={farmerListLoading}
                setScreen={(screen) => setScreen(screen)}
                setFarmerData={(farmerData) => setFarmerData(farmerData)}
              />
            </>
          ) : tab === 1 ? (
            <AddFarmer completed={handleCompleted} />
          ) : 
          
          (
           <UploadFarmer />
          )}
        </>
      ) : (
        <ViewAdEditFarmerData
          setScreen={(screen) => {
            setScreen(screen);
            handleCompleted(0);
          }}
          farmerData={farmerData}
        />
      )}
    </div>
  );
};

export default FarmerRecord;
