import React, {useEffect, useState} from 'react'
import { Space, Table } from 'antd';
import AgroButton from '../../misc/AgroButton';
import AddForum from "./components/addForum";
import useMediaService from "../../../utils/hooks/user/useMediaService";
import {useSelector} from "react-redux";
import {InlineError} from "../../index";
import ListForum from "./components/ListForum";
import ViewEditForum from "./components/ViewEditForum";
import ListBlog from "./components/ListBlog";
import AddBlog from "./components/AddBlog";

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <a>{text}</a>,
  },

  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'User',
    key: 'User',
    dataIndex: 'User',

  },

  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
        <Space size="middle">
          <AgroButton
              primary={true}
              text="Manage"
          />

          <AgroButton
              primary={true}
              text="Delete"
          />
        </Space>
    ),
  },
];
const data = [
  /*  {
     key: '1',
     name: 'John Brown',
     address: 'New York No. 1 Lake Park',
     User: "10",
   },
   {
     key: '2',
     name: 'Jim Green',
     address: 'London No. 1 Lake Park',
     User: "20",
   },
   {
     key: '3',
     name: 'Joe Black',
     address: 'Sidney No. 1 Lake Park',
     User: "30",
   }, */
];

function UserForum() {
  const [tab, setTab] = useState(0);
  const [blog, setForum] = useState(null);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    size: 10,
  });

  const {
    forumsLoading,
    forumsFailure,
    setForumsFailed,
    getForums,
    deleteBlogLoading,
    deleteBlog,
  } = useMediaService();

  const { forums } = useSelector((state) => state.global);
  const handleClick = (index) => {
    if (tab === 1)
      setPaginationData({
        page: 1,
        size: 10,
      });
    setTab(index);
  };

  const handleView = (blog, tab) => {
    setForum(blog);
    setTab(tab);
  };

  useEffect(() => {
    getForums(paginationData);
  }, [paginationData]);


  const handleCompleted = () => {
    setTab(0);
    setPaginationData({
      page: 1,
      size: 10,
    });
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current,
      size: pageSize,
    });
  };

  return (
      <div>
        <>
          <div className={"flex justify-end gap-x-3"}>
            <AgroButton
                primary={tab === 0}
                text="List Forum"
                className={"text-[12px]"}
                onClick={() => handleClick(0)}
            />
            <AgroButton
                primary={tab === 1}
                text="Add Forum"
                className={"text-[12px]"}
                onClick={() => handleClick(1)}
            />

          </div>
          <div>
            {tab === 0 ?(
                <>
                  <div className={"w-full mt-4"}>
                    <InlineError
                        control={forumsFailure}
                        message={forumsFailure}
                        onClose={() => setForumsFailed(null)}
                    />
                  </div>
                  <ListForum
                      deleteBlog={deleteBlog}
                      forums={forums}
                      paginationChangeHandler={paginationChangeHandler}
                      loading={forumsLoading}
                      view={(blog, tab) => handleView(blog, tab)}
                      completed={handleCompleted}
                  />
                </>
            ) : tab === 1 ?(
                <>
                  <AddForum completed={handleCompleted} />
                </>
            ):(
                <ViewEditForum
                    completed={handleCompleted}
                    blog={blog}
                />
            )}
          </div>
        </>
      </div>
  )
}

export default UserForum