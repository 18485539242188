import React, { useEffect, useState } from "react";
import {
  FaCalendarAlt,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaPinterestP,
  FaSkype,
  FaBolt,
} from "react-icons/fa";
import moment from "moment";

const news = [
  // "Magcal Fisher Basical Navikea Area Follower Power To Conjure",
  "Agroclerk goes live with version one",
  "Agriculture Remains Preferred Occupation in Rural Nigeria",
  "Novak appeals in court against dearless Care cancellation of Australia",
];

function DarkNav() {
  const [currNews, setCurrNews] = useState(0);

  function swap() {
    const shuffle = () => {
      if (currNews >= 2) {
        setCurrNews(0);
      } else {
        setCurrNews((news) => news + 1);
      }
    };

    setInterval(shuffle, 3000);
  }

  useEffect(() => {
    // setInterval(shuffle, 3000);
    // return () => clearInterval(shuffle);

    console.log("curr news", currNews);
  }, []);

  return (
    <div className="w-full">
      {/* FOR DESKTOP */}
      <div className="w-full flex items-center justify-between text-white bg-[#292929] p-2">
        <div className="hidden lg:flex">
          <div className="flex items-center">
            <div className="h-8 w-8 rounded-full nav-bg flexed">
              <FaBolt className="" size="1.1rem" />
            </div>
            <p className="mb-0 font-semibold text-white text-opacity-90 ml-2 mr-3 tracking-wide">
              TRENDING &nbsp; |{" "}
            </p>
            <p className="mb-0 mt-1 text-white text-opacity-90 text-[13px]">
              {news[0]}{" "}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center md:flex-row justify-center flex-wrap mx-auto lg:mx-0">
          <p className="mb-0 mt-1 flex items-center text-white text-opacity-90">
            <span className="mr-2">
              <FaCalendarAlt />
            </span>
            {moment(new Date()).format("MMMM Do, YYYY")}
          </p>
          <span className="mx-3 hidden md:inline">|</span>
          <div className="flex mt-1 text-white text-opacity-80 pr-2">
            <p className="mb-0 mt-1 mr-2 font-semibold text-[13px] bg-grdeen-500">
              FOLLOW US:{" "}
            </p>
            <ul className="flex items-center space-x-3">
              <li>
                <FaFacebookF />
              </li>
              <li>
                <FaTwitter />
              </li>
              <li>
                <FaLinkedinIn />
              </li>
              <li>
                <FaPinterestP />
              </li>
              <li>
                <FaSkype />
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* END OF DESKTOP */}
    </div>
  );
}

export default DarkNav;
