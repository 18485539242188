import React, { useEffect, useState } from "react";
import { Form, Modal, message } from "antd";
import { FiEdit } from "react-icons/fi";
import { MdOutlineCancel } from "react-icons/md";
import useGeneric from "../../../../../utils/hooks/user/useGeneric";
import { AgroButton } from "../../../..";
import useFarmerData from "../../../../../utils/hooks/user/useFarmerData";
import FocusModal from "./FocusModal";
import IndividualInfo from "../IndividualInfo";
import {useSelector} from "react-redux";
const success = "Successful";
const error = "An error has occured";

const EditIndividualInfo = ({ farmerData }) => {

  const [disable, setDisable] = useState(true);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const { categoriesData, getCategories } = useGeneric();
  const [focuses, setFocuses] = useState([]);
  const [recordFocuses, setRecordFocuses] = useState([]);
  const [newrecordFocuses, setNewRecordFocuses] = useState([]);
  const [farmlist, setFarmList] = useState([]);

  const {
    addFocusFunc,
    deleteFocusFunc,
    getFarmerListById,
    updateFarmerLoading,
    updateFarmer
  } = useFarmerData();

  const { singleFarmerList } = useSelector((state) => state.global);

  useEffect(() => {
    getFarmerListById(farmerData?.id)
  }, []);


  useEffect(() => {
    console.log("Farm Data",farmerData);
    form.setFieldsValue({
      first_name: farmerData?.first_name,
      last_name: farmerData?.last_name,
      middle_name: farmerData?.middle_name,
      phone_number: farmerData?.phone,
      email: farmerData?.email,
      website: farmerData?.website,
    });

    setRecordFocuses([])
    formatFocus(singleFarmerList?.focus_items);
  }, [singleFarmerList?.focus_items]);

  useEffect(() => {
    setFarmList(recordFocuses.map((fct, i) =>(
        {
          item: fct?.item_id,
          focus_item_id: fct?.focus_items_id
        }
    )))
  }, [recordFocuses])

  const onSubmitForm = (values) => {
      const  data = {
        first_name: values?.first_name ?? "",
        last_name: values?.last_name ?? "",
        middle_name: values?.middle_name ?? "",
        phone: values?.phone_number ?? "",
        email: values?.email ?? "",
        website: values?.website ?? "",
        type_id: farmerData?.type_id.toString() ?? "",
        focusItems: farmlist,
        // focusItems:focusItems,
      };
      console.log(data);
      updateFarmer(data, farmerData?.id).then((data) => {
        if (data?.success) {
          message.success(data?.message || success);
          window.location.reload()
          setDisable(true);
        } else {
          message.error(data?.message || error);
        }
      });
  };

  useEffect(() => {
    console.log("focususus", focuses);
  }, [focuses]);

  const formatAddFocus = (focus) => {
    if (focus) {
      const item = {
        item: focus?.id?.toString() ?? focus?.item,
      };
      setNewRecordFocuses((prev) => [...prev, item]);
    }
  };

  const formatFocus = (focus) => {

    if (focus) {
      focus.map((fct) =>{
        const item = {
          category: fct?.fdc_item?.item_category_id?.toString() ?? fct?.fdc_item?.category,
          item: fct?.fdc_item?.id?.toString() ?? fct?.fdc_item?.item,
          sub_category:
              fct?.fdc_item?.item_sub_category_id?.toString() ?? fct?.fdc_item?.sub_category,
          item_name: fct?.fdc_item?.name ?? fct?.fdc_item?.item_name,
          focus_items_id: fct?.id,
        };
        setRecordFocuses((prev) => [...prev, item]);
      })
    }
  };


  const addFocus = (item) => {
    formatAddFocus(item);

    const formData = new FormData();
      formData.append(`focus_items[0]`, item.item)
        addFocusFunc(formData, singleFarmerList?.id).then((data) => {
          if (data?.success) {
            message.success(data?.message || success);
            setRecordFocuses([])
            getFarmerListById(farmerData?.id);
          } else {
            message.error(data?.message || error);
          }
        });
    setVisible(false);
  };


  useEffect(() => {
    getCategories();

  }, []);

  const handleRemove = (index) => {
    deleteFocusFunc(singleFarmerList?.id, index).then((data) => {
      if (data?.success) {
        message.success(data?.message || success);
        setRecordFocuses([])
        getFarmerListById(farmerData?.id);
      } else {
        message.error(data?.message || error);
      }
    });
  };

  return (
      <div className={"mt-4"}>
        <div className={"border rounded-lg"}>
          <div
              className={
                "w-full bg-gray-300 py-2 px-4 rounded-t-lg flex items-center justify-between"
              }
          >
            <div className={"font-bold"}>Basic Info</div>
            <div onClick={() => setDisable(!disable)}>
              <a className={"flex gap-x-2 items-center"}>
                {disable ? (
                    <>
                  <span className={"font-bold"}>
                    <FiEdit />
                  </span>
                      <span className={"font-bold"}>Edit</span>
                    </>
                ) : (
                    <>
                  <span className={"font-bold text-red-500"}>
                    <MdOutlineCancel />
                  </span>
                      <span className={"font-bold text-red-500"}>Cancel</span>
                    </>
                )}
              </a>
            </div>
          </div>
          <Form
              form={form}
              onFinish={onSubmitForm}
              autoComplete="off"
              className={"w-full px-4 mt-4"}
          >
            {/*<h1>{*/}
            {/*  singleFarmerList?.focus_items?.map*/}
            {/*}</h1>*/}
            <IndividualInfo
                form={form}
                setFocuses={(focuses) => setFocuses(focuses)}
                existingFocuses={recordFocuses}
                disable={disable}
                handleRemove={(index) => handleRemove(index)}
            />
            <div className="flex justify-end my-4 gap-x-4">
              {!disable ? (
                  <AgroButton
                      primary
                      text={"Add Focus"}
                      type="button"
                      className={"px-6 text-[10px]"}
                      onClick={() => setVisible(true)}
                  />
              ) : (
                  <></>
              )}
            </div>
            <div className="flex justify-center my-4 gap-x-4">
              {!disable ? (
                  <AgroButton
                      loading={updateFarmerLoading}
                      disabled={updateFarmerLoading}
                      primary
                      text={updateFarmerLoading ? "Please wait..." : "Update"}
                      type="submit"
                      className={"px-10"}
                  />
              ) : (
                  <></>
              )}
            </div>
          </Form>

          <Modal
              title="Focus"
              centered
              visible={visible}
              onOk={() => setVisible(false)}
              onCancel={() => setVisible(false)}
              footer={null}
          >
            <FocusModal
                categories={categoriesData}
                setVisible={(bool) => setVisible(bool)}
                addFocus={(item) => addFocus(item)}
            />
          </Modal>
        </div>
      </div>
  );
};

export default EditIndividualInfo;
