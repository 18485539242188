import React from "react";
import { useSelector } from "react-redux";
import { GroupServicesLeft } from "../../components";
import UserLayout from "../../containers/layouts/UserLayout";
import { mediaSidebarItems } from "../../utils/misc/userHomeData";
import { Outlet } from "react-router-dom";

function GroupServices() {
  const { user } = useSelector((state) => state.global);

  return (
    <UserLayout leftSidebar={<GroupServicesLeft />} rightSidebar>
      <div className={"border-b px-3 pb-3"}>
        <p className="text-2xl font-bold">Group Services</p>
      </div>

      <div className={"mt-4 px-3"}>
        <Outlet />
      </div>
    </UserLayout>
  );
}

export default GroupServices;
