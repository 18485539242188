import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAggregations } from "../../../slices/globalSlice";
import axiosInstance from "../../axios/axiosInterceptors";

const useFieldService = () => {
  const dispatch = useDispatch();
  const [aggregationsLoading, setAggregationsLoading] = useState(false);
  const [aggregationsFailure, setAggregationsFailed] = useState(null);

  const [createAggregationLoading, setCreateAggregationLoading] =
    useState(false);
  const [createAggregationFailure, setCreateAggregationFailure] =
    useState(null);

  const [updateAggregationLoading, setUpdateAggregationLoading] =
    useState(false);
  const [updateAggregationFailure, setUpdateAggregationFailure] =
    useState(null);

  const getAggregations = async ({ page, size }) => {
    setAggregationsLoading(true);
    setAggregationsFailed(null);
    try {
      const { data } = await axiosInstance.get(
        `user/aggregations?include=item,item.itemSubCategory,item.itemCategory,supplyModes.country&page=${page}&per_page=${size}`
      );
      const serverResponse = data?.data?.aggregations;
      dispatch(setAggregations(serverResponse));
      setAggregationsLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setAggregationsFailed(
        isObject ? Object.values(message).join(" | ") : message
      );
      setAggregationsLoading(false);
    }
  };

  const createAggregation = async (payload) => {
    setCreateAggregationLoading(true);
    setCreateAggregationFailure(null);
    try {
      const { data } = await axiosInstance.post("user/aggregations", payload);
      setCreateAggregationLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setCreateAggregationFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCreateAggregationLoading(false);
    }
  };

  const updateAggregation = async ({ id, payload }) => {
    setUpdateAggregationLoading(true);
    setUpdateAggregationFailure(null);
    try {
      const { data } = await axiosInstance.put(
        "user/aggregations/" + id,
        payload
      );
      setUpdateAggregationLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setUpdateAggregationFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setUpdateAggregationLoading(false);
      return false;
    }
  };

  return {
    aggregationsLoading,
    aggregationsFailure,
    setAggregationsFailed,
    getAggregations,
    createAggregationLoading,
    createAggregationFailure,
    setCreateAggregationFailure,
    createAggregation,
    updateAggregationLoading,
    updateAggregationFailure,
    setUpdateAggregationFailure,
    updateAggregation,
  };
};

export default useFieldService;
