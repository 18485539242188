import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUser } from "../../../slices/globalSlice";
import axiosInstance from "../../axios/axiosInterceptors";

function useRegister() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [regLoading, setRegLoading] = useState(false);
	const [regFailure, setRegFailure] = useState(null);

	const registerUser = async (payload) => {
		setRegLoading(true);
		setRegFailure(null);
		try {
			const { data } = await axiosInstance.post("user/auth/register", payload);
			const serverResponse = data?.data;
			dispatch(setUser(serverResponse));
			navigate("/user/home");
			return data;
		} catch (error) {
			const message =
				error?.response?.data.errors || error?.response?.data?.message;
			console.log("err", error?.response?.data);
			const isObject = typeof error === "object";
			setRegFailure(isObject ? Object.values(message).join(" | ") : message);
			setRegLoading(false);
		}
	};

	return { regLoading, regFailure, setRegFailure, registerUser };
}

export default useRegister;
