import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useGroupServices from "../../../utils/hooks/user/useGroupServices";
import AgroButton from "../../misc/AgroButton";
import { Form, message } from "antd";
import { InlineError } from "../..";
import { useSelector } from "react-redux";
import CooperativeMemberDueDetail from "./components/Cooperative/CooperativeMemberDueDetail";
import UpdateFarmerDueForm from "./components/Cooperative/UpdateFarmerDueForm";
const error = "An error has occured";

const ManageCooperativeMembers = () => { 
  const [cooperative, setCooperative] = useState(null);
  const [tab, setTab] = useState(0);
  const [dues, setDues] = useState([]);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    size: 10,
  });
  const [modalFormData, setModalFormData] = useState({});

  const modalRef = useRef();
  const formSubmitRef = useRef();

  const { search } = useLocation();
  const navigate = useNavigate();
  const cooperative_id = new URLSearchParams(search).get("cooperative_id");
  const member_name = new URLSearchParams(search).get("member_name");
  

  const member_id = new URLSearchParams(search).get("member_id");
  // const due_id = new URLSearchParams(search).get("due_id");

  const openModal = (event)=>{
    closeModal();
    const dueId = event.target.getAttribute('id');
    
    getCooperativeDuesAttachedToASpecificFarmer({
      cooperativeId:cooperative_id,
      dueId:dueId,
      farmerRecordId:member_id
    }).then((data) => {
      if (data?.id) {
        
        setModalFormData({
          amount_due    :data?.amount_due,
          amount_paid   :data?.amount_paid,
          amount_balance:data?.amount_balance,
          date          :data?.date
        });
        
        modalRef.current.style.display = 'block';
      
        formSubmitRef.current.setAttribute('id', dueId);
      } else {
        message.error(data?.message || error);
      }
    });
   
  }

  const closeModal = ()=>{
    modalRef.current.style.display = 'none';
  }

  
  useEffect(() => {
    if (!cooperative_id) {
      navigate(-1);
    }
  }, []);


  const {
    getCooperative,
    cooperativeDuesLoading,
    cooperativeDuesFailure,
    setCooperativeDuesFailure,
    getCooperativeDues,
    cooperativeDuesAttachedToASpecificFarmerLoading,
    setCooperativeDuesAttachedToASpecificFarmerFailure,
    setCooperativeDuesAttachedToFarmersFailure,
    getCooperativeDuesAttachedToASpecificFarmer
  } = useGroupServices();
  const { cooperativeDues, farmersNotInCooperative } = useSelector(
    (state) => state.global
  );

  useEffect(() => {
    if (cooperative_id) {
      getCooperative(cooperative_id).then((data) => {
        if (data?.success) {
          setCooperative(data?.data?.cooperative);
        } else {
          message.error(data?.message || error);
        }
      });
    }
   
  }, []);

  const handleClick = (index) => {
    if (tab === 1)
      setPaginationData({
        page: 1,
        size: 10,
      });
    setTab(index);
  };

  useEffect(() => {
    console.log(typeof(cooperative_id))
    if ( typeof(cooperative_id) != 'undefined') {
      
      getCooperativeDues({
        id:cooperative_id,
        page: paginationData.page,
        size: paginationData.size,
      }).then((data) => {
        if (data?.success) {
          setDues(data?.data?.dues?.data);
        } else {
          message.error(data?.message || error);
        }
      });
    }
  }, []);

  const handleCompleted = () => {
    setTab(0);
    setPaginationData({
      page: 1,
      size: 10,
    });
  };

  const handleRefresh = () => {
    getCooperative(cooperative_id).then((data) => {
      if (data?.success) {
        setCooperative(data?.data?.cooperative);
      } else {
        message.error(data?.message || error);
      }
    });
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current,
      size: pageSize,
    });
  };

  


  return (
    <div>
      <InlineError
        control={cooperativeDuesFailure}
        message={cooperativeDuesFailure}
        onClose={() => setCooperativeDuesFailure(null)}
      />
      <div className={"flex justify-between items-center mb-4"}>
        <h1>{cooperative?.name}</h1>
        <h1>{member_name}</h1>
        
      </div>
      {/* {cooperative ? (
        <div className={"flex justify-end"}>
          <div className={"flex items-center gap-x-3"}>
            <AgroButton
              onClick={() => handleClick(0)}
              primary={tab === 0}
              text="List Members"
              className={"text-[10px]"}
            />
            <AgroButton
              onClick={() => handleClick(1)}
              primary={tab === 1}
              text="Add Member"
              className={"text-[10px]"}
            />
          </div>
        </div>
      ) : (
        <></>
      )} */}
      {cooperative ? (
        <>
          {tab === 0 && (
            <>
            
              <CooperativeMemberDueDetail 
                dues={dues} 
                singleDueLoading={cooperativeDuesAttachedToASpecificFarmerLoading} 
                loading={cooperativeDuesLoading} 
                paginationChangeHandler={paginationChangeHandler} 
                openModal={openModal} 
              />

              <UpdateFarmerDueForm 
                ref={{ modalRef:modalRef, formSubmitRef:formSubmitRef }}
                // formSubmitRef={formSubmitRef}
                closeModal={closeModal} 
                modalFormData={modalFormData} 
                member_id={member_id}
                cooperative_id={cooperative_id}
              />
            </>
          ) }
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ManageCooperativeMembers;
