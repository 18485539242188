import React from "react";
import GenericLayout from "../../containers/layouts/GenericLayout";

function Services() {
	return (
		<GenericLayout>
		 <section
      id="services"
      className="bg-[#f3f4fe] pt-20 pb-20 lg:pt-[120px] lg:pb-[120px]"
    >
      <div className="container">
        <div className="wow fadeInUp bg-white" data-wow-delay=".2s">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div
                className="items-center justify-between overflow-hidden border lg:flex"
              >
                <div
                  className="w-full py-12 px-7 sm:px-12 md:p-16 lg:max-w-[100%] lg:py-9 lg:px-16 xl:max-w-[100%] xl:p-[70px]"
                >
                  <span
                    className="mb-5 inline-block bg-primary py-2 px-5 text-sm font-medium text-green-600"
                  >
                    Services
                  </span>
               
                  <p className="mb-9 text-base leading-relaxed text-body-color">
				<li className="list-disc font-bold"> Farmer Data Management</li>
			The platform comes with a robust database management system that allows users
			capture farmers within their domain. The database management system comes with
			capability to perform reporting, analytics and messaging via the platform and also
			through external channels like email, short message service (SMS) and whatsapp.
                  </p>
				  <p className="mb-9 text-base leading-relaxed text-body-color">
				<li className="list-disc font-bold"> Aggregation and Distribution</li>
		Our aggregation and distribution features provides robust services along the value chain
		including but not limited to product aggregation, offtake management, anchor borrower
		programme management. It also manages input supplies, evacuation, storage and ware
		housing, processing, transportation etc.
                  </p>
			
				  <p className="mb-9 text-base leading-relaxed text-body-color">
				<li className="list-disc font-bold"> Associations and Cooperatives</li>
		Our trading features gives users a 360 degree view of their trading providing the, with
lead generation, negotiation, transaction, transportation and delivery. At any point of the
trade the user can monitor the trade digitally. Users can also trade safely using our
physical inspection services, secure payment and escrow account.
                  </p>
			
		          <p className="mb-9 text-base leading-relaxed text-body-color">
				<li className="list-disc font-bold">Records Management</li>
			The platform offers a robust record management system to farmers that enables them
keep farm records in a simple manner. It manages their income and expenditure,
customer records, supplier records, inventory, products etc.
                  </p>

				  <p className="mb-9 text-base leading-relaxed text-body-color">
				<li className="list-disc font-bold"> Loans and Grants</li>
			This gives users access to loans and grants. The feature automatically prequalifies the
users thereby reducing the time required for loan processing and reduces processing
time for loans.
                  </p>

				  <p className="mb-9 text-base leading-relaxed text-body-color">
				<li className="list-disc font-bold">Insurance</li>
				This gives users access to various agricultural insurance types and providers.
                  </p>

				  <p className="mb-9 text-base leading-relaxed text-body-color">
				<li className="list-disc font-bold">Technical Assistance</li>
			The platform offers various forms of technical assistance both digitally and in person to
	farmers on its database to improve their offerings such as crop yield, new markets,
	financing options etc
                  </p>

				  <p className="mb-9 text-base leading-relaxed text-body-color">
				<li className="list-disc font-bold">Governments</li>
			The platforms provides unique set of features that enables governments better manage
the agricultural programmes.
                  </p>

				  <p className="mb-9 text-base leading-relaxed text-body-color">
				<li className="list-disc font-bold">Financial Institutions</li>
			The platform incorporates financial institution including banking and non-banking
financial institutions such as development finance giving them access to high grade
agricultural assets to transact with.
                  </p>
			     
			
                </div>
           
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
		</GenericLayout>
	);
}

export default Services;










