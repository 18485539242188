import React, { useState } from "react";
import { FaEye } from "react-icons/fa";
import { ImSpinner9 } from "react-icons/im";
import SolidButton from "../../../../misc/SolidButton";
import { AiOutlineDelete } from "react-icons/ai";

const LocationList = ({
  items = [],
  handleDelete,
  select,
  disable,
  deleteLocationLoading,
}) => {
  const [currIndex, setCurrIndex] = useState(null);
  return (
    <>
      {items.map((item, i) => (
        <div
          key={item + i}
          className="w-full flex mb-5 flex-wrap gap-2 justify-between pb-6 border-b-2 border-gray-300"
        >
          <div className={"flex flex-wrap gap-y-2 gap-x-6 w-[80%]"}>
            <div className={"w-[30%]"}>
              Country
              <div className={"border p-2 flex justify-between"}>
                <div
                  onClick={() => select(i)}
                  className={
                    "border border-gray-400 rounded bg-gray-200 px-1 flex gap-x-1 items-center cursor-pointer"
                  }
                >
                  {item.country_name}
                </div>
              </div>
            </div>
            <div className={"w-[30%]"}>
              State
              <div className={"border p-2 flex justify-between"}>
                <div
                  onClick={() => select(i)}
                  className={
                    "border border-gray-400 rounded bg-gray-200 px-1 flex gap-x-1 items-center cursor-pointer"
                  }
                >
                  {item.state_name}
                </div>
              </div>
            </div>
            <div className={"w-[30%]"}>
              City
              <div className={"border p-2 flex justify-between"}>
                <div
                  onClick={() => select(i)}
                  className={
                    "border border-gray-400 rounded bg-gray-200 px-1 flex gap-x-1 items-center cursor-pointer"
                  }
                >
                  {item.city_name}
                </div>
              </div>
            </div>
            <div className={"w-[97%]"}>
              Address
              <div className={"border p-2 flex justify-between"}>
                <div
                  onClick={() => select(i)}
                  className={
                    "border border-gray-400 rounded bg-gray-200 px-1 flex gap-x-1 items-center cursor-pointer"
                  }
                >
                  {item.address ?? "None"}
                </div>
              </div>
            </div>
          </div>

          {!disable ? (
            <SolidButton
              Icon={
                deleteLocationLoading && currIndex === i ? (
                  <ImSpinner9 className="w-5 h-5 mr-3 text-gray-400 animate-spin" />
                ) : (
                  <AiOutlineDelete />
                )
              }
              text={"Delete"}
              onClick={() => {
                if (!disable && !deleteLocationLoading) {
                  handleDelete(i);
                  setCurrIndex(i);
                }
              }}
              type="button"
            />
          ) : (
            <></>
          )}
        </div>
      ))}
    </>
  );
};

export default LocationList;
