import React from 'react'
import { Card, Col, Row } from 'antd';

const Dashboard = () => {
  return (
    <div>
                <div  className="site-card-wrapper mt-8">
    <Row gutter={12}>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
        title="Blog Story" bordered={false}>
         3000
        </Card>
      </Col>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
        title="Forum" bordered={false}>
         150
        </Card>
      </Col>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
         title="Directory" bordered={false}>
          30
        </Card>
      </Col>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
         title="Events" bordered={false}>
        30
        </Card>
      </Col>
    </Row>
  </div>
    </div>
 
  )
}

export default Dashboard