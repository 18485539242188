import React from "react";
import BankDetails from "./BankDetails";

const BankDetailsInfo = ({disable}) => {
  return (
    <>
      <div className="flex items-center justify-start md:justify-between flex-wrap w-full">
        <div className="w-full mb-2">
          <p className="form-label w-full">Bank Details (Optional)</p>
        </div>
        <BankDetails disable={disable} />
      </div>
    </>
  );
};

export default BankDetailsInfo;
