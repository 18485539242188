import React from "react";
import ContactPerson from "./ContactPerson";

const ContactPersonInfo = ({
  form,
  setContactPersons,
  disable,
  existingContacts,
  farmRecordId,
  handleRemove
}) => {
  const getContactPersons = (contactPersons) => {
    setContactPersons(contactPersons);
  };
  return (
    <>
      <div className="flex items-center justify-start md:justify-between flex-wrap w-full">
        <div className="w-full mb-2 flex justify-between items-center">
          <p className="form-label">Contact Person</p>
        </div>
        <ContactPerson
          form={form}
          getContactPersons={(contactPersons) =>
            getContactPersons(contactPersons)
          }
          disable={disable}
          existingContacts={existingContacts}
          farmRecordId={farmRecordId}
          handleRemove={handleRemove}
        />
      </div>
    </>
  );
};

export default ContactPersonInfo;
