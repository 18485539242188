import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { AgroButton, InlineError } from "../../../../components";
import useCountriesStates from "../../../../utils/hooks/auth/useCountriesStates";
import _ from "lodash";
import { message } from "antd";

import { EditorState } from "draft-js";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const valuesInit = {
    country: "",
    state: "",
    city: "",
    address: "",
    country_name: "",
    state_name: "",
    city_name: "",
};
const { TextArea } = Input;

const AddGroup = ({ completed, disable }) => {
    const [form] = Form.useForm();

    const success = "Successful";
    const error = "An error has occured";

    const [buttonText, setButtonText] = useState("Create Message");
    const [current, setCurrent] = useState(0);
    const [location, setLocation] = useState(valuesInit);
    const [locations, setLocations] = useState([]);
    const { countriesData, getCountries } = useCountriesStates();

    const {
        statesData,
        setStatesData,
        getStates,
        citiesData,
        setCitiesData,
        getCities,
    } = useCountriesStates();


    const addLocation = () => {
        form
            .validateFields([
                "country",
                "state",
                "city",
                "address",
                "contact_email",
                "contact_phone",
            ])
            .then((vals) => {
                const data = {
                    ...vals,
                    country_name: "",
                    state_name: "",
                    city_name: citiesData.find((c) => c.id == vals.city)?.name,
                };
                setLocations([...locations, { ...data }]);
                reset();
            })
            .catch((error) => {});
    };

    const handleCountryChange = (e) => {
        const id = e.target.value;
        setLocation({ ...location, country: id });
        getStates(id);
    };

    const handleCityChange = (e) => {
        const id = e.target.value;
        setLocation({
            ...location,
            city: id,
        });
        // reset();
    };

    const handleStateChange = (e) => {
        const id = e.target.value;
        setLocation({ ...location, state: id });
        getCities(id);
    };


    const reset = () => {
        setStatesData([]);
        setCitiesData([]);
        form.setFieldsValue({ country: "", state: "", city: "", address: "" });
        setLocation({ ...location, ...valuesInit });
        // setVisibility(false);
    };



    useEffect(() => {
        getCountries();
    }, []);

    const [postBody, setpostBody] = useState("");
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const [convertedContent, setConvertedContent] = useState(null);
    const handleEditorChange = (state) => {
        setEditorState(state);
        convertContentToHTML();
    };
    const convertContentToHTML = () => {
        let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
        setConvertedContent(currentContentAsHTML);
        setpostBody(currentContentAsHTML);
    };
    const createMarkup = (html) => {
        return {
            __html: DOMPurify.sanitize(html)
        };
    };

    console.log("one", editorState)
    console.log("Two", postBody)


    const onSubmit = (values) => {
       console.log(values)
      };

    return (
        <div>
            {/*<InlineError*/}
            {/*    control={createEventFailure}*/}
            {/*    message={createEventFailure}*/}
            {/*    onClose={() => setCreateEventFailure(null)}*/}
            {/*/>*/}
            <Form form={form} onFinish={onSubmit} autoComplete="on" className="mt-5">
                <div className="flex items-center justify-start md:justify-between flex-wrap w-full">
                    <div className="w-full mb-2 flex justify-between items-center">
                        <p className="mb-2 text-lg lg:text-[18px] font-bold">Create Group</p>
                    </div>


             {/*       <div className="w-full md:w-[29%] lg:my-0">*/}
             {/*           <p className="form-label text-xs">Country</p>*/}
             {/*           <Form.Item*/}
             {/*               onChange={handleCountryChange}*/}
             {/*               name={"country"}*/}
             {/*               // rules={[{ required: true, message: "Required" }]}*/}
             {/*           >*/}
             {/*               <select className="form-field__input__2 bg-white form-field__sel">*/}
             {/*                   <option value={""}>Select Country</option>*/}
             {/*                   {countriesData?.map((item, index) => (*/}
             {/*                       <option key={index} value={item.id}>*/}
             {/*                           {item?.name?.length > 20*/}
             {/*                               ? `${item.name.substring(0, 30)}...`*/}
             {/*                               : item.name}*/}
             {/*                       </option>*/}
             {/*                   ))}*/}
             {/*               </select>*/}
             {/*           </Form.Item>*/}
             {/*       </div>*/}

             {/*       <div className="w-full md:w-[29%] lg:my-0">*/}
             {/*           <p className="form-label text-xs">State</p>*/}
             {/*           <Form.Item*/}
             {/*               onChange={handleStateChange}*/}
             {/*               name={"state"}*/}
             {/*               // rules={[{ required: true, message: "Required" }]}*/}
             {/*           >*/}
             {/*               <select className="form-field__input__2 bg-white form-field__sel">*/}
             {/*                   <option value={""}>Select State</option>*/}
             {/*                   {statesData?.map((state) => (*/}
             {/*                       <option key={state?.name} value={state?.id}>*/}
             {/*                           {state.name}*/}
             {/*                       </option>*/}
             {/*                   ))}*/}
             {/*               </select>*/}
             {/*           </Form.Item>*/}
             {/*       </div>*/}

             {/*       <div className="w-full md:w-[29%] lg:my-0">*/}
             {/*           <p className="form-label text-xs">City</p>*/}
             {/*           <Form.Item*/}
             {/*               onChange={handleCityChange}*/}
             {/*               name={"city"}*/}
             {/*               // rules={[{ required: true, message: "Required" }]}*/}
             {/*           >*/}
             {/*               <select className="form-field__input__2 form-field__sel bg-white">*/}
             {/*                   <option value={""}>Select City</option>*/}
             {/*                   {citiesData?.map((city) => (*/}
             {/*                       <option key={city?.name} value={city?.id}>*/}
             {/*                           {city.name}*/}
             {/*                       </option>*/}
             {/*                   ))}*/}
             {/*               </select>*/}
             {/*           </Form.Item>*/}
             {/*       </div>*/}

             {/*   </div>*/}
             {/*   <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">*/}
             {/*       <div className="w-full md:w-[50%] lg:my-0 ">*/}
             {/*           <p className="form-label">Search For Recipients</p>*/}
             {/*           <Form.Item*/}
             {/*               name="search"*/}
             {/*               rules={[*/}
             {/*                   { required: true, message: "Required" },*/}
             {/*                   { min: 2, message: "Must be at least 2 characters" },*/}
             {/*               ]}*/}
             {/*           >*/}
             {/*               <Input*/}
             {/*                   className="form-field__input__2"*/}
             {/*                   placeholder=" "*/}
             {/*               />*/}
             {/*           </Form.Item>*/}
             {/*       </div>*/}
             {/*   </div>*/}

             {/*<div className="flex items-center justify-start md:justify-between flex-wrap w-full ">*/}
             {/*       <div className="w-full md:w-[100%] lg:my-0 ">*/}
             {/*           <p className="form-label">Create Message</p>*/}
             {/*           <Editor*/}
             {/*               editorState={editorState}*/}
             {/*               toolbarClassName="toolbarClassName"*/}
             {/*               wrapperClassName="wrapperClassName"*/}
             {/*               editorClassName="editorClassName"*/}
             {/*               onEditorStateChange={handleEditorChange}*/}
             {/*           />*/}
             {/*       </div>*/}
             {/*   </div>*/}

             {/*   <div className="flex justify-end mt-4 mb-1 gap-x-4">*/}
             {/*       <AgroButton*/}
             {/*           // loading={createEventLoading}*/}
             {/*           // disabled={createEventLoading}*/}
             {/*           primary*/}
             {/*           text={"Save"}*/}
             {/*           type="submit"*/}
             {/*           className={"px-10"}*/}
             {/*       />*/}
                </div>
            </Form>

        </div>
    );
};

export default AddGroup;
