import React, { useState } from "react";
import { Table, Pagination, Modal } from "antd";
import AgroButton from "../../../../misc/AgroButton";
import EditDues from "./EditDues";
import { cooperativesDuesListColumns } from "../../../../../utils/misc/groupServices";

const ListDues = (props) => {
  const [visible, setVisible] = useState(false);
  const [activeDue, setActiveDue] = useState(null);
  const { dues, paginationChangeHandler, loading, cooperative, completed } = props;
  const defaultCurrent = 1;
  const defaultPageSize = 10;
  const data = dues?.data;
  let dataSource =
    data &&
    data.length > 0 &&
    data.map((row, index) => {
      return {
        key: row?.id,
        sn: index + 1,
        name: row?.name,
        description: row?.description,
        type: row?.type,
        amount: parseFloat(row?.amount).toLocaleString(),
        manage: (
          <div className={"flex justify-between"}>
            <AgroButton
              primary
              text={"Edit"}
              type="button"
              className={"text-[10px]"}
              onClick={() => {
                setActiveDue(row);
                setVisible(true)
              }}
            />
          </div>
        ),
      };
    });

  const onShowSizeChange = (current, pageSize) => {
    return { current, pageSize };
  };
  return (
    <>
      <div className={"mt-6"}>
        <Table
          className={"farmerList text-[14px]"}
          rowClassName={(_, index) =>
            index % 2 === 0
              ? "table-row-light text-[13px]"
              : "table-row-dark text-[13px]"
          }
          bordered={true}
          dataSource={dataSource}
          columns={cooperativesDuesListColumns}
          pagination={false}
          loading={loading}
        />
      </div>
      <div className="flex justify-end py-3">
        <Pagination
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={defaultCurrent}
          total={dues?.total}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={defaultPageSize}
          onChange={paginationChangeHandler}
          className={"text-[13px]"}
        />
      </div>

      <Modal
        title="Edit Due"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <EditDues cooperative={cooperative} completed={completed} due={activeDue} setVisible={setVisible} />
      </Modal>
    </>
  );
};

export default ListDues;
