import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import MultiSelectedFormItems from "./MultiSelectedItems";
import AgroButton from "../../../../misc/AgroButton";

const valuesInit = {
  title: "",
  first_name: "",
  last_name: "",
  other_name: "",
  email: "",
  phone: "",
  role: "",
};

const ContactPerson = (props) => {
  const { form, getContactPersons } = props;
  const [contactPersons, setContactPersons] = useState([]);
  const [values, setValues] = useState(valuesInit);
  const [visibility, setVisibility] = useState(true);
  const titles = ["Mr", "Mrs", "Miss", "Ms", "Dr"];
  const roles = ["Chairman", "Secretary", "Treasurer", "Officer"];

  const addContactPerson = () => {
    form
      .validateFields([
        "contact_title",
        "contact_first_name",
        "contact_last_name",
        "contact_other_name",
        "contact_email",
        "contact_phone",
        "contact_role",
      ])
      .then((vals) => {
        const data = {
          title: vals?.contact_title,
          first_name: vals?.contact_first_name,
          last_name: vals?.contact_last_name,
          other_name: vals?.contact_other_name,
          email: vals?.contact_email,
          phone: vals?.contact_phone,
          role: vals?.contact_role,
          name: `${vals?.contact_title}, ${vals?.contact_first_name} ${vals?.contact_last_name}`,
        };
        setContactPersons([...contactPersons, { ...data }]);
        reset();
      })
      .catch((error) => {});
  };

  const reset = () => {
    form.setFieldsValue({
      contact_title: "",
      contact_first_name: "",
      contact_last_name: "",
      contact_other_name: "",
      contact_email: "",
      contact_phone: "",
      contact_role: "",
    });
    setValues({ ...values, ...valuesInit });
    setVisibility(false);
  };

  const remove = (index) => {
    contactPersons.splice(index, 1);
    setContactPersons(contactPersons.filter((_, i) => i !== index));
  };

  useEffect(() => {
    getContactPersons(contactPersons);
  }, [contactPersons]);

  return (
    <>
      {visibility ? (
        <>
          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">Title</p>
            <Form.Item
              name={"contact_title"}
              rules={[{ required: true, message: "Required" }]}
            >
              <select className="form-field__input__2 bg-white form-field__sel">
                <option value={""}>Select Title</option>
                {titles?.map((item, index) => (
                  <option key={index + item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">First Name</p>
            <Form.Item
              name={"contact_first_name"}
              rules={[
                { required: true, message: "Required" },
                { min: 3, message: "Must be at least 3 characters" },
              ]}
            >
              <Input className="form-field__input__2" placeholder="Ojo" />
            </Form.Item>
          </div>

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">Last Name</p>
            <Form.Item
              name={"contact_last_name"}
              rules={[
                { required: true, message: "Required" },
                { min: 3, message: "Must be at least 3 characters" },
              ]}
            >
              <Input className="form-field__input__2" placeholder="Wale" />
            </Form.Item>
          </div>

          {/* <div className="w-full md:w-[25%] lg:my-0">
            <p className="form-label text-xs">Other Name</p>
            <Form.Item
              name={"contact_other_name"}
              rules={[{ min: 3, message: "Must be at least 3 characters" }]}
            >
              <Input className="form-field__input__2" placeholder="Wale" />
            </Form.Item>
          </div> */}

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">Email</p>
            <Form.Item
              name={"contact_email"}
              rules={[
                { required: true, message: "Required" },
                { type: "email", message: "Invalid Email" },
              ]}
            >
              <Input
                className="form-field__input__2"
                placeholder="matt@agroclerk.com"
              />
            </Form.Item>
          </div>

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">Phone</p>
            <Form.Item
              name={"contact_phone"}
              rules={[
                { required: true, message: "Required" },
                { min: 11, message: "Must be at least 8 characters" },
                {max: 11,  message: "Must not exceed 11 characters" }
              ]}
            >
              <Input
                type="tel"
                className="form-field__input__2"
                placeholder="08012345678"
              />
            </Form.Item>
          </div>

          <div className="w-full md:w-[29%] lg:my-0">
            <p className="form-label text-xs">Role</p>
            <Form.Item
              name={"contact_role"}
              rules={[{ required: true, message: "Required" }]}
            >
              <select className="form-field__input__2 bg-white form-field__sel">
                <option value={""}>Select Role</option>
                {roles?.map((item, index) => (
                  <option key={index + item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Form.Item>
          </div>
          <div className="w-full my-4 flex justify-end gap-x-6">
            {contactPersons.length ? (
              <AgroButton
                className={
                  "bg-red-500 text-[#ffffff] hover:bg-red-800 text-[10px]"
                }
                text={"Close"}
                type="button"
                onClick={() => setVisibility(false)}
              />
            ) : (
              <></>
            )}
            <AgroButton
              primary
              text={"Save"}
              type="button"
              onClick={addContactPerson}
              className={"text-[10px]"}
            />
          </div>
        </>
      ) : (
        <div className="w-full mb-4">
          <AgroButton
            primary
            text={"Add New Contact"}
            type="button"
            onClick={() => setVisibility(true)}
            className={"text-[10px]"}
          />
        </div>
      )}
      {contactPersons.length ? (
        <MultiSelectedFormItems
          items={contactPersons}
          prop={"name"}
          remove={remove}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default ContactPerson;
