import React, { useEffect, useState } from "react";
import { Form, Modal } from "antd";
import { FiEdit } from "react-icons/fi";
import { MdOutlineCancel, MdDoneOutline } from "react-icons/md";
import useCountriesStates from "../../../../../utils/hooks/auth/useCountriesStates";
import { AgroButton } from "../../../..";
import LocationInfo from "../LocationInfo";
import _ from "lodash";
import LocationModal from "./LocationModal";

const EditLocationInfo = ({ farmerData }) => {
  const [disable, setDisable] = useState(true);
  const [visible, setVisible] = useState(false);
  const { countriesData, getCountries } = useCountriesStates();
  const [locations, setLocations] = useState([]);
  const [recordLocations, setRecordLocations] = useState([]);

  useEffect(() => {
    console.log(farmerData);
    if (farmerData?.addresses && farmerData?.addresses?.length) {
      farmerData?.addresses?.map((address) => {
        formatLocation(address);
      });
    }
  }, [farmerData]);

  useEffect(() => {
    getCountries();
  }, []);

  const formatLocation = (location) => {
    if (location) {
      const item = {
        id: location?.id ?? location?.id,
        country: location?.country_id?.toString() ?? location?.country,
        state: location?.state_id?.toString() ?? location?.state,
        city: location?.city_id?.toString() ?? location?.city,
        address: location?.address ?? location?.address,
        country_name: location?.country?.name ?? location?.country_name,
        state_name: location?.state?.name ?? location?.state_name,
        city_name: location?.city?.name ?? location?.city_name,
      };
      setRecordLocations((prev) => [...prev, item]);
    }
  };

  const addLocation = (item) => {
    console.log(item);
    formatLocation(item);
    setVisible(false);
  };

  const handleRemove = (index) => {
    recordLocations.splice(index, 1);
    setRecordLocations(recordLocations.filter((_, i) => i !== index));
  };

  return (
    <div className={"mt-6"}>
      <div className={"border rounded-lg"}>
        <div
          className={
            "w-full bg-gray-300 py-2 px-4  flex items-center justify-between"
          }
        >
          <div className={"font-bold"}>Farmer Location</div>
          <div onClick={() => setDisable(!disable)}>
            <a className={"flex gap-x-2 items-center"}>
              {disable ? (
                <>
                  <span className={"font-bold"}>
                    <FiEdit />
                  </span>
                  <span className={"font-bold"}>Edit</span>
                </>
              ) : (
                <>
                  <span className={"font-bold text-green-500"}>
                    <MdDoneOutline />
                  </span>
                  <span className={"font-bold text-green-500"}>Save</span>
                </>
              )}
            </a>
          </div>
        </div>
        <Form className={"w-full px-4 mt-4"}>
          <LocationInfo
            countries={countriesData}
            setLocations={(locations) => setLocations(locations)}
            existingLocations={recordLocations}
            disable={disable}
            farmRecordId={farmerData?.id}
            handleRemove={handleRemove}
          />
          {!disable ? (
            <div className="flex justify-end my-4 gap-x-4">
              <AgroButton
                primary
                text={"Add Location"}
                type="button"
                className={"px-6 text-[10px]"}
                onClick={() => setVisible(true)}
              />
            </div>
          ) : (
            <></>
          )}
        </Form>

        <Modal
          title="Location"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <LocationModal
            countries={countriesData}
            setVisible={(bool) => setVisible(bool)}
            addLocation={(item) => addLocation(item)}
            farmRecordId={farmerData?.id}
          />
        </Modal>
      </div>
    </div>
  );
};

export default EditLocationInfo;
