import { useState } from "react";
import { useDispatch } from "react-redux";
import { setBlogs } from "../../../slices/globalSlice";
import { setForums } from "../../../slices/globalSlice";
import { setEvents } from "../../../slices/globalSlice";
import { setAdverts } from "../../../slices/globalSlice";
import axiosInstance from "../../axios/axiosInterceptors";

const useMediaService = () => {
  const dispatch = useDispatch();
  const [blogsLoading, setBlogsLoading] = useState(false);
  const [blogsFailure, setBlogsFailed] = useState(null);

  const [createBlogLoading, setCreateBlogLoading] = useState(false);
  const [createBlogFailure, setCreateBlogFailure] = useState(null);

  const [updateBlogLoading, setUpdateBlogLoading] = useState(false);
  const [updateBlogFailure, setUpdateBlogFailure] = useState(null);

  const [deleteBlogLoading, setDeleteBlogLoading] = useState(false);
  const [deleteBlogFailure, setDeleteBlogFailure] = useState(null);



  const [forumsLoading, setForumsLoading] = useState(false);
  const [forumsFailure, setForumsFailed] = useState(null);

  const [createForumLoading, setCreateForumLoading] = useState(false);
  const [createForumFailure, setCreateForumFailure] = useState(null);

  const [updateForumLoading, setUpdateForumLoading] = useState(false);
  const [updateForumFailure, setUpdateForumFailure] = useState(null);

  const [deleteForumLoading, setDeleteForumLoading] = useState(false);
  const [deleteForumFailure, setDeleteForumFailure] = useState(null);



  const [eventsLoading, setEventsLoading] = useState(false);
  const [eventsFailure, setEventsFailed] = useState(null);

  const [createEventLoading, setCreateEventLoading] = useState(false);
  const [createEventFailure, setCreateEventFailure] = useState(null);

  const [updateEventLoading, setUpdateEventLoading] = useState(false);
  const [updateEventFailure, setUpdateEventFailure] = useState(null);

  const [deleteEventLoading, setDeleteEventLoading] = useState(false);
  const [deleteEventFailure, setDeleteEventFailure] = useState(null);


  const [advertsLoading, setAdvertsLoading] = useState(false);
  const [advertsFailure, setAdvertsFailed] = useState(null);

  const [createAdvertLoading, setCreateAdvertLoading] = useState(false);
  const [createAdvertFailure, setCreateAdvertFailure] = useState(null);

  const [updateAdvertLoading, setUpdateAdvertLoading] = useState(false);
  const [updateAdvertFailure, setUpdateAdvertFailure] = useState(null);

  const [deleteAdvertLoading, setDeleteAdvertLoading] = useState(false);
  const [deleteAdvertFailure, setDeleteAdvertFailure] = useState(null);


  const getBlogs = async ({ page, size }) => {
    setBlogsLoading(true);
    setBlogsFailed(null);
    try {
      const { data } = await axiosInstance.get(
          `user/posts?filter[post_type]=blog&include=categories, tags, comments, comments.replies, comments.likes, comments.likes.user&page=${page}&per_page=${size}`
      );
      const serverResponse = data?.data?.posts;
      console.log(serverResponse )
      dispatch(setBlogs(serverResponse));
      setBlogsLoading(false);
      return data;
    } catch (error) {
      const message =
          error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setBlogsFailed(
          isObject ? Object.values(message).join(" | ") : message
      );
      setBlogsLoading(false);
    }
  };

  const createBlog = async (payload) => {
    setCreateBlogLoading(true);
    setCreateBlogFailure(null);
    try {
      const { data } = await axiosInstance.post("user/posts", payload);
      setCreateBlogLoading(false);
      return data;
    } catch (error) {
      const message =
          error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setCreateBlogFailure(
          isObject ? Object.values(message).join(" | ") : message
      );
      setCreateBlogLoading(false);
    }
  };

  const updateBlog = async ({ id, payload }) => {
    setUpdateBlogLoading(true);
    setUpdateBlogFailure(null);
    try {
      // const { data } = await axiosInstance.put(
      const { data } = await axiosInstance.post(
          "user/posts/" + id,
          payload
      );
      setUpdateBlogLoading(false);
      return data;
    } catch (error) {
      const message =
          error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setUpdateBlogFailure(
          isObject ? Object.values(message).join(" | ") : message
      );
      setUpdateBlogLoading(false);
      return false;
    }
  };

  const deleteBlog = async (postId) => {
    setDeleteBlogLoading(true);
    setDeleteBlogFailure(null);
    try {
      const { data } = await axiosInstance.delete(
          `user/posts/${postId}`
      );
      setDeleteBlogLoading(false);
      return data;
    } catch (error) {
      const message =
          error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setDeleteBlogFailure(
          isObject ? Object.values(message).join(" | ") : message
      );
      setDeleteBlogLoading(false);
    }
  };


  const getForums = async ({ page, size }) => {
    setForumsLoading(true);
    setForumsFailed(null);
    try {
      const { data } = await axiosInstance.get(
          `user/posts?filter[post_type]=forum&include=categories, tags, comments, comments.replies, comments.likes, comments.likes.user&page=${page}&per_page=${size}`
      );
      const serverResponse = data?.data?.posts;
      // console.log(serverResponse )
      dispatch(setForums(serverResponse));
      setForumsLoading(false);
      return data;
    } catch (error) {
      const message =
          error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setForumsFailed(
          isObject ? Object.values(message).join(" | ") : message
      );
      setForumsLoading(false);
    }
  };



  // EVENT START HERE
  const getEvents = async ({ page, size }) => {
    setEventsLoading(true);
    setEventsFailed(null);
    try {
      const { data } = await axiosInstance.get(
          `user/events?page=${page}&per_page=${size}`
      );
      const serverResponse = data?.data?.events;
      console.log(serverResponse )
      dispatch(setEvents(serverResponse));
      setEventsLoading(false);
      return data;
    } catch (error) {
      const message =
          error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setEventsFailed(
          isObject ? Object.values(message).join(" | ") : message
      );
      setEventsLoading(false);
    }
  };

  const createEvent = async (payload) => {
    setCreateEventLoading(true);
    setCreateEventFailure(null);
    try {
      const { data } = await axiosInstance.post("user/events", payload);
      setCreateEventLoading(false);
      return data;
    } catch (error) {
      const message =
          error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setCreateEventFailure(
          isObject ? Object.values(message).join(" | ") : message
      );
      setCreateEventLoading(false);
    }
  };

  const updateEvent = async ({ id, payload }) => {
    setUpdateEventLoading(true);
    setUpdateEventFailure(null);
    try {
      const { data } = await axiosInstance.put(
          "user/events/" + id,
          payload
      );
      setUpdateEventLoading(false);
      return data;
    } catch (error) {
      const message =
          error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setUpdateEventFailure(
          isObject ? Object.values(message).join(" | ") : message
      );
      setUpdateEventLoading(false);
      return false;
    }
  };

  const deleteEvent = async (eventId) => {
    setDeleteEventLoading(true);
    setDeleteEventFailure(null);
    try {
      const { data } = await axiosInstance.delete(
          `user/events/${eventId}`
      );
      setDeleteEventLoading(false);
      return data;
    } catch (error) {
      const message =
          error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setDeleteBlogFailure(
          isObject ? Object.values(message).join(" | ") : message
      );
      setDeleteEventLoading(false);
    }
  };
  // EVENT END HERE


  //ADVERT START HERE
  const getAdverts = async ({ page, size }) => {
    setAdvertsLoading(true);
    setAdvertsFailed(null);
    try {
      const { data } = await axiosInstance.get(
          `user/adverts?page=${page}&per_page=${size}`
      );
      const serverResponse = data?.data?.adverts;
      console.log(serverResponse )
      dispatch(setAdverts(serverResponse));
      setAdvertsLoading(false);
      return data;
    } catch (error) {
      const message =
          error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setAdvertsFailed(
          isObject ? Object.values(message).join(" | ") : message
      );
      setAdvertsLoading(false);
    }
  };

  const createAdvert = async (payload) => {
    setCreateAdvertLoading(true);
    setCreateAdvertFailure(null);
    try {
      const { data } = await axiosInstance.post("user/adverts", payload);
      setCreateAdvertLoading(false);
      return data;
    } catch (error) {
      const message =
          error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setCreateAdvertFailure(
          isObject ? Object.values(message).join(" | ") : message
      );
      setCreateAdvertLoading(false);
    }
  };

  const updateAdvert = async ({ id, payload }) => {
    setUpdateAdvertLoading(true);
    setUpdateAdvertFailure(null);
    try {
      const { data } = await axiosInstance.put(
          "user/adverts/" + id,
          payload
      );
      setUpdateAdvertLoading(false);
      return data;
    } catch (error) {
      const message =
          error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setUpdateAdvertFailure(
          isObject ? Object.values(message).join(" | ") : message
      );
      setUpdateAdvertLoading(false);
      return false;
    }
  };

  const deleteAdvert = async (advertId) => {
    setDeleteAdvertLoading(true);
    setDeleteAdvertFailure(null);
    try {
      const { data } = await axiosInstance.delete(
          `user/adverts/${advertId}`
      );
      setDeleteAdvertLoading(false);
      return data;
    } catch (error) {
      const message =
          error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setDeleteAdvertFailure(
          isObject ? Object.values(message).join(" | ") : message
      );
      setDeleteAdvertLoading(false);
    }
  };
  //ADVERT END HERE

  return {
    blogsLoading,
    blogsFailure,
    setBlogsFailed,
    getBlogs,
    createBlogLoading,
    createBlogFailure,
    setCreateBlogFailure,
    createBlog,
    updateBlogLoading,
    updateBlogFailure,
    setUpdateBlogFailure,
    updateBlog,
    deleteBlogLoading,
    deleteBlogFailure,
    setDeleteBlogFailure,
    deleteBlog,

    forumsLoading,
    forumsFailure,
    setForumsFailed,
    getForums,



    eventsLoading,
    eventsFailure,
    setEventsFailed,
    getEvents,
    createEventLoading,
    createEventFailure,
    setCreateEventFailure,
    createEvent,
    updateEventLoading,
    updateEventFailure,
    setUpdateEventFailure,
    updateEvent,
    deleteEventLoading,
    deleteEventFailure,
    setDeleteEventFailure,
    deleteEvent,

    advertsLoading,
    advertsFailure,
    setAdvertsFailed,
    getAdverts,
    createAdvertLoading,
    createAdvertFailure,
    setCreateAdvertFailure,
    createAdvert,
    updateAdvertLoading,
    updateAdvertFailure,
    setUpdateAdvertFailure,
    updateAdvert,
    deleteAdvertLoading,
    deleteAdvertFailure,
    setDeleteAdvertFailure,
    deleteAdvert,
  };
};

export default useMediaService;
