import React, { useState } from "react";
import { Table, Form } from "antd";
import AgroButton from "../../../../misc/AgroButton";
import useGroupServices from "../../../../../utils/hooks/user/useGroupServices";
import InlineError from "../../../../misc/InlineError";
import { associationMembersListColumns } from "../../../../../utils/misc/groupServices";

const ListMembers = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [due, setDue] = useState(null);
  const { association, loading, associationDues } = props;
  const data = association?.members;
  let dataSource =
    data &&
    data.length > 0 &&
    data.map((row, index) => {
      return {
        key: row?.id,
        sn: index + 1,
        name:
          row?.farmer_record?.first_name || row?.farmer_record?.last_name
            ? `${row?.farmer_record?.first_name ?? ""} ${
                row?.farmer_record?.last_name ?? ""
              }`
            : row?.farmer_record?.company_name,
        status: row?.farmer_record?.publish ? "PUBLISHED" : "NOT PUBLISHED",
        lastDuePaid: "-",
        phone: row?.farmer_record?.phone,
        manage: (
          <div className={"flex justify-between"}>
            <AgroButton
              primary
              text={"Ma"}
              type="button"
              className={"text-[10px]"}
              disabled={!due}
            />
          </div>
        ),
      };
    });
  const {
    associationDuesAttachedToFarmersLoading,
    associationDuesAttachedToFarmersFailure,
    setAssociationDuesAttachedToFarmersFailure,
    getAssociationDuesAttachedToFarmers,
  } = useGroupServices();

  const addDue = (event) => {
    console.log('dueId: '+due)
    console.log('SelectedRowKeys: '+selectedRowKeys)
    console.log('AssociationId: '+association?.id);
    console.log('dueId: '+due);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    console.log(newSelectedRowKeys)
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const handleDueChange = (e) => {
    const id = e.target.value;
    setDue(id);
    console.log(id);
  };

  

  return (
    <>
      <InlineError
        control={associationDuesAttachedToFarmersFailure}
        message={associationDuesAttachedToFarmersFailure}
        onClose={() => setAssociationDuesAttachedToFarmersFailure(null)}
      />
      <div className={"mt-2"}>
        <div className={"flex items-center gap-x-2 mb-2"}>
          <Form
            autoComplete="on"
            className="flex items-center justify-start md:justify-between flex-wrap w-[20%]"
          >
            <div className="w-full lg:my-0 ">
              <Form.Item
                name={"type"}
                rules={[{ required: true, message: "Required" }]}
                onChange={handleDueChange}
                className={"due-form"}
              >
                <select className="form-field__input__2 form-field__sel bg-white dues">
                  <option value={""}>Select Due</option>
                  associationDues ?
                  {associationDues?.data?.map((d) => (
                    <option key={d.name} value={d.id}>
                      {d.name}
                    </option>
                  ))}{" "}
                  : <></>
                </select>
              </Form.Item>
            </div>
          </Form>
          <AgroButton
            loading={associationDuesAttachedToFarmersLoading}
            primary
            onClick={addDue}
            disabled={
              associationDuesAttachedToFarmersLoading || !hasSelected || !due
            }
            text={
              associationDuesAttachedToFarmersLoading
                ? "Please wait..."
                : "Add Due"
            }
            className={"text-[10px]"}
          />
          <span>
            {hasSelected ? `Selected ${selectedRowKeys.length} farmers` : ""}
          </span>
        </div>
        <Table
          className={"farmerList text-[14px]"}
          rowClassName={(_, index) =>
            index % 2 === 0
              ? "table-row-light text-[13px]"
              : "table-row-dark text-[13px]"
          }
          bordered={true}
          dataSource={dataSource}
          columns={associationMembersListColumns}
          loading={loading}
          rowSelection={rowSelection}
        />
      </div>
    </>
  );
};

export default ListMembers;
