const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const randomize = () => {
  let x = `${Math.floor(1000000 + Math.random() * 9000000)}0${Math.floor(
    1000000 + Math.random() * 9000000
  )}`;
  let u = x + "";
  return u;
};

const formatDate = (date) => {
  const dd = new Date(date);
  const y = dd.getFullYear();
  const m = dd.getMonth();
  const d = dd.getDate().toString();
  return `${d.length === 1 ? '0' + d : d} ${months[m]} ${y}`;
}

export { randomize, formatDate };
