import React from "react";
import { useSelector } from "react-redux";

function UserFullName() {
  const { user } = useSelector((state) => state.global);
  const lastName = user?.user?.name.split(" ")[1];
  const name = lastName ? lastName : user?.user?.name.split(" ")[0];

  return (
    <div className="flex items-center py-2 pl-2 mb-3 cursor-pointer rounded-sm w-full hover:bg-gray-300 transition-all">
      <div className="h-7 w-7 mr-2 rounded-full bg-gray-700 flexed text-white">
        {name[0]}
      </div>
      <p className="mb-0 font-semibold">{user.user.name}</p>
    </div>
  );
}

export default UserFullName;
