import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { FiEdit } from "react-icons/fi";
import { MdDoneOutline } from "react-icons/md";
import { AgroButton } from "../../../..";
import ContactPersonInfo from "../ContactPersonInfo";
import _ from "lodash";
import Modal from "antd/lib/modal/Modal";
import ContactPersonModal from "./ContactPersonModal";

const EditContactPersonInfo = ({ farmerData }) => {
  const [disable, setDisable] = useState(true);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [contactPersons, setContactPersons] = useState([]);
  const [recordContactPersons, setRecordContactPersons] = useState([]);

  const onSubmitForm = (values) => {
    console.log(values);
  };

  useEffect(() => {
    console.log(farmerData);
    setRecordContactPersons(farmerData?.contacts);
  }, [farmerData]);

  const addContactPerson = (items) => {
    console.log(items);
    setRecordContactPersons(items);
    setVisible(false);
  };

  const handleRemove = (id) => {
    console.log(id);
    console.log(contactPersons);
    setRecordContactPersons(recordContactPersons.filter((i) => i.id !== id));
  };

  return (
    <div className={"mt-4"}>
      <div className={"border rounded-lg"}>
        <div
          className={
            "w-full bg-gray-300 py-2 px-4  flex items-center justify-between"
          }
        >
          <div className={"font-bold"}>Contact Person</div>
          <div onClick={() => setDisable(!disable)}>
            <a className={"flex gap-x-2 items-center"}>
              {disable ? (
                <>
                  <span className={"font-bold"}>
                    <FiEdit />
                  </span>
                  <span className={"font-bold"}>Edit</span>
                </>
              ) : (
                <>
                  <span className={"font-bold text-green-500"}>
                  <MdDoneOutline />
                  </span>
                  <span className={"font-bold text-green-500"}>Save</span>
                </>
              )}
            </a>
          </div>
        </div>
        <Form
          form={form}
          onFinish={onSubmitForm}
          autoComplete="on"
          className={"w-full px-4 mt-4"}
        >
          <ContactPersonInfo
            form={form}
            setContactPersons={(contactPersons) =>
              setContactPersons(contactPersons)
            }
            disable={disable}
            existingContacts={recordContactPersons}
            farmRecordId={farmerData?.id}
            handleRemove={handleRemove}
          />
          {!disable ? (
            <div className="flex justify-end my-4 gap-x-4">
              <AgroButton
                primary
                text={"Add Contact Person"}
                type="button"
                className={"px-6 text-[10px]"}
                onClick={() => setVisible(true)}
              />
            </div>
          ) : (
            <></>
          )}
        </Form>

        <Modal
          title="Location"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          footer={null}
        >
          <ContactPersonModal
            setVisible={(bool) => setVisible(bool)}
            addContactPerson={(items) => addContactPerson(items)}
            farmRecordId={farmerData?.id}
          />
        </Modal>
      </div>
    </div>
  );
};

export default EditContactPersonInfo;
