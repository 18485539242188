import React, {useEffect, useState} from 'react'
import { Space, Table } from 'antd';
import AgroButton from '../../misc/AgroButton';
import AddEvent from "./components/AddEvent";
import useMediaService from "../../../utils/hooks/user/useMediaService";
import {useSelector} from "react-redux";
import {InlineError} from "../../index";
import ListEvent from "./components/ListEvent";
import ViewEditEvent from "./components/ViewEditEvent";

function UserEvents() {
  const [tab, setTab] = useState(0);
  const [event, setEvent] = useState(null);
  const [paginationData, setPaginationData] = useState({
    page: 1,
    size: 10,
  });

  const {
    eventsLoading,
    eventsFailure,
    setEventsFailed,
    getEvents,
    deleteEventLoading,
    deleteEvent,
  } = useMediaService();

  const { events } = useSelector((state) => state.global);

  const handleClick = (index) => {
    if (tab === 1)
      setPaginationData({
        page: 1,
        size: 10,
      });
    setTab(index);
  };

  const handleView = (event, tab) => {
    setEvent(event);
    setTab(tab);
  };

  useEffect(() => {
    getEvents(paginationData);
  }, [paginationData]);


  const handleCompleted = () => {
    setTab(0);
    setPaginationData({
      page: 1,
      size: 10,
    });
  };

  const paginationChangeHandler = (current, pageSize) => {
    setPaginationData({
      ...paginationData,
      page: current,
      size: pageSize,
    });
  };

  return (
      <div>
        <>
          <div className={"flex justify-end gap-x-3"}>
            <AgroButton
                primary={tab === 0}
                text="List Event"
                className={"text-[12px]"}
                onClick={() => handleClick(0)}
            />
            <AgroButton
                primary={tab === 1}
                text="Add Event"
                className={"text-[12px]"}
                onClick={() => handleClick(1)}
            />

          </div>
          <div>
            {tab === 0 ?(
                <>
                  <div className={"w-full mt-4"}>
                    <InlineError
                        control={eventsFailure}
                        message={eventsFailure}
                        onClose={() => setEventsFailed(null)}
                    />
                  </div>
                  <ListEvent
                      deleteEvent={deleteEvent}
                      events={events}
                      paginationChangeHandler={paginationChangeHandler}
                      loading={eventsLoading}
                      view={(event, tab) => handleView(event, tab)}
                      completed={handleCompleted}
                  />
                </>
            ) : tab === 1 ?(
                <>
                  <AddEvent completed={handleCompleted} />
                </>
            ):(
                <ViewEditEvent
                    completed={handleCompleted}
                    event={event}
                />
            )}
          </div>
        </>
      </div>
  )
}

export default UserEvents