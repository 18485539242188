import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isUserMobNavOpen: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      return {
        ...state,
        user: payload,
      };
    },

    logoutUser: (state) => {
      return {
        ...state,
        user: null,
      };
    },

    toggleUserNav: (state) => {
      return {
        ...state,
        isUserMobNavOpen: !state.isUserMobNavOpen,
      };
    },

    setFarmerList: (state, { payload }) => {
      return {
        ...state,
        farmerList: payload,
      };
    },

    setSingleFarmerList: (state, { payload }) => {
      return {
        ...state,
        singleFarmerList: payload,
      };
    },

    setAggregations: (state, { payload }) => {
      return {
        ...state,
        aggregations: payload,
      };
    },

    setCooperatives: (state, { payload }) => {
      return {
        ...state,
        cooperatives: payload,
      };
    },

  

    setCooperativeDues: (state, { payload }) => {
      return {
        ...state,
        cooperativeDues: payload,
      };
    },

    setFarmerRecordNotInCooperative: (state, { payload }) => {
      return {
        ...state,
        farmersNotInCooperative: payload,
      };
    },

    setCooperativeDuesAttachedToFarmers: (state, { payload }) => {
      return {
        ...state,
        cooperativeDuesAttachedToFarmers: payload,
      };
    },

    setCooperativeDuesAttachedToASpecificFarmer: (state, { payload }) => {
      return {
        ...state,
        cooperativeDuesAttachedToASpecificFarmer: payload,
      };
    },

    setAssociations: (state, { payload }) => {
      return {
        ...state,
        associations: payload,
      };
    },

    setAssociationDues: (state, { payload }) => {
      return {
        ...state,
        associationDues: payload,
      };
    },

    setFarmerRecordNotInAssociation: (state, { payload }) => {
      return {
        ...state,
        farmersNotInAssociation: payload,
      };
    },

    setAssociationDuesAttachedToFarmers: (state, { payload }) => {
      return {
        ...state,
        associationDuesAttachedToFarmers: payload,
      };
    },

    setBlogs: (state, { payload }) => {
      return {
        ...state,
        blogs: payload,
      };
    },

    setForums: (state, { payload }) => {
      return {
        ...state,
        forums: payload,
      };
    },
    setEvents: (state, { payload }) => {
      return {
        ...state,
        events: payload,
      };
    },
    setAdverts: (state, { payload }) => {
      return {
        ...state,
        adverts: payload,
      };
    },
  },
});

export const {
  setUser,
  toggleUserNav,
  logoutUser,
  setFarmerList,
  setSingleFarmerList,
  setAggregations,
  setCooperatives,
  setCooperativeDues,
  setFarmerRecordNotInCooperative,
  setCooperativeDuesAttachedToFarmers,
  setCooperativeDuesAttachedToASpecificFarmer,
  setAssociations,
  setAssociationDues,
  setFarmerRecordNotInAssociation,
  setAssociationDuesAttachedToFarmers,
  setBlogs,
  setForums,
  setEvents,
  setAdverts,
} = globalSlice.actions;

export default globalSlice.reducer;
