import moment from "moment";
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BiCalendar } from "react-icons/bi";
import { FaRegComments } from "react-icons/fa";

function HomeBlogItem({ item }) {
	const { id, comments, title, featured_image, created_at, meta } = item;

	return (
		<Article
			key={title}
			className="blog-right flex flex-wrap md:flex-nowrap items-center bg-white my-8 lg:my-auto shadow-md">
			<div className="mr-1">
				<img
					src={featured_image}
					alt={title}
					className="right__blog-img object-cover"
				/>
			</div>
			<div className="p-3 md:px-3 w-full">
				<p className="bg-[#3dd800] text-center text-sm text-white font-medium uppercase py-[1px] px-2 mb-0 w-max">
					{meta}
				</p>

				<Link to={`/#`}>
					<p className="my-3 font-semibold text-black uppercase hover:underline hover:text-[#3dd800] transition-all text-sm md:text-md">
						{title}
					</p>
				</Link>

				<div className="flex items-center text-black text-opacity-70 uppercase ">
					<p className="text-[10px] md:text-md lg:text-[13px] mb-0 font-semibold">
						BY ADMIN{" "}
					</p>
					<p className="mx-3 mb-0 flex items-center font-semibold text-[10px] md:text-md lg:text-[13px]">
						<BiCalendar className="mr-1 text-[12px] md:text-[13px] lg:text-[15px] mb-1" />{" "}
						<span>{moment(created_at).format("MMMM d, YYYY")}</span>
					</p>
					<span className="flex items-center font-semibold text-[10px] md:text-md lg:text-[13px]">
						<FaRegComments size="17px" className="mr-2" /> {comments.length}{" "}
						comments
					</span>
				</div>
			</div>
		</Article>
	);
}

export default HomeBlogItem;

const Article = styled.article`
	@media (min-width: 768px) {
		.right__blog-img {
			width: 250px;
			height: 129px;
		}
	}

	@media (max-width: 768px) {
		.right__blog-img {
			width: 280px;
		}
	}
	@media (max-width: 500px) {
		.right__blog-img {
			width: 100%;
		}
	}
`;
