import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  setCooperatives,
  setCooperativeDues,
  setFarmerRecordNotInCooperative,
  setCooperativeDuesAttachedToFarmers,
  setCooperativeDuesAttachedToASpecificFarmer,
  setAssociations,
  setAssociationDues,
  setFarmerRecordNotInAssociation,
  setAssociationDuesAttachedToFarmers,
} from "../../../slices/globalSlice";
import axiosInstance from "../../axios/axiosInterceptors";

const useGroupServices = () => {
  const base_url = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  const [cooperativesLoading, setCooperativesLoading] = useState(false);
  const [cooperativesFailure, setCooperativesFailure] = useState(null);
  const [cooperativeLoading, setCooperativeLoading] = useState(false);
  const [cooperativeFailure, setCooperativeFailure] = useState(null);
  const [cooperativeDuesLoading, setCooperativeDuesLoading] = useState(false);
  const [cooperativeDuesFailure, setCooperativeDuesFailure] = useState(null);
  const [
    farmerRecordNotInCooperativeLoading,
    setFarmerRecordNotInCooperativeLoading,
  ] = useState(false);
  const [
    farmerRecordNotInCooperativeFailure,
    setFarmerRecordNotInCooperativeFailure,
  ] = useState(null);
  const [
    cooperativeDuesAttachedToFarmersLoading,
    setCooperativeDuesAttachedToFarmersLoading,
  ] = useState(false);
  const [
    cooperativeDuesAttachedToFarmersFailure,
    setCooperativeDuesAttachedToFarmersFailure,
  ] = useState(null);
  const [createCooperativeLoading, setCreateCooperativeLoading] =
    useState(false);
  const [createCooperativeFailure, setCreateCooperativeFailure] =
    useState(null);
    const [updateCooperativeLoading, setUpdateCooperativeLoading] =
    useState(false);
  const [updateCooperativeFailure, setUpdateCooperativeFailure] =
    useState(null);
  const [createDueLoading, setCreateDueLoading] = useState(false);
  const [createDueFailure, setCreateDueFailure] = useState(null);
  const [addCooperativeMembersLoading, setAddCooperativeMembersLoading] =
    useState(false);
  const [addCooperativeMembersFailure, setAddCooperativeMembersFailure] =
    useState(null);
  const [updateDueLoading, setUpdateDueLoading] = useState(false);
  const [updateDueFailure, setUpdateDueFailure] = useState(null);
  const [updateAssociationDueLoading, setUpdateAssociationDueLoading] = useState(false);
  const [updateAssociationDueFailure, setUpdateAssociationDueFailure] = useState(null);
  const [associationsLoading, setAssociationsLoading] = useState(false);
  const [associationsFailure, setAssociationsFailure] = useState(null);
  const [associationLoading, setAssociationLoading] = useState(false);
  const [associationFailure, setAssociationFailure] = useState(null);
  const [associationDuesLoading, setAssociationDuesLoading] = useState(false);
  const [associationDuesFailure, setAssociationDuesFailure] = useState(null);
  const [
    farmerRecordNotInAssociationLoading,
    setFarmerRecordNotInAssociationLoading,
  ] = useState(false);
  const [
    farmerRecordNotInAssociationFailure,
    setFarmerRecordNotInAssociationFailure,
  ] = useState(null);
  const [createAssociationLoading, setCreateAssociationLoading] =
    useState(false);
  const [createAssociationFailure, setCreateAssociationFailure] =
    useState(null);
    const [updateAssociationLoading, setUpdateAssociationLoading] =
    useState(false);
  const [updateAssociationFailure, setUpdateAssociationFailure] =
    useState(null);
  const [createAssociationDueLoading, setCreateAssociationDueLoading] =
    useState(false);
  const [createAssociationDueFailure, setCreateAssociationDueFailure] =
    useState(null);
  const [addAssociationMembersLoading, setAddAssociationMembersLoading] =
    useState(false);
  const [addAssociationMembersFailure, setAddAssociationMembersFailure] =
    useState(null);
  const [
    associationDuesAttachedToFarmersLoading,
    setAssociationDuesAttachedToFarmersLoading,
  ] = useState(false);
  const [
    associationDuesAttachedToFarmersFailure,
    setAssociationDuesAttachedToFarmersFailure,
  ] = useState(null);

  const [attachDueToFarmerLoading, setAttachDueToFarmerLoading] = useState(false);
  const [attachDueToFarmerFailure, setAttachDueToFarmerFailure] = useState(null);

  const [
    cooperativeDuesAttachedToASpecificFarmerLoading,
    setCooperativeDuesAttachedToASpecificFarmerLoading,
  ] = useState(false);
  const [
    cooperativeDuesAttachedToASpecificFarmerFailure,
    setCooperativeDuesAttachedToASpecificFarmerFailure,
  ] = useState(false);

  const getCooperatives = async ({ page, size }) => {
    setCooperativesLoading(true);
    setCooperativesFailure(null);
    try {
      const { data } = await axiosInstance.get(
        `user/cooperatives?include=country,state,city,cooperativeFocusItems, cooperativeFocusItems.item,
        cooperativeFocusItems.item.itemCategory,cooperativeFocusItems.item.itemSubCategory,members,members.farmerRecord&page=${page}&per_page=${size}`
      );
      const serverResponse = data?.data?.cooperatives;
      dispatch(setCooperatives(serverResponse));
      setCooperativesLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setCooperativesFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCooperativesLoading(false);
    }
  };

  const getCooperative = async (id) => {
    setCooperativeLoading(true);
    setCooperativeFailure(null);
    try {
      const { data } = await axiosInstance.get(
        `${base_url}/user/cooperatives/${id}?include=members,members.farmerRecord,country,state,city,
        cooperativeFocusItems.item.itemCategory,cooperativeFocusItems.item.itemSubCategory,contacts`
      );
      const serverResponse = data?.data?.cooperative;
      setCooperativeLoading(false);
      console.log(data)
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setCooperativeFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCooperativeLoading(false);
    }
  };

  const createCooperative = async (payload) => {
    setCreateCooperativeLoading(true);
    setCreateCooperativeFailure(null);
    try {
      const { data } = await axiosInstance.post("user/cooperatives", payload);
      setCreateCooperativeLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setCreateCooperativeFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCreateCooperativeLoading(false);
    }
  };

  const updateCooperative = async (payload, cooperativeId) => {
    setUpdateCooperativeLoading(true);
    setUpdateCooperativeFailure(null);
    try {
      const { data } = await axiosInstance.post(`${base_url}/user/cooperatives/${cooperativeId}`, payload);
      setUpdateCooperativeLoading(false);
     
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setUpdateCooperativeFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setUpdateCooperativeLoading(false);
    }
  };

  const getCooperativeDues = async ({ id, page, size }) => {
    setCooperativeDuesLoading(true);
    setCooperativeDuesFailure(null);
    try {
      const { data } = await axiosInstance.get(
        `${base_url}/user/cooperatives/${id}/dues?page=${page}&per_page=${size}`
      );
      // const { data } = await axiosInstance.get(
      //   `${base_url}/user/cooperatives/${id}/dues?model,farmer_record_status&page=${page}&per_page=${size}`
      // );
      const serverResponse = data?.data?.dues;
      dispatch(setCooperativeDues(serverResponse));
      setCooperativeDuesLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setCooperativeDuesFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCooperativeDuesLoading(false);
    }
  };

  const getFarmerRecordNotInCooperative = async ({ id, page, size }) => {
    setFarmerRecordNotInCooperativeLoading(true);
    setFarmerRecordNotInCooperativeFailure(null);
    try {
      const { data } = await axiosInstance.get(
        `user/cooperatives/${id}/farmers-not-in-cooperative?page=${page}&per_page=${size}`
      );
      const serverResponse = data?.data?.farmerRecords;
      dispatch(setFarmerRecordNotInCooperative(serverResponse));
      setFarmerRecordNotInCooperativeLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setFarmerRecordNotInCooperativeFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setFarmerRecordNotInCooperativeLoading(false);
    }
  };

  const getCooperativeDuesAttachedToFarmers = async ({
    cooperativeId,
    dueId,
    page,
    size,
  }) => {
    setCooperativeDuesAttachedToFarmersLoading(true);
    setCooperativeDuesAttachedToFarmersFailure(null);
    try {
      const { data } = await axiosInstance.get(
        `user/cooperatives/${cooperativeId}/dues/${dueId}/farmer-cooperative-dues?include=cooperative,due&page=${page}&per_page=${size}`
      );
      const serverResponse = data?.data?.farmerRecords;
      dispatch(setCooperativeDuesAttachedToFarmers(serverResponse));
      setCooperativeDuesAttachedToFarmersLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setCooperativeDuesAttachedToFarmersFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCooperativeDuesAttachedToFarmersLoading(false);
    }
  };

  
  const getCooperativeDuesAttachedToASpecificFarmer = async ({
    cooperativeId,
    dueId,
    farmerRecordId
  }) => {
    setCooperativeDuesAttachedToASpecificFarmerLoading(true);
    setCooperativeDuesAttachedToASpecificFarmerFailure(null);

    try {

      const getUserDueOnly = (due)=>{
        return due.farmer_data_capture_id == farmerRecordId
      };

      const { data } = await axiosInstance.get(
        `user/cooperatives/${cooperativeId}/dues/${dueId}/farmer-cooperative-dues`
      );
      const firstResponse = data?.data?.farmer_dues?.data;
      const serverResponse = firstResponse.filter(getUserDueOnly);

      dispatch(setCooperativeDuesAttachedToASpecificFarmer(serverResponse));
      setCooperativeDuesAttachedToASpecificFarmerLoading(false);
      return serverResponse[0];
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setCooperativeDuesAttachedToFarmersFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCooperativeDuesAttachedToFarmersLoading(false);
    }
  };

  const createDue = async (id, payload) => {
    setCreateDueLoading(true);
    setCreateDueFailure(null);
    try {
      const { data } = await axiosInstance.post(
        `${base_url}/user/cooperatives/${id}/dues`,
        payload
      );
      setCreateDueLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setCreateDueFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCreateDueLoading(false);
    }
  };

  const addCooperativeMembers = async (id, payload) => {
    setAddCooperativeMembersLoading(true);
    setAddCooperativeMembersFailure(null);
    try {
      const { data } = await axiosInstance.post(
        `user/cooperatives/${id}/add-members`,
        payload
      );
      setAddCooperativeMembersLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setAddCooperativeMembersFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setAddCooperativeMembersLoading(false);
    }
  };

  const updateDue = async (cooperativeId, dueId, payload) => {
    setUpdateDueLoading(true);
    setUpdateDueFailure(null);
    try {
      const { data } = await axiosInstance.post(
        `user/cooperatives/${cooperativeId}/dues/${dueId}`,
        payload
      );
      setUpdateDueLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setUpdateDueFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setUpdateDueLoading(false);
    }
  };

  const updateAssociationDue = async (associationId, dueId, payload) => {
    setUpdateAssociationDueLoading(true);
    setUpdateAssociationDueFailure(null);
    try {
      const { data } = await axiosInstance.post(
        `${base_url}/user/association/${associationId}/dues/${dueId}`,
        payload
      );
      setUpdateAssociationDueLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setUpdateAssociationDueFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setUpdateAssociationDueLoading(false);
    }
  };
  const getAssociations = async ({ page, size }) => {
    setAssociationsLoading(true);
    setAssociationsFailure(null);
    try {
      const { data } = await axiosInstance.get(
        `user/associations?include=country,state,city,associationFocusItems, associationFocusItems.item,
        associationFocusItems.item.itemCategory,associationFocusItems.item.itemSubCategory,members,members.farmerRecord&page=${page}&per_page=${size}`
      );
      const serverResponse = data?.data?.associations;
      dispatch(setAssociations(serverResponse));
      setAssociationsLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setAssociationsFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setAssociationsLoading(false);
    }
  };
  const getAssociation = async (id) => {
    setAssociationLoading(true);
    setAssociationFailure(null);
    try {
      const { data } = await axiosInstance.get(
        `${base_url}/user/associations/${id}?include=members,members.farmerRecord,associationFocusItems.item.itemCategory,associationFocusItems.item.itemSubCategory,contacts`
      );
      const serverResponse = data?.data?.association;
      setAssociationLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setAssociationFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setAssociationLoading(false);
    }
  };

  const createAssociation = async (payload) => {
    setCreateAssociationLoading(true);
    setCreateAssociationFailure(null);
    try {
      const { data } = await axiosInstance.post("user/associations", payload);
      setCreateAssociationLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setCreateAssociationFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCreateAssociationLoading(false);
    }
  };

  const updateAssociation = async (payload, associationId) => {
    setCreateAssociationLoading(true);
    setCreateAssociationFailure(null);
    try {
      const { data } = await axiosInstance.put(`${base_url}/user/associations/${associationId}`, payload);
      setUpdateAssociationLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setUpdateAssociationFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setUpdateAssociationLoading(false);
    }
  };

  const getAssociationDues = async ({ id, page, size }) => {
    setAssociationDuesLoading(true);
    setAssociationDuesFailure(null);
    try {
      const { data } = await axiosInstance.get(
        `user/associations/${id}/dues?model,farmer_record_status&page=${page}&per_page=${size}`
      );
      const serverResponse = data?.data?.dues;
      dispatch(setAssociationDues(serverResponse));
      setAssociationDuesLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setAssociationDuesFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setAssociationDuesLoading(false);
    }
  };

  const getFarmerRecordNotInAssociation = async ({ id, page, size }) => {
    setFarmerRecordNotInAssociationLoading(true);
    setFarmerRecordNotInAssociationFailure(null);
    try {
      const { data } = await axiosInstance.get(
        `user/associations/${id}/farmers-not-in-association?page=${page}&per_page=${size}`
      );
      const serverResponse = data?.data?.farmerRecords;
      dispatch(setFarmerRecordNotInAssociation(serverResponse));
      setFarmerRecordNotInAssociationLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setFarmerRecordNotInAssociationFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setFarmerRecordNotInAssociationLoading(false);
    }
  };

  const getAssociationDuesAttachedToFarmers = async ({
    associationId,
    dueId,
    page,
    size,
  }) => {
    setAssociationDuesAttachedToFarmersLoading(true);
    setAssociationDuesAttachedToFarmersFailure(null);
    try {
      const { data } = await axiosInstance.get(
        `user/associations/${associationId}/dues/${dueId}/farmer-association-dues?include=association,due&page=${page}&per_page=${size}`
      );
      const serverResponse = data?.data?.farmerRecords;
      dispatch(setAssociationDuesAttachedToFarmers(serverResponse));
      setAssociationDuesAttachedToFarmersLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setAssociationDuesAttachedToFarmersFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setAssociationDuesAttachedToFarmersLoading(false);
    }
  };

  const createAssociationDue = async (id, payload) => {
    setCreateAssociationDueLoading(true);
    setCreateAssociationDueFailure(null);
    try {
      const { data } = await axiosInstance.post(
        `user/associations/${id}/dues`,
        payload
      );
      setCreateAssociationDueLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setCreateAssociationDueFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCreateAssociationDueLoading(false);
    }
  };

  const addAssociationMembers = async (id, payload) => {
    setAddAssociationMembersLoading(true);
    setAddAssociationMembersFailure(null);
    try {
      const { data } = await axiosInstance.post(
        `user/associations/${id}/add-members`,
        payload
      );
      setAddAssociationMembersLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setAddAssociationMembersFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setAddAssociationMembersLoading(false);
    }
  };

  const attachDueToFarmer = async (id, dueId, payload) => {
    setAttachDueToFarmerLoading(true);
    setAttachDueToFarmerFailure(null);
    try {
      const { data } = await axiosInstance.post(
        `${base_url}/user/cooperatives/${id}/dues/${dueId}/farmer-cooperative-dues`,
        payload
      );
      setAttachDueToFarmerLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      const isObject = typeof error === "object";
      setAttachDueToFarmerFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setAttachDueToFarmerLoading(false);
    }
  };

  return {
    cooperativesLoading,
    cooperativesFailure,
    setCooperativesFailure,
    getCooperatives,
    cooperativeLoading,
    cooperativeFailure,
    setCooperativeFailure,
    getCooperative,
    createCooperativeLoading,
    createCooperativeFailure,
    updateCooperative,
    setUpdateCooperativeFailure,
    updateCooperativeLoading,
    updateCooperativeFailure,
    setCreateCooperativeFailure,
    createCooperative,
    cooperativeDuesLoading,
    cooperativeDuesFailure,
    setCooperativeDuesFailure,
    getCooperativeDues,
    farmerRecordNotInCooperativeLoading,
    farmerRecordNotInCooperativeFailure,
    setFarmerRecordNotInCooperativeFailure,
    getFarmerRecordNotInCooperative,
    cooperativeDuesAttachedToFarmersLoading,
    cooperativeDuesAttachedToFarmersFailure,
    setCooperativeDuesAttachedToFarmersFailure,
    getCooperativeDuesAttachedToFarmers,
    createDueLoading,
    createDueFailure,
    setCreateDueFailure,
    createDue,
    addCooperativeMembersLoading,
    addCooperativeMembersFailure,
    setAddCooperativeMembersFailure,
    addCooperativeMembers,
    updateDueLoading,
    updateDueFailure,
    setUpdateDueFailure,
    updateDue,
    updateAssociationDueLoading,
    updateAssociationDueFailure,
    setUpdateAssociationDueFailure,
    updateAssociationDue,
    associationsLoading,
    associationsFailure,
    setAssociationsFailure,
    getAssociations,
    associationLoading,
    associationFailure,
    setAssociationFailure,
    getAssociation,
    createAssociationLoading,
    createAssociationFailure,
    updateAssociationLoading,
    updateAssociationFailure,
    setCreateAssociationFailure,
    createAssociation,
    updateAssociation,
    associationDuesLoading,
    associationDuesFailure,
    setAssociationDuesFailure,
    getAssociationDues,
    farmerRecordNotInAssociationLoading,
    farmerRecordNotInAssociationFailure,
    setFarmerRecordNotInAssociationFailure,
    getFarmerRecordNotInAssociation,
    createAssociationDueLoading,
    createAssociationDueFailure,
    setCreateAssociationDueFailure,
    createAssociationDue,
    addAssociationMembersLoading,
    addAssociationMembersFailure,
    setAddAssociationMembersFailure,
    addAssociationMembers,
    associationDuesAttachedToFarmersLoading,
    associationDuesAttachedToFarmersFailure,
    setAssociationDuesAttachedToFarmersFailure,
    getAssociationDuesAttachedToFarmers,
    attachDueToFarmerLoading,
    setAttachDueToFarmerLoading,
    attachDueToFarmerFailure,
    setAttachDueToFarmerFailure,
    attachDueToFarmer,
    cooperativeDuesAttachedToASpecificFarmerLoading,
    setCooperativeDuesAttachedToASpecificFarmerFailure,
    setCooperativeDuesAttachedToFarmersFailure,
    getCooperativeDuesAttachedToASpecificFarmer,
  };
};

export default useGroupServices;
