import React, { useEffect, useState } from "react";
import { dummyProducts } from "../../utils/misc/homeData";
import { MdAddShoppingCart } from "react-icons/md";
import { TiArrowUnsorted } from "react-icons/ti";
import { BsArrowRight } from "react-icons/bs";
import styled from "styled-components";
import StarRatingComponent from "react-star-rating-component";
import AgroButton from "../misc/AgroButton";
import { Link } from "react-router-dom";
import { PaddedContainer } from "..";

function ProductsSection() {
  const [dynamicProducts, setDynamicProducts] = useState(dummyProducts);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchError, setSearchError] = useState(false);
  const [currCategory, setCurrCategory] = useState("all");
  const allCategories = [
    "all",
    ...new Set(dummyProducts.map((item) => item.category)),
  ];

  useEffect(() => {
    const tempProducts = dummyProducts.filter((item) =>
      item.prName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setDynamicProducts(tempProducts);
  }, [searchTerm, dummyProducts]);

  const filterItems = (category) => {
    if (category === "all") {
      setDynamicProducts(dummyProducts);

      setCurrCategory("all");
      return;
    }

    const newItems = dummyProducts.filter((item) => item.category === category);
    setDynamicProducts(newItems);

    setCurrCategory(category);
  };

  return (
    <PaddedContainer>
      <div className="mb-8 relative flex items-center w-[95%] mx-auto md:w-[50%] border border-gray-400 hover:border-orange-400 transition-all rounded-sm">
        <div className="relative">
          <span className="absolute right-2 top-3 ml-5">
            <TiArrowUnsorted className="h-3.5 w-3.5 text-black text-opacity-50" />
          </span>
          <select
            defaultValue="All Categories"
            className="pl-3 pr-9 py-2 border-r bg-transparent border-gray-400 outline-none hover:border-orange-500 transition-all cursor-pointer"
          >
            <option value="" className="selectOptions">
              All Categories
            </option>
            <option value="">Category 1</option>
            <option value="">Category 2</option>
            <option value="">Category 3</option>
          </select>
        </div>

        <input
          placeholder="Search over 10,000 products"
          type="text"
          className="px-3 py-[10px] w-full focus:outline-0 border-l-0"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="my-5 w-full flex items-center justify-around lg:justify-between flex-wrap lg:flex-nowrap">
        <div className=" w-full md:w-auto my-2">
          <p className="mb-0 lg:text-xl text-center font-bold tracking-wide whitespace-nowrap">
            Featured Products
          </p>
        </div>

        <hr className="w-full hidden lg:inline mx-3 h-[2px] bg-gray-300" />

        <div className="flex items-center justify-center w-full md:w-auto my-2">
          {allCategories.map((item, index) => (
            <p
              onClick={() => filterItems(item)}
              className={`mx-1 cursor-pointer capitalize px-2 transition-all ${
                currCategory === item && "bg-[#009900] text-white rounded-md"
              }`}
              key={index}
            >
              {item}
            </p>
          ))}
        </div>
      </div>
	  
      <div className="flex flex-wrap items-center justify-center lg:justify-start">
        {dynamicProducts.map(
          ({
            id,
            imgPath,
            prName,
            prevPrice,
            currentPrice,
            rating,
            category,
            reviews,
          }) => (
            <ProductContainer
              key={prName}
              className="w-full group my-4 lg:my-3 md:w-[42%] lg:w-[25%] px-2"
            >
              <div
                className={
                  "shadow-lg hover:shadow-xl hover:scale-105 transition-all"
                }
              >
                <div>
                  <img
                    src={imgPath}
                    alt={prName}
                    className="object-cover product__img"
                  />
                </div>

                <div className="bg-white p-3 pb-1">
                  {/* IMAGE & CART BTN */}
                  <div className="flex items-center justify-between">
                    <p className="font-bold uppercase">{prName}</p>
                    <span className="group opacity-0 group-hover:opacity-100 h-9 w-9 rounded-full border border-gray-400 bg-[#009900] hover:border-none transition-all flex items-center justify-center cursor-pointer">
                      <MdAddShoppingCart className="h-5 w-5 text-white" />
                    </span>
                  </div>

                  {/* PRICE & STARS */}
                  <div className="flex items-center justify-between mt-2">
                    <div>
                      <p className="line-through text-black text-opacity-70">
                        ${prevPrice.toLocaleString()}
                      </p>
                      <p className="font-bold">
                        ${currentPrice.toLocaleString()}
                      </p>
                    </div>

                    <div>
                      <span className="flex items-center justify-center">
                        <StarRatingComponent
                          name={prName}
                          starCount={5}
                          value={rating}
                        />
                      </span>

                      <p className="font-bold uppercase text-xs">
                        {reviews} Reviews
                      </p>
                    </div>
                  </div>
                  <Link to={`/products/${id}`}>
                    <p className="text-center p-2 mt-2 border border-gray-300 bg-[#009900] text-white">
                      More Details
                    </p>
                  </Link>
                </div>
              </div>
            </ProductContainer>
          )
        )}
      </div>
      <div className="my-8 flex iteme-center justify-start">
        <AgroButton primary text="Load More" Icon={<BsArrowRight />} />
      </div>
    </PaddedContainer>
  );
}

export default ProductsSection;

const ProductContainer = styled.div`
  .product__img {
    width: 100%;
    height: 150px;
  }
`;
