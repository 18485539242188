const blogListColumns = [
  {
    title: "S/N",
    dataIndex: "sn",
    key: "sn",
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Meta",
    dataIndex: "meta",
    key: "meta",
  },
  {
    title: "Post Type",
    dataIndex: "post_type",
    key: "post_type",
  },
  {
    title: "Body",
    dataIndex: "body",
    key: "body",
  },
  {
    title: "Status",
    dataIndex: "is_active",
    key: "is_active",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

const eventListColumns = [
  {
    title: "S/N",
    dataIndex: "sn",
    key: "sn",
  },
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Meta",
    dataIndex: "meta",
    key: "meta",
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    key: "start_date",
  },
  {
    title: "End Date",
    dataIndex: "end_date",
    key: "end_date",
  },
  {
    title: "Venue Type",
    dataIndex: "venue_type",
    key: "venue_type",
  },
  {
    title: "Status",
    dataIndex: "is_active",
    key: "is_active",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

const advertListColumns = [
  {
    title: "S/N",
    dataIndex: "sn",
    key: "sn",
  },
  {
    title: "Advert Url",
    dataIndex: "advert_url",
    key: "advert_url",
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
];

export { blogListColumns, eventListColumns, advertListColumns };
