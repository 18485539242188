import React, {useState, useEffect} from "react";
import { Table, Pagination } from "antd";
import { cooperativesListColumns } from "../../../../../utils/misc/groupServices";
import useCountriesStates from "../../../../../utils/hooks/auth/useCountriesStates";
import { Link } from "react-router-dom";
import AgroButton from "../../../../misc/AgroButton";

const ListCooperatives = (props) => {
  const {
    countriesData,
    getCountries,
    statesData,
    getStates,
    citiesData,
    getCities,
  } = useCountriesStates();

/*   const stateGetter = (countryId) => {
    getStates(countryId)
    console.log(countriesData);
  }
 */

  const [statArray, setStateArray] = useState([])
  const { cooperatives, paginationChangeHandler, loading } = props;
  const defaultCurrent = 1;
  const defaultPageSize = 10;
  const data = cooperatives?.data;
console.log(data)

  let dataSource =
    data &&
    data.length > 0 &&
    data.map((row, index) => {
  
      return {
   
        key: row?.id,
        sn: index + 1,
        name: row?.name,
        state: row?.state?.name,
        city: row?.city?.name,
        members: (row?.members).length,
        focus: row?.cooperative_focus_items[0]?.item?.name,
        manage: (
          <div className={"flex justify-between"}>
            <Link
              to={{
                pathname: "/user/group-services/cooperatives/manage",
                search: `?id=${row?.id}`,
              }}
            >
              <AgroButton
                primary
                text={"Manage"}
                type="button"
                className={"text-[10px]"}
              />
            </Link>
            <Link
              to={{
                pathname: "/user/group-services/cooperatives/members",
                search: `?id=${row?.id}`,
              }}
            >
              <AgroButton
                primary
                text={"Members"}
                type="button"
                className={"text-[10px]"}
              />

            </Link>
            <Link
              to={{
                pathname: "/user/group-services/cooperatives/dues",
                search: `?id=${row?.id}`,
              }}
            >
              <AgroButton
                primary
                text={"Dues"}
                type="button"
                className={"text-[10px]"}
              />
            </Link>
          </div>
        ),
      };
    });

   
  const onShowSizeChange = (current, pageSize) => {
    return { current, pageSize };
  };

  return (
    <>
      <div className={"mt-6"}>
        <Table
          className={"farmerList text-[14px]"}
          rowClassName={(_, index) =>
            index % 2 === 0
              ? "table-row-light text-[13px]"
              : "table-row-dark text-[13px]"
          }
          bordered={true}
          dataSource={dataSource}
          columns={cooperativesListColumns}
          pagination={false}
          loading={loading}
        />
      </div>
      <div className="flex justify-end py-3">
        <Pagination
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={defaultCurrent}
          total={cooperatives?.total}
          showTotal={(total) => `Total ${total} items`}
          defaultPageSize={defaultPageSize}
          onChange={paginationChangeHandler}
          className={"text-[13px]"}
        />
      </div>
    </>
  );
};

export default ListCooperatives;
