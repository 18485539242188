import React from "react";
import { PaddedContainer } from "..";
import AgroButton from "../misc/AgroButton";

const advertItems = [
	{
		title: "UP TO $5M",
		subtitle: "High Range Loan",
		iconPath: "/images/advertImgs/icon-5.png",
		bgColor: "#7a33ff",
	},
	{
		title: "FROM 7.50%",
		subtitle: "Offer Low Interest",
		iconPath: "/images/advertImgs/icon-6.png",
		bgColor: "#b93dcc",
	},
	{
		title: "7 DAYS PROCESS",
		subtitle: "Fast & Easy Process",
		iconPath: "/images/advertImgs/icon-7.png",
		bgColor: "#247cb2",
	},
];

function Adverts() {
	return (
		<PaddedContainer>
			<div className="flex flex-wrap justify-center lg:justify-between items-center px-3 lg:px-0">
				{advertItems.map(({ title, subtitle, iconPath, bgColor }) => (
					<div
						style={{
							backgroundColor: bgColor,
						}}
						key={title}
						className="p-5 w-full lg:w-[30%] shadow-lg flex items-center cursor-pointer my-3 hover:scale-105 transition-all">
						<div className="flexed items-center justify-center mr-4">
							<img src={iconPath} alt={title} />
						</div>

						<div className="text-white">
							<p className="font-medium tracking-wide mb-1">{title}</p>
							<p className="font-bold tracking-wide mb-1">{subtitle}</p>
						</div>
					</div>
				))}
			</div>

			<div className="flex justify-center items-center mt-7">
				<AgroButton text="Post an Advert" className="" />
			</div>
		</PaddedContainer>
	);
}

export default Adverts;
