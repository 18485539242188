const footerLeftOptions = [
	{ title: "Home", path: "/" },
	{ title: "About Us", path: "/about" },
	{ title: "Team", path: "/" },
	{ title: "Investors", path: "/" },
	{ title: "Careers", path: "/" },
	{ title: "Contact", path: "/" },
	{ title: "Newsroom", path: "/" },
];

const footerLinks = [
	{ title: "Privacy Policy", path: "/" },
	{ title: "Terms of Use", path: "/" },
	{ title: "Cookie Policy", path: "/" },
	{ title: "Legal", path: "/" },
];

const footerMidOptions = [
	{ title: "Field Services", path: "/" },
	{ title: "Commercial Services", path: "/" },
	{ title: "Institutions", path: "/" },
	{ title: "Media Services", path: "/" },
	{ title: "Concierge", path: "/" },
	{ title: "Models", path: "/" },
	{ title: "Bespoke", path: "/" },
	{ title: "Upgroove Services", path: "/" },
	{ title: "Home Shoot", path: "/" },
];

const footerBROptions = [
	{ title: "Privacy Policy", path: "/" },
	{ title: "Terms of Use", path: "/" },
	{ title: "Cookie Policy", path: "/" },
	{ title: "General", path: "/" },
];

export { footerLeftOptions, footerMidOptions, footerBROptions, footerLinks };
