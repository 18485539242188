import React from 'react'
import { Card, Col, Row } from 'antd'

const Dashboard = () => {
  return (
    <div>
            <div  className="site-card-wrapper mt-8">
    <Row gutter={12}>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
        title="Total Farmers" bordered={false}>
         3000
        </Card>
      </Col>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
        title="Distribution" bordered={false}>
         150
        </Card>
      </Col>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
         title="Input Supplies" bordered={false}>
          30
        </Card>
      </Col>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
         title="Processing" bordered={false}>
        30
        </Card>
      </Col>
    </Row>
    <Row gutter={12} className = "mt-14">
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
        title="Production" bordered={false}>
        20
       <p> </p>
        </Card>
      </Col>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
        title=" Storage" bordered={false}>
          40
        </Card>
      </Col>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
         title="Corperate" bordered={false}>
         120
        </Card>
      </Col>
      <Col span={6}>
        <Card 
       headStyle={{ borderTopLeftRadius: '5px', fontSize:"14px", borderTopRightRadius: '5px', backgroundColor: 'rgba(229, 231, 235)', color: '#000000' }}
       bodyStyle={{ borderBottomLeftRadius: '5px', fontSize:"14px", borderBottomRightRadius: '5px', backgroundColor: 'rgba(243, 244, 246)',  color: '#000000' }}
         title="Individual" bordered={false}>
        180
        </Card>
      </Col>
    </Row>
  </div>
    </div>
  )
}

export default Dashboard