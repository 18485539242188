import React, { useEffect, useState } from "react";
import { Form, Input } from "antd";
import { AgroButton, InlineError } from "../../../../components";
import _ from "lodash";
import useGeneric from "../../../../utils/hooks/user/useGeneric";
import useMediaService from "../../../../utils/hooks/user/useMediaService";
import { message } from "antd";

const AddAdvert = ({ completed, disable }) => {
    const [form] = Form.useForm();
    const [buttonText, setButtonText] = useState("Create Advert");
    const [current, setCurrent] = useState(0);

    const {
        createAdvertLoading,
        createAdvertFailure,
        setCreateAdvertFailure,
        createAdvert,
    } = useMediaService();

    const success = "Successful";
    const error = "An error has occured";

    const { TextArea } = Input;

    const [bannerImage, setBannerImage] = useState(null);
    const [advertItem, setAdvertItem] = useState(null);
    const [advertType, setAdvertType] = useState(null);
    const [advertId, setAdvertId] = useState(null);

    const next = () => {
        setCurrent(current + 1);
    };

    const handleImage = (e) => {
        const reader = new FileReader();
        reader.readAsDataURL(e);
        setBannerImage(e);
        reader.onload = function () {
        };
    };

    const handleAdvert = (e) => {
        const type = e.target.value;
        setAdvertItem(type);
    };

    const handleType = (e) => {
        const type = e.target.value;
        setAdvertType(type);
    };


    useEffect(() => {
        if (current !== 0) {
            setButtonText("Continue");
        }
    }, [current]);

    const onSubmit = (values) => {
        const data = {
            advert_plan_id: values?.advert_plan_id ?? "",
            advert_url: values?.advert_url ?? "",
            banner: bannerImage,
        };
        const formData = new FormData();
        _.forEach(data, (value, key) => {
            if (typeof value === "object") {
                if (value?.length) {
                    for (let i = 0; i < value.length; i++) {
                        _.forEach(value[i], (item, prop) => {
                            if (item && key !== "focusItems") {
                                formData.append(`${key}[${i}][${prop}]`, item);
                            }
                            if (item && key === "focusItems") {
                                formData.append(`${key}[]`, item);
                            }
                        });
                    }
                }
            } else {
                formData.append(key, value);
                formData.append(`banner`, bannerImage);
            }
        });

        createAdvert(formData).then((data) => {
            if (data?.success) {
                setAdvertId(data?.data?.adverts?.id);
                message.success(data?.message || success);
                if (!createAdvertFailure) {
                    completed();
                }
            } else {
                message.error(data?.message || error);
            }
        });
    };

    // const onSubmit = (values) => {
    //     const formData = new FormData();
    //     // formData.append("advert_plan_id", advert_plan_id);
    //     // formData.append("body", values?.body);
    //     // formData.append("advert_url", advert_url);
    //     // formData.append("banner", banner);
    //
    //
    //     createAdvert(formData).then((data) => {
    //         if (data?.success) {
    //             message.success(data?.message || success);
    //                 if (!createAdvertFailure) {
    //                     completed();
    //                 }
    //         } else {
    //             message.error(data?.message || error);
    //         }
    //     });
    //     // createAdvert(formData).then(() => {
    //     //     if (!createAdvertFailure) {
    //     //         completed();
    //     //     }
    //     // });
    //     console.log(formData)
    // };


    return (
        <div>
            <InlineError
                control={createAdvertFailure}
                message={createAdvertFailure}
                onClose={() => setCreateAdvertFailure(null)}
            />

            <Form form={form} onFinish={onSubmit} autoComplete="on" className="mt-5">
                <div className="flex items-center justify-start md:justify-between flex-wrap w-full">
                    <div className="w-full mb-2 flex justify-between items-center">
                        <p className="mb-2 text-lg lg:text-[18px] font-bold">Create Advert</p>
                    </div>

                    <div className="flex items-center justify-start md:justify-between flex-wrap w-full ">
                        <div className="w-full md:w-[29%] lg:my-4 ">
                            <p className="form-label">Advert</p>
                            <Form.Item onChange={handleAdvert} name={"advert_plan_id"}>
                                <select
                                    className="form-field__input__2 form-field__sel bg-white"
                                    disabled={disable}
                                >
                                    <option value={""}>Select Advert</option>
                                    <option value={"1"}>Publishes Description</option>
                                    <option value={"2"}>Banner Size</option>
                                    <option value={"3"}>Pictorial Guide</option>
                                </select>
                            </Form.Item>
                        </div>

                        <div className="w-full md:w-[29%] lg:my-0 ">
                            <p className="form-label">Advert Type</p>
                            <Form.Item  onChange={handleType} name={"advert_type"}>
                                <select
                                    className="form-field__input__2 form-field__sel bg-white"
                                    disabled={disable}
                                >
                                    <option value={""}>Select Advert Type</option>
                                    <option value="pay_per_view">Pay Per View</option>
                                    <option value="fixed_duration">Fixed Duration</option>
                                </select>
                            </Form.Item>
                        </div>

                        <>
                            {
                                advertType === "pay_per_view"
                                ?
                                    <div className="w-full md:w-[29%] lg:my-0 ">
                                        <p className="form-label">Numbers Of Views</p>
                                        <Form.Item
                                            name="num"
                                            // rules={[
                                            //     { required: true, message: "Required" },
                                            //     { min: 2, message: "Must be at least 2 characters" },
                                            // ]}
                                        >
                                            <Input
                                                className="form-field__input__2"
                                                placeholder=" "
                                                disabled={disable}
                                            />
                                        </Form.Item>
                                    </div>
                                    : advertType === "fixed_duration"
                                ?
                                       <>
                                           <div className="w-full md:w-[29%] lg:my-0 ">
                                               <p className="form-label">Start Date</p>
                                               <Form.Item
                                                   name="start_date"
                                                   // rules={[
                                                   //     { required: true, message: "Required" },
                                                   //     { min: 2, message: "Must be at least 2 characters" },
                                                   // ]}
                                               >
                                                   <Input
                                                       type="datetime-local"
                                                       className="form-field__input__2"
                                                       placeholder=" "
                                                       disabled={disable}
                                                   />
                                               </Form.Item>
                                           </div>

                                           <div className="w-full md:w-[29%] lg:my-0 ">
                                               <p className="form-label">End Date</p>
                                               <Form.Item
                                                   name="end_date"
                                                   // rules={[
                                                   //     { required: true, message: "Required" },
                                                   //     { min: 2, message: "Must be at least 2 characters" },
                                                   // ]}
                                               >
                                                   <Input
                                                       type="datetime-local"
                                                       className="form-field__input__2"
                                                       placeholder=" "
                                                       disabled={disable}
                                                   />
                                               </Form.Item>
                                           </div>
                                       </>
                                :
                                <></>
                            }
                        </>

                        <div className="w-full md:w-[29%] lg:my-0 ">
                            <p className="form-label">Advert Url</p>
                            <Form.Item
                                name="advert_url"
                                initialValue="https://"
                                rules={[
                                    { required: true, message: "Required" },
                                    { min: 2, message: "Must be at least 2 characters" },
                                ]}
                            >
                                <Input
                                    className="form-field__input__2"
                                    placeholder=" "
                                    disabled={disable}
                                />
                            </Form.Item>
                        </div>


                        <div className="w-full md:w-[29%] lg:my-0 ">
                            <p className="form-label">Upload Banner</p>
                            <input
                                className="form-field__input__2 mb-24"
                                type="file"
                                onChange={(e) => handleImage(e.target.files[0])}
                                accept="image/png, image/gif, image/jpeg"
                            />
                        </div>
                    </div>

                </div>

                <div className="flex justify-end mt-4 mb-1 gap-x-4">
                    <AgroButton
                        loading={createAdvertLoading}
                        disabled={createAdvertLoading}
                        primary
                        text={createAdvertLoading ? "Please wait..." : buttonText}
                        type="submit"
                        className={"px-10"}
                    />
                </div>
            </Form>
        </div>
    );
};

export default AddAdvert;
