import React from 'react'
import GenericLayout from '../../containers/layouts/GenericLayout'

const Media = () => {
  return (
<GenericLayout>
    <div className="bg-gray-200 rounded-lg shadow-lg p-5 md:p-20 mt-10">
      <div className="text-center">
        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          Agro<span className="text-green-600">CLERK</span>  </h2>
         <h2> Media<span className="text-green-600">Section</span></h2>
      
        <h3 className='text-xl md:text-3xl mt-10'>Coming Soon</h3>
        
      </div>
    </div>
    </GenericLayout>
  )
}

export default Media