import React from "react";
import { RiHome7Line } from "react-icons/ri";
import { Link } from "react-router-dom";

function UserSidebarHome({ isActive = true }) {
  return (
    <Link className={isActive ? "style_lg_active" : ""} to="/user/home">
      <div className="group left-sidebar-item">
        <span className="mr-2 text-black">
          <RiHome7Line
            size="1.3rem"
            className="group-hover:grn-text transition-all left-sidebar-icon"
          />
        </span>
        <p className="left-sidebar-text">Homepage</p>
      </div>
    </Link>
  );
}

export default UserSidebarHome;
