import { useState } from "react";
import { useDispatch } from "react-redux";
import { setFarmerList } from "../../../slices/globalSlice";
import { setSingleFarmerList } from "../../../slices/globalSlice";
import axiosInstance from "../../axios/axiosInterceptors";

const useFarmerData = () => {
  const dispatch = useDispatch();
  const [farmerListLoading, setFarmerListLoading] = useState(false);
  const [farmerListFailure, setFarmerListFailed] = useState(null);

  const [farmerListByIdLoading, setFarmerListByIdLoading] = useState(false);
  const [farmerListByIdFailure, setFarmerListByIdFailed] = useState(null);

  const [createFarmerLoading, setCreateFarmerLoading] = useState(false);
  const [createFarmerFailure, setCreateFarmerFailure] = useState(null);

  const [createLocationLoading, setCreateLocationLoading] = useState(false);
  const [createLocationFailure, setCreateLocationFailure] = useState(null);

  const [deleteLocationLoading, setDeleteLocationLoading] = useState(false);
  const [deleteLocationFailure, setDeleteLocationFailure] = useState(null);

  const [createContactPersonLoading, setCreateContactPersonLoading] =
    useState(false);
  const [createContactPersonFailure, setCreateContactPersonFailure] =
    useState(null);

  const [deleteContactPersonLoading, setDeleteContactPersonLoading] =
    useState(false);
  const [deleteContactPersonFailure, setDeleteContactPersonFailure] =
    useState(null);

  const [createBankDetailsLoading, setCreateBankDetailsLoading] =
    useState(false);
  const [createBankDetailsFailure, setCreateBankDetailsFailure] =
    useState(null);

  const [updateFarmerLoading, setUpdateFarmerLoading] = useState(false);
  const [updateFarmerFailure, setUpdateFarmerFailure] = useState(null);

  const [updateBankLoading, setUpdateBankDetailsLoading] = useState(false);
  const [updateBankFailure, setUpdateBankDetailsFailure] = useState(null);

  const [uploadFarmerLoading, setUploadFarmerLoading] = useState(false);
  const [uploadFarmerFailure, setUploadFarmerFailure] = useState(null);


  const [createFocusLoading, setCreateFocusLoading] = useState(true);
  const [createFocusFailure, setCreateFocusFailure] = useState(null);

  const [deleteFocusLoading, setDeleteFocusLoading] = useState(false);
  const [deleteFocusFailure, setDeleteFocusFailure] = useState(null);

  const getFarmerList = async ({ page, size }) => {
    setFarmerListLoading(true);
    setFarmerListFailed(null);
    try {
      const { data } = await axiosInstance.get(
        `user/farmer-records?include=fdcType,addresses,addresses.country,addresses.state,addresses.city,contacts,
        focusItems,focusItems.fdcItem,bankDetails&page=${page}&per_page=${size}`
      );
      const serverResponse = data?.data?.farmer_records;
      console.log(serverResponse);
      dispatch(setFarmerList(serverResponse));
      setFarmerListLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setFarmerListFailed(
        isObject ? Object.values(message).join(" | ") : message
      );
      setFarmerListLoading(false);
    }
  };


  const getFarmerListById = async (farmer_id) => {
    // alert(22)
    setFarmerListByIdLoading(true);
    setFarmerListByIdFailed(null);
    try {
      const { data } = await axiosInstance.get(
        `user/farmer-records/${farmer_id}?include=focusItems.fdcItem.itemCategory`
      );
      const serverResponse = data?.data?.farmer_record;
      // console.log("kkkk", serverResponse);
      dispatch(setSingleFarmerList(serverResponse));
      setFarmerListByIdLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setFarmerListByIdFailed(
        isObject ? Object.values(message).join(" | ") : message
      );
      setFarmerListByIdLoading(false);
    }
  };


  const createFarmer = async (payload) => {
    setCreateFarmerLoading(true);
    setCreateFarmerFailure(null);
    try {
      const { data } = await axiosInstance.post("user/farmer-records", payload);
      setCreateFarmerLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setCreateFarmerFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCreateFarmerLoading(false);
    }
  };

  const addFocusFunc = async (payload, id) => {
    setCreateFocusLoading(true);
    setCreateFocusFailure(null)
    try {
      const { data } = await axiosInstance.post(
        `user/farmer-records/${id}/focus-items`, payload
      );
      setCreateFocusLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setCreateFocusFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCreateFocusLoading(false);
    }
  };

  const createLocation = async (payload, farmRecordId) => {
    setCreateLocationLoading(true);
    setCreateLocationFailure(null);
    try {
      const { data } = await axiosInstance.post(
        `user/farmer-records/${farmRecordId}/addresses`,
        payload
      );
      setCreateLocationLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setCreateLocationFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCreateLocationLoading(false);
    }
  };

  const deleteLocation = async (farmRecordId, addressId) => {
    setDeleteLocationLoading(true);
    setDeleteLocationFailure(null);
    try {
      const { data } = await axiosInstance.delete(
        `user/farmer-records/${farmRecordId}/addresses/${addressId}`
      );
      setDeleteLocationLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setDeleteLocationFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setDeleteLocationLoading(false);
    }
  };


  const deleteFocusFunc = async (farmer_rec_id, focus_item_id) => {
    setDeleteFocusLoading(true);
    setDeleteFocusFailure(null);
    try {
      const { data } = await axiosInstance.delete(
        `user/farmer-records/${farmer_rec_id}/focus-items/${focus_item_id}`
      );
      setDeleteFocusLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setDeleteFocusFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setDeleteFocusLoading(false);
    }
  };

  const createContactPerson = async (payload, farmRecordId) => {
    setCreateContactPersonLoading(true);
    setCreateContactPersonFailure(null);
    try {
      const { data } = await axiosInstance.post(
        `user/farmer-records/${farmRecordId}/contacts`,
        payload
      );
      setCreateContactPersonLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setCreateContactPersonFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCreateContactPersonLoading(false);
    }
  };

  const deleteContactPerson = async (farmRecordId, contactId) => {
    setDeleteContactPersonLoading(true);
    setDeleteContactPersonFailure(null);
    try {
      const { data } = await axiosInstance.delete(
        `user/farmer-records/${farmRecordId}/contacts/${contactId}`
      );
      setDeleteContactPersonLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setDeleteContactPersonFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setDeleteContactPersonLoading(false);
    }
  };

  const createBankDetails = async (payload, farmRecordId) => {
    setCreateBankDetailsLoading(true);
    setCreateBankDetailsFailure(null);
    try {
      const { data } = await axiosInstance.post(
        `user/farmer-records/${farmRecordId}/banks`,
        payload
      );
      setCreateBankDetailsLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setCreateBankDetailsFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setCreateBankDetailsLoading(false);
    }
  };

  const updateFarmer = async (payload, farmRecordId) => {
    setUpdateFarmerLoading(true);
    setUpdateFarmerFailure(null);
    try {
      // const { data } = await axiosInstance.post(
      const { data } = await axiosInstance.put(
        `user/farmer-records/${farmRecordId}`,
        payload
      );
      setUpdateFarmerLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setUpdateFarmerFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setUpdateFarmerLoading(false);
    }
  };

  const updateBankDetails = async (payload, farmRecordId, bankDetailId) => {
    setUpdateBankDetailsLoading(true);
    setUpdateBankDetailsFailure(null);
    try {
      const { data } = await axiosInstance.post(
        `user/farmer-records/${farmRecordId}/banks/${bankDetailId}`,
        payload
      );
      setUpdateBankDetailsLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setUpdateBankDetailsFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setUpdateBankDetailsLoading(false);
    }
  };

  const uploadFarmer = async (payload) => {
    setUploadFarmerLoading(true);
    setUploadFarmerFailure(null);
    try {
      const { data } = await axiosInstance.post(
        `user/farmer-records/import`,
        payload
      );
      setUploadFarmerLoading(false);
      return data;
    } catch (error) {
      const message =
        error?.response?.data.errors || error?.response?.data?.message;
      console.log("err", error?.response?.data);
      const isObject = typeof error === "object";
      setUploadFarmerFailure(
        isObject ? Object.values(message).join(" | ") : message
      );
      setUploadFarmerLoading(false);
    }
  };

  return {
    farmerListLoading,
    farmerListFailure,
    setFarmerListFailed,
    getFarmerList,
    farmerListByIdLoading,
    farmerListByIdFailure,
    setFarmerListByIdFailed,
    getFarmerListById,
    createFarmerLoading,
    createFarmerFailure,
    setCreateFarmerFailure,
    createFarmer,
    createLocationLoading,
    createLocationFailure,
    setCreateLocationFailure,
    createLocation,
    createFocusLoading,
    createFocusFailure,
    setCreateFocusFailure,
    addFocusFunc,

    deleteLocationLoading,
    deleteLocationFailure,
    setDeleteLocationFailure,
    deleteLocation,
    deleteFocusLoading,
    deleteFocusFailure,
    setDeleteFocusFailure,
    deleteFocusFunc,

    createContactPersonLoading,
    createContactPersonFailure,
    setCreateContactPersonFailure,
    createContactPerson,
    deleteContactPersonLoading,
    deleteContactPersonFailure,
    setDeleteContactPersonFailure,
    deleteContactPerson,
    createBankDetailsLoading,
    createBankDetailsFailure,
    setCreateBankDetailsFailure,
    createBankDetails,
    updateFarmerLoading,
    updateFarmerFailure,
    setUpdateFarmerFailure,
    updateFarmer,
    updateBankLoading,
    updateBankFailure,
    updateBankDetails,
    setUploadFarmerFailure,
    setUploadFarmerLoading,
    uploadFarmerLoading,
    uploadFarmerFailure,
    uploadFarmer
  };
};

export default useFarmerData;
