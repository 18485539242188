import React from "react";
import { useSelector } from "react-redux";
import { UserFullName, UserSidebarHome } from "../..";
import { NavLink, useLocation } from "react-router-dom";

import { userNavItems } from "../../../utils/misc/userHomeData";
import { userHome } from "../../../utils/misc/navData";

function HomeLeftSidebar() {
  const { user } = useSelector((state) => state.global);
  const { pathname } = useLocation();
  return (
    <div>
      <UserFullName />

      <div className="flex flex-col">
        <UserSidebarHome isActive={pathname === userHome ? true : false} />
        {userNavItems.slice(1).map(({ title, Icon, path }) => (
          <NavLink
            key={title}
            to={path}
            className={({ isActive }) =>
              isActive ? "style_lg_active" : "none"
            }
          >
            <div className="group left-sidebar-item">
              <span className="mr-2 text-black">
                <Icon
                  size="1.3rem"
                  className="group-hover:grn-text transition-all left-sidebar-icon"
                />
              </span>
              <p className="left-sidebar-text">{title}</p>
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
}

export default HomeLeftSidebar;
