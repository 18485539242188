import React from "react";
import StarRatingComponent from "react-star-rating-component";
import { PaddedContainer } from "..";
import { clientReviews } from "../../utils/misc/homeData";

function Testimonials() {
	return (
		<PaddedContainer>
			<p className="text-center w-full md:w-3/6 mx-auto tracking-wide text-black text-xl lg:text-3xl mt-5 mb-2 font-medium">
				Testimonials{" "}
			</p>
			<div className="flex items-center flex-wrap justify-center lg:justify-between">
				{clientReviews.map(({ clientName, imgSrc, review, stars }) => (
					<div
						key={clientName}
						className="py-2.5 w-full md:w-[28%] my-3 mx-auto">
						<div className="shadow-lg p-3">
							<StarRatingComponent
								name={clientName}
								starCount={5}
								value={stars}
								editing={false}
								className="text-lg"
							/>
							<p className="text-black text-justify">{review}</p>
						</div>
						<div className="mx-auto flex items-center justify-center mt-3">
							<div className="flex items-center">
								<img
									height="50"
									width="50"
									className="object-contain rounded-full mx-1"
									src={imgSrc}
									alt={clientName}
								/>
								<p className="text-black text-xs font-medium tracking-wide mx-1">
									{clientName}
								</p>
							</div>
						</div>
					</div>
				))}
			</div>
		</PaddedContainer>
	);
}

export default Testimonials;
